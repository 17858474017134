import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDateMonitor from './DocDateMonitor';
import { CardContent, Typography, Grid, Card, Box, IconButton, Tooltip } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { css, keyframes } from "@emotion/react";
import LogMessage from './LogMessage';

const blink = keyframes`
from {
  background: red;
}
to {
  background: none;
}
`;

const LogEvent = ({ logs, type, categoriesMap, brandsMap, colorsMap, reverse = false }) => {

  const [expand, setExpand] = useState(false);
  const [highlight, setHighlight] = useState();

  useEffect(() => {
    // console.log("nowSeconds " + (new Date()).getSeconds())
    const diff = logs.length > 0 ? Math.floor((new Date() - new Date(logs[0].createdAt.toMillis())) / (1000)) : 0
    // console.log("diff " + diff)
    setHighlight(logs.length > 0 && diff <= 5 ? logs[0].createdAt.toMillis() : 0)
  }, [logs]);

  if (!logs || !logs.length)
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '80px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '40px',
        textAlign: 'center',
      }}>
        No events recorded yet.
      </Box>
    );
  else {
    return (
      <Box sx={{bgcolor: 'background.paper', width:'500px' }}>
        <div>
        <Tooltip title="History">
          <IconButton color="inherit" onClick={() => setExpand((old) => !old)}>
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          </Tooltip>
        </div>
        <List sx={{ bgcolor: 'background.paper' }}>
          {logs.slice(0, expand ? logs.length : 1).map((log) => {
            console.log("LOG: "+ (log.id != null ? ("ID: " + log.id) : "ACT " + log.action + " ARGS-n " + (log.args && Array.isArray(log.args) ? log.args.length : "noArr")))
            const categories = []
            const brands = []
            const colors = []
            var req = "";
            var resp = "";
            if (log.args && Array.isArray(log.args) && log.args.length > 0) {
              // req = log.args.map(arg => arg.substring(0, 20))
              // req = log.args.substring(0, 20)
              try {
                // console.log("THIJSON " + log.args + " (type: " + typeof log.args)
                // const jsonArr = JSON.parse(log.args);
                // console.log("THIJSON parsedArray? " + Array.isArray(jsonArr))
                // if(typeof jsonArr === 'string' || !Array.isArray(jsonArr)) throw Exception("Badly parsed")
                // console.log("ARGARR " + log.args);
                req = log.args.map((arg, i) => {
                  let parsedArg = ""
                  try {
                    parsedArg = JSON.parse(arg);
                    console.log("ARGARRGG " + (typeof parsedArg));
                    if(typeof parsedArg === 'string' || !Object.keys(parsedArg).length) throw Exception("Badly parsed")
                    console.log("ARGARRGG " + Object.keys(parsedArg));
                    return <LogMessage key={i} jsObj={parsedArg} />
                  } catch (e) {
                    return <div key={i}>{arg.substring(0, 40)}</div>
                  }
                })
              } catch (e) {
                req = <div>{typeof log.args === 'string' ? log.args.substring(0, 20) : log.args}</div>
              }
            }
            if (log.message && log.message.length > 0) {
              try {
                // console.log("THIJSON " + log.message)
                const jsonObj = JSON.parse(log.message);
                // console.log("THIJSON parsed " + Object.keys(jsonObj).length)
                if(typeof jsonObj === 'string' || !Object.keys(jsonObj).length) throw Exception("Badly parsed")
                // resp = <div>{JSON.stringify(jsonObj, null, 2)}</div>
                  resp = <LogMessage jsObj={jsonObj} />
                if (type === 'feedoptions' && categoriesMap && brandsMap && colorsMap) {
                  if (jsonObj.dealFilter.mc)
                    categories.push(categoriesMap[jsonObj.dealFilter.mc])
                  if (jsonObj.dealFilter.mainCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.mainCategoryId])
                  if (jsonObj.dealFilter.sc)
                    categories.push(categoriesMap[jsonObj.dealFilter.sc])
                  if (jsonObj.dealFilter.subCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.subCategoryId])
                  if (jsonObj.dealFilter.p)
                    categories.push(categoriesMap[jsonObj.dealFilter.p])
                  if (jsonObj.dealFilter.productTagCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.productTagCategoryId])
                  if (jsonObj.dealFilter.st)
                    categories.push(categoriesMap[jsonObj.dealFilter.st])
                  if (jsonObj.dealFilter.subTagCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.subTagCategoryId])
                  if (jsonObj.dealFilter.b) {
                    jsonObj.dealFilter.b.forEach((brId) => brands.push(brandsMap[brId]))
                  }
                  if (jsonObj.dealFilter.brandIds) {
                    jsonObj.dealFilter.brandIds.forEach((brId) => brands.push(brandsMap[brId]))
                  }
                  if (jsonObj.dealFilter.c) {
                    jsonObj.dealFilter.c.forEach((id) => colors.push(colorsMap[id]))
                  }
                  if (jsonObj.dealFilter.colorIds) {
                    jsonObj.dealFilter.colorIds.forEach((id) => colors.push(colorsMap[id]))
                  }
                }
                if (type === 'dyChooseBody' && categoriesMap) {
                  if (jsonObj.context.page.data) {
                    jsonObj.context.page.data.forEach((id) => categories.push(categoriesMap[id]))
                  }
                }
              } catch (e) {
                resp = <div>{log.message
                  // .substring(0, 40)
                  }</div>
              }
            }

            const previewChips = <>{categories.length > 0 && <Stack direction="row" spacing={1}>
            {categories.map((cat) => {
              return <Chip key={cat} label={cat} size="small" color='primary' />
            })}
          </Stack>}
          {brands.length > 0 && <Stack sx={{marginTop:'4px'}} direction="row" spacing={1}>
            {brands.map((item) => {
              return <Chip key={item} label={item} size="small" color='primary' />
            })}
          </Stack>}
          {colors.length > 0 && <Stack sx={{marginTop:'4px'}} direction="row" spacing={1}>
            {colors.map((item) => {
              return <Chip key={item} label={item} size="small" color='primary' />
            })}
          </Stack>}</>

            return (
              /* primary={

              <Box paddingLeft='0px'><Box><DocDateMonitor timeStampDate={log.createdAt} /></Box>
              </Box>} */
                <ListItem alignItems="flex-start" key={log.docId} sx={{ overflow: 'auto', animation: 'none', ...(highlight === log.createdAt.toMillis() && { animation: `${blink} 0.5s ease` }) }}>
                <Box>
                        
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {(req != null && req.length >0) && <Box  sx={{display: 'flex'}} marginBottom='10px'>
                              {!reverse && (<span>-->&nbsp;</span>)}
                              {req}
                              {reverse && (<span>&lt;--&nbsp;</span>)}
                            </Box>}
                          {resp && <Box>{previewChips}<Box  sx={{display: 'flex'}} marginBottom='10px'>
                            {!reverse && (<span>&lt;--&nbsp;</span>)}
                            {resp}
                            {reverse && (<span>-->&nbsp;</span>)}
                            </Box></Box>}
                        </Typography>
                      </Box>
                </ListItem>
            )
          })}
        </List>
      </Box>
    );
  }
};

export default LogEvent;
