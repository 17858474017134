import React, { useEffect, useState } from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import {CardContent, Typography, Grid, Card, Box} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const STORE_NAME_ACC = "octo-app-install-target-account"
const STORE_NAME_APP = "octo-app-install-target-app"
const STORE_URL_REPO_OWNER = "octo-app-install-target-repo-owner"
const STORE_URL_REPO_NAME = "octo-app-install-target-repo-name"
// const STORE_URL_REPO_VARIANTS = "octo-app-install-target-repo-variants"

// FirebaseManager.getInstance().init();

const GitHubRepoInstalled = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const installIdSet = searchParams.get('installation_id');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
  const accId = localStorage.getItem(STORE_NAME_ACC);
  const appId = localStorage.getItem(STORE_NAME_APP);
  const repoOwner = localStorage.getItem(STORE_URL_REPO_OWNER);
  const repoName = localStorage.getItem(STORE_URL_REPO_NAME);
  // const repoVariants = JSON.parse(localStorage.getItem(STORE_URL_REPO_VARIANTS));
    console.log("GitHubRepoInstalled installIdSet", installIdSet)
    console.log("GitHubRepoInstalled repoOwner", repoOwner)
    console.log("GitHubRepoInstalled repoName", repoName)
    // console.log("GitHubRepoInstalled repoVariants", JSON.stringify(repoVariants))
    if ((typeof installIdSet === 'string') && installIdSet.length > 0 && accId && appId && repoOwner && repoName) {
      localStorage.removeItem(STORE_NAME_ACC);
      localStorage.removeItem(STORE_NAME_APP);
      localStorage.removeItem(STORE_URL_REPO_OWNER);
      localStorage.removeItem(STORE_URL_REPO_NAME);
      // localStorage.removeItem(STORE_URL_REPO_VARIANTS);

      setLoading(true);
      const subscription = Data.getInstance().setAppRepo(`Accounts/${accId}/Apps/${appId}`, installIdSet, repoOwner, repoName)
        .subscribe(
          (ok) => {
              console.log("GitHubRepoInstalled loaded", ok)
            setLoading(false);
            navigate(`/accounts/${accId}/apps/${appId}/aidocs`);
          },
        );

      return () => {
        subscription.unsubscribe();
      };
    }
    setError(true);
    setLoading(false);
    return () => {};
  }, [installIdSet]);
  

    return (
      <Box sx={{
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Box sx={{
          // height: '100%',
          width: '300px',
          marginTop: '20vh',
          padding: '40px',
          borderRadius: '4px',
          background: '#eee',
        }}>
          {loading ? <Box sx={{display:'flex', alignItems:'center'}}><CircularProgress sx={{marginRight:'20px'}} /> Getting things ready...</Box> : (error ? "There was an error. Please try connecting the repo again." : "")}
        </Box>
      </Box>
    );
};

export default GitHubRepoInstalled;
