import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Data from './Data';

const ConnectedFileMethods = ({ avAppPath, targetComp, file, addedFiles }) => {
  const [targetParentName, setTargetParentName] = useState();
  const [methodsAvail, setMethodsAvail] = useState();
  const [methodsSelectable, setMethodsSelectable] = useState();
  const [methodSelected, setMethodSelected] = useState();
  const [interactionType, setInteractionType] = useState();
  const [addMethodTrigger, setAddMethodTrigger] = useState();
  const [showAddMethod, setShowAddMethod] = useState(!(targetComp.methods && targetComp.methods.length));

  useEffect(() => {
    const targetFound = addedFiles.find(fileElem => fileElem.fullPath === targetComp.filePath);
    console.log("ConnectedFileMethods allFiles " + addedFiles[0].fullPath)
    console.log("ConnectedFileMethods targetComp.filePath " + targetComp.filePath)
    console.log("ConnectedFileMethods targetFound " + targetFound)
    if (targetFound && targetFound.fileCheck) {
      if (targetFound.fileCheck.parentClass && targetFound.fileCheck.parentClass.length) {
        //even for not-connected (and not analyzed/added) parents, we need the name later, so we can ask the AI to also find parent refs
        setTargetParentName(targetFound.fileCheck.parentClass)
      }
      let methodsAvail = [];
      methodsAvail = methodsAvail.concat(targetFound.fileCheck.methods || [])
      const connectedParentOfTarget = targetFound.parentPathConnected
      if (connectedParentOfTarget) {
        const parentDocFound = addedFiles.find(file => file.fullPath === connectedParentOfTarget);
        if (parentDocFound && parentDocFound.fileCheck) {
          methodsAvail = methodsAvail.concat(parentDocFound.fileCheck.methods || [])
        }
      }
      setMethodsAvail(methodsAvail);
    }
  }, [targetComp, addedFiles]);

  useEffect(() => {
    if (methodsAvail)
      setMethodsSelectable(methodsAvail.filter(mtd => !targetComp.methods || (targetComp.methods.findIndex(alreadyInDoc => alreadyInDoc.methodName === mtd.methodName) < 0)))
  }, [methodsAvail, targetComp]);

  const handleMethodAddClick = () => {
    if (methodSelected && interactionType) {
      setAddMethodTrigger([methodSelected, interactionType])
      setMethodSelected(null)
      setInteractionType(null);
    }
  }

  useEffect(() => {
    console.log('addMethodsTrigger ' + addMethodTrigger);
    if (addMethodTrigger) {      
      const [methodSelected, interactionType] = addMethodTrigger
      const newComps = file.compConnected.slice()
      const compI = newComps.findIndex(c => c.filePath === targetComp.filePath)
      const newMethods = (newComps[compI].methods || []).slice()
      newMethods.push({
        methodName: methodSelected.methodName,
        interaction: interactionType
      })
      newComps[compI] = {
        filePath: targetComp.filePath,
        ...(targetParentName && {parentFileName: targetParentName}),
        methods: newMethods,
      }
      const subscription = Data.getInstance().updateConnectedComps(avAppPath, file.docId, newComps)
        .subscribe(() => setAddMethodTrigger(null));
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [addMethodTrigger]);

  const handleSelect = (event, value) => {
    console.log('Selected item:', value);
    if (value && ("" !== value)) {
      setMethodSelected(value)
    }
  };
  const handleinteractionTypeChange = (event) => {
    setInteractionType(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', padding: '4px' }}>
      {!methodsAvail && "Loading"}
      {methodsAvail && <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '20px',
      }}>
        {(!targetComp.methods || !targetComp.methods.length) && <Tooltip title={targetComp.filePath}><Box>{targetComp.filePath.split("/").pop().split(".").slice(0, -1).join(".")}</Box></Tooltip>}
        {(targetComp.methods && targetComp.methods.length) && <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
        }}>
          {targetComp.methods.map((mtd) => <Tooltip title={targetComp.filePath}><Box>{mtd.interaction + " " + targetComp.filePath.split("/").pop().split(".").slice(0, -1).join(".") + ": " + mtd.methodName}</Box></Tooltip>)}
        </Box>}
        <Box sx={{display:'flex', alignItems:'center', marginLeft:'120px'}}><Typography variant="h8" component="h6">Add method</Typography>{showAddMethod ? <ExpandLessOutlinedIcon onClick={() => setShowAddMethod(false)} /> : <ExpandMoreOutlinedIcon onClick={() => setShowAddMethod(true)} />}</Box>
        {methodsAvail.length == 0 ? "No methods found" : (showAddMethod && methodsSelectable && methodsSelectable.length > 0 && <Box sx={{
          display: 'flex',
          // flexDirection: 'column', 
          padding: '20px',
          background:'#ccc'
        }}>
          <FormControl>
            <FormLabel id="radio-buttons-group">Interaction with method:</FormLabel>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={interactionType || ""}
              onChange={handleinteractionTypeChange}
            >
              <FormControlLabel value="calls" control={<Radio />} label="Calls/Invokes" />
              <FormControlLabel value="observes" control={<Radio />} label="Listens to/Observes" />
            </RadioGroup>
          </FormControl>
          <Autocomplete
            options={methodsSelectable}
            onChange={handleSelect}
            getOptionLabel={(option) => option.methodName || ""}
            value={methodSelected || ""}
            renderInput={(params) => (
              <TextField {...params} label="Select method" placeholder="" />
            )}
            sx={{ width: '370px' }}
          />
          <Button
            sx={{ marginTop: '20px' }}
            variant="outlined"
            disabled={!methodSelected || !interactionType}
            startIcon={<InsightsIcon />}
            onClick={handleMethodAddClick}
          >
            Add interaction
          </Button>
        </Box>)}
      </Box>}
    </Box>
  );
};
export default ConnectedFileMethods;
