import React, { useState, useEffect } from 'react';
import ReactJson from 'react-json-view'
import { Box, Chip, Tooltip, Button, TextField } from '@mui/material';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { AddOutlined } from '@mui/icons-material';
import Data from './Data';

const LogMessage = ({ jsObj, group, event, showjsTree=false, addableAsTestCase = false, appPath = "" }) => {

  const [testCaseAddExpanded, setTestCaseAddExpanded] = useState(false);
  const [testCaseGroupIdInput, setTestCaseGroupIdInput] = useState("");
  const [testCaseVariantIdInput, setTestCaseVariantIdInput] = useState("");
  const [testCaseVariantLabelInput, setTestCaseVariantLabelInput] = useState("");
  const [testCaseCreateTrigger, setTestCaseCreateTrigger] = useState(false);
  const [testCaseCreated, setTestCaseCreated] = useState(false);
  const [testCaseAddErr, setTestCaseAddErr] = useState(false);

  useEffect(() => {
    console.log('createTrigger ' + testCaseCreateTrigger);
    setTestCaseAddErr(false);
    setTestCaseCreated(false)
    if (testCaseCreateTrigger && testCaseGroupIdInput.length && testCaseVariantIdInput.length && testCaseVariantLabelInput.length) {
      Data.getInstance().addTestVariantToGroup(appPath, testCaseGroupIdInput, testCaseVariantIdInput, testCaseVariantLabelInput, JSON.stringify(jsObj))
      .then((data) => {
         console.log(data);
         setTestCaseCreated(true)
         setTestCaseCreateTrigger(false)
         setTestCaseGroupIdInput("")
         setTestCaseVariantIdInput("")
         setTestCaseVariantLabelInput("")
      })
      .catch((err) => {
         console.log(err.message);
         setTestCaseAddErr(true);
         setTestCaseCreateTrigger(false)
      });
    }
  }, [testCaseCreateTrigger]);

    // const showJson = (json) => {return <Box><ReactJson src={json} theme="codeschool" name={false} collapsed={1} collapseStringsAfterLength={15} groupArraysAfterLength={10} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} /></Box>}
    // const showJson = (json, type) => {return <div>{type}<pre>{JSON.stringify(json, null, 2).substring(0, 400)}</pre></div>}
    const showJson = (json, typeBox) => {
        const jsonClean = JSON.parse(JSON.stringify(json))
        if (jsonClean.avType && !window.location.href.startsWith("http://localhost")) { //!window.location.href.startsWith("http://localhost")
            delete jsonClean.avType
        }
        if (showjsTree) {
            return <Box><ReactJson src={jsonClean} theme="codeschool" name={false} collapsed={5} collapseStringsAfterLength={30} groupArraysAfterLength={10} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} /></Box>
        }
        
      /* if (group === 'GoogleAnalytics' && event == 'Event') {
        if (jsObj['&t'] === 'screenview')
          return <Box><Chip label={jsObj['&t']} /></Box>
        else
          return <Box sx={{ display: 'flex' }}><Chip label={jsObj['&ec']} /><Chip label={jsObj['&ea']} />{jsObj['&el'] ? <Chip label={jsObj['&el']} /> : ""}</Box>
      } else */
        return (<Box sx={{ display: 'flex' }}>
          {typeBox}
          {(Object.keys(jsonClean).length > 0) && (<Tooltip title={JSON.stringify(jsonClean)}>
              <Chip label={JSON.stringify(jsonClean).substring(0, 60)} size="small" variant="soft" icon={<DataObjectOutlinedIcon />}/>
            </Tooltip>)}
        </Box>)
    }
    var typeBox = <></>
    let js = jsObj;
    if (jsObj.avType && !(["HashMap", "Bundle"].includes(jsObj.avType))) {
        typeBox = <Chip label={jsObj.avType} size="small" variant="soft" color="info" icon={<HexagonOutlinedIcon />} sx={{marginRight: '10px'}}/>
        // delete jsObj.avType
        // ({ avType, ...js } = js);
    }
    // else {js = jsObj}
    // console.log("LogMessage", js)
    let toShow = "";
    let additionalJson = showjsTree ? showJson(js) : ""
    // if (Object.keys(js).length == 1 && jsObj.avType) toShow = <Box>{typeBox}</Box>
    /* else if (Object.keys(js).length == 2 && js.successful != null) return <Box>{js.successful ? "\u2714\uFE0F" : "\u274cFAILED"}</Box>
    else if (js.avType == "ImmutableApiResult") {
        return <Box>{js.a ? "\u2714\uFE0F" : "\u274cFAILED"}</Box>
    } */ 
    // else 
    if (js.avType == "ImmutableDyEngagementBody") {
        try {
            const engage = js.engagements[0];
            toShow =  (<Box sx={{display:'flex', flexDirection:'column'}}>
                    <Box><Chip label={engage.type} size="small" /> <Chip label={engage.slotId.substring(0, 20) + "..."} size="small" /></Box>
                    {additionalJson}
                </Box>)
        } catch (e) { 
            toShow =  showJson(js)
        }
    } else if (js.avType == "ImmutableDyEventBody") {
            try {
                const engage = js.events[0];
                toShow =  (<Box sx={{display:'flex', flexDirection:'column'}}>
                        <Box><Chip label={engage.name} size="small" /></Box>
                        {additionalJson}
                    </Box>)
            } catch (e) { toShow =  showJson(js) }
    } else if (js.avType == "ImmutableDyChooseBody" || js.avType == "DyChooseBody" || (group == 'DY' && event == 'trackPagevieww')) {
        try {
            var other = [];
            try {
                other.push(<Chip key={'pageLoc'} label={js.context.page.location} size="small" />)
            }catch(e){}
            try {
                if (js.context.page.data.length > 0)
                    other.push(<Chip key={'pageData'} label={js.context.page.data.join(", ")} size="small" />)
                if (js.selector && js.selector.names && js.selector.names.length)
                    other.push(<Chip key={'selectors'} label={js.selector.names[0]} size="small" />)
            }catch(e){}
            toShow =  (<Box sx={{display:'flex', flexDirection:'column'}}>
            <Box><Chip label={js.context.page.type} size="small" />{other}</Box>
            {additionalJson}
            </Box>)
        } catch (e) { toShow =  showJson(js) }
   /*  } else if (type == "fetchDyCampaigns") {
        try {
            const show = js.result.variations[0].title
            const show2 = js.result.variations[0].dealSlots.length
            toShow =  (<Box>{typeBox}<Chip label={show} size="small" /><Chip label={show2 + " slots"} size="small" /></Box>)
        } catch (e) { toShow =  showJson(js) } */
    } else if (js.avType && (js.avType.endsWith("UIModel") || js.avType.endsWith("UiModel"))) {
        try {
            const show = [];
            if (js.loading)
                show.push("loading")
            else{
                show.push(<Chip key={'id'} label={js.product.Id} size="small" />) 
                show.push(<Chip key={'name'} label={js.product.Name} size="small" />)
                show.push(<Box key={'img'}><img width={250} height={250} src={js.product.BackgroundImage}/></Box>) 
            }
            toShow =  (<Box sx={{display:'flex', flexDirection:'column'}}>
            <Box>{show}</Box>
            {additionalJson}
            </Box>)
        } catch (e) { toShow =  showJson(js) }
    } else
        toShow =  showJson(js, typeBox)
    return <Box>
        {toShow}
        {addableAsTestCase && !testCaseAddExpanded && <Button onClick={() => setTestCaseAddExpanded(true)}>Save as test variant</Button>}
        {testCaseAddExpanded && <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          marginBottom: '24px',
        }}>
        <TextField id="input-test-case-group-id" label="group id" variant="standard" sx={{marginBottom: '0px'}} value={testCaseGroupIdInput} onChange={(ev) => setTestCaseGroupIdInput(ev.target.value)} />
        <TextField id="input-test-case-variant-id" label="variant id" variant="standard" sx={{marginBottom: '0px'}} value={testCaseVariantIdInput} onChange={(ev) => setTestCaseVariantIdInput(ev.target.value)} />
        <TextField id="input-test-case-variant-label" label="variant label" variant="standard" sx={{marginBottom: '6px'}} value={testCaseVariantLabelInput} onChange={(ev) => setTestCaseVariantLabelInput(ev.target.value)} />
            <Button
            variant="outlined"
              disabled={!testCaseGroupIdInput.length || !testCaseVariantIdInput.length || !testCaseVariantLabelInput.length || testCaseAddErr || testCaseCreateTrigger}
              startIcon={testCaseCreateTrigger ? <CircularProgress size={24} /> : <AddOutlined/>}
              onClick={() => {
                setTestCaseCreateTrigger(true)
              }}>Add test case to group</Button>
              {testCaseCreated && <Box sx={{color:'green'}}>Test case added.</Box>}
              {testCaseAddErr && <Box sx={{color:'red'}}>Couldn't add test case. Try again or contact us.</Box>}
          </Box>}
      </Box>
};

export default LogMessage;