import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import LogEvent from './LogEvent';
import LogMessageRow from './LogMessageRow';


const ComponentLogList = ({logRefs}) => {

  const { accountId, appId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const subscription = Data.getInstance().streamAggLogDataByRefs(`Accounts/${accountId}/Apps/${appId}`, logRefs)
    .subscribe(
      (logs) => {
        if (logs.length) {
          console.log("ComponentLogList loaded", logs)
          setLogs(logs)
        }
        setLoading(false);
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, logRefs]);
  
  const onSessionClick = (docId) => {
    navigate(`/accounts/${accountId}/apps/${appId}/monitor?logDocId=${docId}`);
  }

  if (loading) return "Loading";

  return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {
        logs.sort().map((log) => {
          // return <LogMessageRow log={log} showjsTree={true} />
          /* var show = "";
          try {
            const jsonObj = JSON.parse(log);
            try {
              delete jsonObj.avType
            } catch(e){}
            show = JSON.stringify(jsonObj, null, 2)
          } catch (e) {
            show = log
          } */
          let jump = <Button variant='outlined' sx={{maxWidth:'max-content'}} onClick={()=>onSessionClick(log.docId)}>
          Jump to session 
          {/* {log.docId} */}
          </Button>
          return (<Box key={log.docId} sx={{display:'flex', flexDirection: 'column'}}><LogMessageRow log={log} showjsTree={true} />{jump}</Box>);
        })
      }
    </Box>)
};

export default ComponentLogList;
