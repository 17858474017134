import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

const AiTextExpandable = ({ usageAnalysis }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', width: '600px', border: 1, borderRadius: 2, padding: '4px' }}>
      {!expanded && (<Typography variant="body2" sx={{ padding: '4px' }}>{usageAnalysis.analysis.substring(0, 80)}</Typography>)}
      {expanded && (<Typography variant="body2" sx={{ padding: '4px' }}>{usageAnalysis.analysis}<br /><i>Details: </i>{usageAnalysis.answer}</Typography>)}
      {!expanded && usageAnalysis.analysis.length > 90 && <Typography variant="body2">...</Typography>}
      {!expanded && <IconButton color="secondary" onClick={() => { setExpanded(true); }}><ExpandMoreOutlinedIcon /></IconButton>}
      {expanded && <IconButton color="secondary" onClick={() => { setExpanded(false); }}><ExpandLessOutlinedIcon /></IconButton>}
    </Box>
  );
};
export default AiTextExpandable;
