import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  flatMap,
  first,
} from 'rxjs/operators';


const ComponentsOverview = () => {

  const MyCard = styled(Card)({
    height: 240,
    width: 200,
    cursor: 'pointer',
  });

  const { accountId, appId, appVersion } = useParams();
  const navigate = useNavigate();
  const [aggs, setAggs] = useState({});
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    const appV = parseInt(appVersion, 10);
    if (appV < 174) {
      setLoading(false);
      return () => {};
    }
    const subscription = Data.getInstance().streamAggLogDataGroup(`Accounts/${accountId}/Apps/${appId}`, appV)
      .pipe(
        map((aggs) => aggs.reduce((map, agg) => {
          const arr = map[agg.group] || [];
          arr.push(agg);
          map[agg.group] = arr;
          return map;
        }, {})),
      )
      .subscribe(
        (aggsFound) => {
          // if (aggsFound.length) {
            setAggs(aggsFound);
          // }
          setLoading(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, appVersion]);

  const onComponentSelected = (compId) => {
    if (Array.isArray(compId)) {
      navigate(`/accounts/${accountId}/apps/${appId}/${appVersion}/docs/${compId[0]}/${compId[1]}`);
    } else
      navigate(`/accounts/${accountId}/apps/${appId}/${appVersion}/docs/${compId}`);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      {loading ? "Loading" : (<Grid container spacing={4}>{Object.keys(aggs).map((group) => 
        <Grid key={group} item>
          <MyCard><CardContent>
          <Typography variant="h5" component="h2">{group}</Typography>
          {aggs[group].map(agg => {
            return <Box key={agg.event} onClick={(e) => {
              e.stopPropagation();
              onComponentSelected([agg.group, agg.event]);
            }}>
            {agg.event}
            </Box>
          })}
        </CardContent></MyCard>          
        </Grid>
      )}</Grid>)}
    </Box>
  );
};

export default ComponentsOverview;
