import React from 'react';
import {useNavigate} from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import {CardContent, Typography, Grid, Card, Box} from '@mui/material';
import PlatformInfo from './PlatformInfo';

// FirebaseManager.getInstance().init();

const AppsList = ({apps}) => {
  const navigate = useNavigate();

  if (!apps || !apps.length)
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '80px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '40px',
        textAlign: 'center',
      }}>
        <Box sx={{
          maxWidth: '600px',
          fontSize: '14px',
        }}>
        We will create your project asap and send you your API key and the docs.<br/><br/>If you want to join an existing project, ask the owner/admin for an invite (pass your user ID that you can find by clicking on the fingerprint icon on the top right of this screen)
          </Box>
      </Box>
    );
  else {
    return (
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '40px',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '40px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
        <Grid container spacing={6}>
          {apps.map((app) => (
            <Grid
              key={app.path} item>
              <Card sx={{
                height: '140px',
                width: '280px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`/${app.path}`)
              }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {app.name}
                  </Typography>
                  <PlatformInfo platform={app.platform} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      /* <div className={styles.list}>
        {apps.map(app => {
          return <div key={app.id} className={styles.app} onClick={()=>
          onAppSelect(app)
          //Data.getInstance().copyHelp()
          }>
            <span className={styles.name}>{app.name}</span>
            <div className={styles.platforms}>
              <span className={styles.platform}>{app.platform}</span>
            </div>
          </div>;
        })}
      </div> */
    );
  }
};

export default AppsList;
