import { Box } from "@mui/material";
import React from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DataFlowItem from "./AiArcDataFlowItem";

const DataFlow = ({ up, flows }) => {
  return (
    <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', rowGap: '6px', whiteSpace: 'nowrap'}}>
      {up && <ArrowUpwardIcon />}
      {!up && <ArrowDownwardIcon />}
      {flows.map((flow) => (
        <DataFlowItem flow={flow} />
      ))}
      {up && <ArrowUpwardIcon />}
      {!up && <ArrowDownwardIcon />}
    </Box>
  );
}
export default DataFlow;