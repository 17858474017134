import React, {useState, useEffect, createContext} from 'react';
import { useNavigate } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import { CardContent, Typography, Grid, Card, Box, TextField, Button, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlatformInfo from './PlatformInfo';
import Data from './Data';

// FirebaseManager.getInstance().init();

const CreateApp = ({user}) => {
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const [createTrigger, setCreateTrigger] = useState(false);
  const [err, setErr] = useState(false);
  const [createdAccApp, setCreatedAccApp] = useState();

  useEffect(() => {
    console.log('createTrigger ' + createTrigger);
    if (createTrigger && input.length) {
      // fetch(`https://api.appviewer.io/createAccountApp?userId=${user.id}&appName=${input}`)
      Data.getInstance().createAppForUser(input)
      .then((data) => {
         console.log(data);
         setCreatedAccApp([data.account, data.app])
         navigate(`/accounts/${data.account}/apps/${data.app}`, {replace: true})
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
  }, [createTrigger]);

  /* useEffect(() => {
    console.log(`createdApp ${createdAccApp} user ${user.apps ? user.apps.map((el) => el.id) : "noApps"}`);
    if (!createdAccApp || !user.apps || !user.apps.length || !user.apps.findIndex((el) => el.id !== createdAccApp[1]) < 0) return;
    navigate("/", {replace: true})
  }, [createdAccApp, user]); */

  useEffect(() => {
    const regex = /^[a-zA-Z0-9](?:[a-zA-Z0-9_-]*[a-zA-Z0-9])?$/;
    setErr(input.length > 0 && (input.length > 40 || !regex.test(input)));
  }, [input]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '80px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      textAlign: 'center',
    }}>
      <Box sx={{
        maxWidth: '600px',
        fontSize: '14px',
      }}>
      <Typography variant='h6'>Set up a new project</Typography>
        <Card sx={{
          height: '180px',
          width: '280px',
          cursor: 'pointer',
          marginTop: '24px',
          marginBottom: '24px',
        }}>
          <CardContent sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
          }}>
          <TextField id="input-app-name" label="My Android App" variant="standard" sx={{marginBottom: '24px'}} value={input} onChange={(ev) => setInput(ev.target.value)} />
            <Button
            variant="outlined"
              disabled={!input.length || err || createTrigger}
              startIcon={createTrigger ? <CircularProgress size={24} /> : <AddCircleOutlineIcon/>}
              onClick={() => {
                setCreateTrigger(true)
              }}>Create Project</Button>
              {err && <Box sx={{color:'red'}}>Invalid name. Only allows a-z, A-Z, 0-9, hyphen, underscores.</Box>}
          </CardContent>
        </Card>
      </Box>
        <Box sx={{
          maxWidth: '600px',
          fontSize: '14px',
        }}>
        Want to join an existing project? Ask the owner/admin for an invite (pass your user ID that you can find by clicking on the fingerprint icon on the top right of this screen)
          </Box>
    </Box>
  );
};

export default CreateApp;
