import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useParams, useNavigate, Link } from 'react-router-dom';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import Data from './Data';
import { AppContext } from './AppFrame';
import MonitorIntro from './MonitorIntro';
import LogOverviewRows from './LogOverviewRows';

const MonitorMain = () => {
  const { accountId, appId } = useParams();
  const { app } = useContext(AppContext);
  const [show, setShow] = useState('none');

  useEffect(() => {
    if (accountId === 'OSjnyurha9B0VDIOufy3' && appId === 'H87PoZ81sOpJ7Ml95pSy') {
      setShow('intro'); return;
    }
    setShow('none');
    if (!app) return;
    const subscription = Data.getInstance().streamIfAnyLogsExist(`Accounts/${accountId}/Apps/${appId}`)
        .subscribe((hasAnyLogs) => setShow(hasAnyLogs ? 'logs' : 'intro'));
    return () => {
      subscription.unsubscribe();
    };
  }, [app]);

  if (show === 'intro') return <MonitorIntro />
  if (show === 'logs') return <LogOverviewRows />
  return <LinearProgress color="secondary" />;
};

export default MonitorMain;