import React, {useEffect, useState, useContext} from 'react';
import {useParams, useNavigate, useNavigationType, useLocation, useSearchParams, createSearchParams} from 'react-router-dom';
import Data from './Data';
import {catchError} from 'rxjs/operators';
import ArrowBack from '@mui/icons-material/ArrowBack';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DevicesIcon from '@mui/icons-material/Devices';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import {Box, Grid, LinearProgress, IconButton, Typography, FormControl, NativeSelect} from '@mui/material';
import {styled} from '@mui/material/styles';
import DocDate from './DocDate';
import StateView from './StateView';
import StateFullView from './StateFullView';
import { AppContext } from './AppFrame';

const MenuButton = styled(IconButton)({
  marginRight: '12px',
});
/* const FormControlSized = styled(FormControl)(({theme}) => ({
  margin: theme.spacing(1),
  minWidth: 120,
})); */

const ScreenDetails = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  console.log('navviiii ' + navigationType + "locState: " + location.state);
  const [searchParams, setSearchParams] = useSearchParams();
  const {accountId, appId, appVersion, screenId} = useParams();
  const {app} = useContext(AppContext);
  const appVersions = app.versions || [];
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState();
  const [uiStatesDoc, setUiStatesDoc] = useState();
  const [screenName, setScreenName] = useState('');

  const selStateId = searchParams.get('state');
  const selDeviceName = searchParams.get('device');
  const filterBy = searchParams.get('filter');
  const isFilteredByState = (filterBy === 'state');

  useEffect(() => {
    const subscription = Data.getInstance().streamUiStates(`Accounts/${accountId}/Apps/${appId}`, parseInt(appVersion, 10), screenId)
        .pipe(catchError(() => setError('This screen cannot be found.')))
        .subscribe((uiDoc) => {
          console.log('LOADED-UI-STATES ' + JSON.stringify(uiDoc));
          setUiStatesDoc(uiDoc);
          setLoadingData(false);
        });

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, appVersion, screenId]);

  useEffect(() => {
    console.log('selStateId ' + selStateId);
    console.log('filterBy ' + filterBy);
    console.log('isFilteredByState ' + isFilteredByState);
    console.log('selDeviceName ' + selDeviceName);
    if (uiStatesDoc) {
      if (isFilteredByState) {
        if (uiStatesDoc.uiStates && uiStatesDoc.uiStates.length) {
          if (!selStateId || !uiStatesDoc.uiStates.find((st) => st.id === selStateId)) {
            setStateQuery(uiStatesDoc.uiStates[0].id);
          }
        } else {
          setError('No states found for this screen.');
        }
      } else {
        if (uiStatesDoc.devicesImages && Object.keys(uiStatesDoc.devicesImages).length) {
          if (!selDeviceName || !uiStatesDoc.devicesImages[selDeviceName]) {
            setDeviceQuery(Object.keys(uiStatesDoc.devicesImages).sort()[0]);
            /* const params = createSearchParams(searchParams);
            params.set('device', Object.keys(uiStatesDoc.devicesImages)[0]);
            navigate(pathname + "?" + params.toString(), { replace: true }); */
          }
        } else {
          setError('No images found for this screen.');
        }
      }
    }
  }, [isFilteredByState, uiStatesDoc, selDeviceName, selStateId]);

  useEffect(() => {
    const subscription = Data.getInstance().streamDocName(`Accounts/${accountId}/Apps/${appId}`, parseInt(appVersion, 10), screenId)
        .subscribe((docName) => {
          setScreenName(docName);
        });
    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, appVersion, screenId]);

  const setDeviceQuery = (deviceName, keepHistory = false) => {
    const newParams = createSearchParams(searchParams);
    newParams.set('device', deviceName);
    setSearchParams(newParams, {replace: !keepHistory});
  };

  const resetParamOrGoBack = (paramName) => {
    if (navigationType === 'PUSH' || location.state === 'CAN_POP') {
      navigate(-1);
    } else {
      const newParams = createSearchParams(searchParams);
      newParams.delete(paramName);
      setSearchParams(newParams, {replace: false});
    }
  };

  const switchToDeviceQuery = (deviceName) => {
    const newParams = createSearchParams(searchParams);
    newParams.delete('filter');
    newParams.delete('state');
    if (deviceName) {
      newParams.set('device', deviceName);
    }
    setSearchParams(newParams, {replace: true});
  };

  const setStateQuery = (stateId, keepHistory = false, state) => {
    const newParams = createSearchParams(searchParams);
    newParams.set('state', stateId);
    setSearchParams(newParams, {replace: !keepHistory, state: keepHistory ? 'CAN_POP' : state});
  };

  const switchToStateQuery = (stateId) => {
    const newParams = createSearchParams(searchParams);
    newParams.delete('device');
    newParams.set('filter', 'state');
    if (stateId) {
      newParams.set('state', stateId);
    }
    setSearchParams(newParams, {replace: true});
  };

  const onDeviceSelect = (selectedValue) => {
    if (!selectedValue || '' === selectedValue) {
      switchToStateQuery();
    } else {
      switchToDeviceQuery(selectedValue);
    }
  };

  const onStateSelect = (selectedValue) => {
    if (!selectedValue || '' === selectedValue) {
      switchToDeviceQuery();
    } else {
      switchToStateQuery(selectedValue);
    }
  };
  
  const onSessionClick = (docId) => {
    navigate(`/accounts/${accountId}/apps/${appId}/monitor?logDocId=${docId}`);
  }

  const getDeviceLabelFromMetaData = (device) => {
    let model = device.meta.model;
    if (model.startsWith("Android SDK")) {
      model = `${isTablet(device.meta) ? 'Tablet' : 'Phone'} Emulator`
    }
    model = `${model} (${(device.meta.width > device.meta.height) ? 'Landscape' : 'Portrait'})`
    return `${device.meta.brand}-${model} (API ${device.meta.os_v})`;
  }

  const isTablet = (deviceMeta) => {
    const widthDp = deviceMeta.width / (deviceMeta.dpi / 160);
    const heightDp = deviceMeta.height / (deviceMeta.dpi / 160);
    const smallerSideDp = Math.min(widthDp, heightDp);
    return smallerSideDp >= 600;
  }

  if (error) return <div><span>{error}</span></div>;
  if (loadingData || (!selDeviceName && !selStateId)) return <LinearProgress color="secondary" />;
  // if (!uiStatesDoc.devicesImages || !Object.keys(uiStatesDoc.devicesImages).length || !uiStatesDoc.devicesImages[selDeviceName]) return <div><span>No images found for this screen/state.</span></div>
  let stateViews;
  if (isFilteredByState) {
    stateViews = Object.entries(uiStatesDoc.devicesImages).map(([deviceId, device]) => {
      const stateFound =
                device.images.find((state) => state.id === selStateId);
                console.log("STATEFOUND " + JSON.stringify(stateFound))
      return !stateFound ? <></> : <StateView onClick={() => setDeviceQuery(deviceId, true)} showFilter={uiStatesDoc.uiStates.length > 1} uniqueForState={false} onFilterByClick={() => switchToDeviceQuery(deviceId)} sessionLink={stateFound.meta ? stateFound.meta.logDocId : null} onSessionLinkClick={()=>onSessionClick(stateFound.meta.logDocId)} key={deviceId} title={device.meta ? getDeviceLabelFromMetaData(device) : deviceId} imageUrl={stateFound.imageUrl} />;
    });
  } else {
    stateViews = uiStatesDoc.devicesImages[selDeviceName].images.map((state) => <StateView onClick={() => setStateQuery(state.id, true)} uniqueForState={true} showFilter={Object.entries(uiStatesDoc.devicesImages).length > 1} onFilterByClick={() => switchToStateQuery(state.id)} sessionLink={state.meta ? state.meta.logDocId : null} onSessionLinkClick={()=>onSessionClick(state.meta.logDocId)} key={state.id} title={state.desc} imageUrl={state.imageUrl} />);
  }

  const timeStampDate = isFilteredByState ? '' : uiStatesDoc.devicesImages[selDeviceName].capturedAt ? uiStatesDoc.devicesImages[selDeviceName].capturedAt : uiStatesDoc.lastModified;
  /* let filterSelect = '';
  if (isFilteredByState && uiStatesDoc.uiStates.length > 1) {
    filterSelect = <Box textAlign='center' position='sticky' top='0' zIndex='1000'>
      {uiStatesDoc.uiStates.map((state) => {
        return (<div key={state.id} className={selStateId === state.id ? styles.deviceBtnSelected : styles.deviceBtn} onClick={(e) => {
          e.stopPropagation();
          setStateQuery(state.id);
        }}>{state.desc}</div>);
      })}
    </Box>;
  } else if (Object.entries(uiStatesDoc.devicesImages).length > 1) {
    filterSelect = <Box textAlign='center' position='sticky' top='0' zIndex='1000'>
      {Object.values(uiStatesDoc.devicesImages).map((device) =>
        (<div key={device.deviceName} className={selDeviceName === device.deviceName ? styles.deviceBtnSelected : styles.deviceBtn} onClick={(e) => {
          e.stopPropagation();
          setDeviceQuery(device.deviceName);
        }}>{device.deviceName}</div>),
      )}
    </Box>;
  } */
  const deviceSelect = <Box sx={{display: 'flex', alignItems: 'center', padding: '12px'}}>
    <DevicesIcon />
    <FormControl sx={{maxWidth: '280px', marginLeft: '12px'}}>
      <NativeSelect
        id="device-select"
        value={selDeviceName ? selDeviceName : ''}
        onChange={(ev) => onDeviceSelect(ev.target.value)}
      >
        <option value=''>All Devices</option>
        {Object.values(uiStatesDoc.devicesImages).sort(device => device.id).map((device) =>
          <option key={device.id} value={device.id}>{device.meta ? getDeviceLabelFromMetaData(device) : device.id}</option>,
        )}
      </NativeSelect>
    </FormControl>
  </Box>;
  const stateSelect = <Box sx={{display: 'flex', alignItems: 'center', padding: '12px'}}><BackupTableIcon />
    <FormControl sx={{marginLeft: '12px', maxWidth: '280px'}}>
      <NativeSelect
        id="state-select"
        value={selStateId ? selStateId : ''}
        onChange={(ev) => onStateSelect(ev.target.value)}
      >
        <option value=''>All States</option>
        {uiStatesDoc.uiStates.map((state) =>
          <option key={state.id} value={state.id}>{state.desc}</option>,
        )}
      </NativeSelect>
    </FormControl>
  </Box>;
  let zoomModal = '';
  if (selStateId && selDeviceName) {
    console.log('selStateId: ' + selStateId);
    if (uiStatesDoc.devicesImages[selDeviceName]) {
      const selState = uiStatesDoc.devicesImages[selDeviceName].images.find((st) => st.id === selStateId);
      console.log('selState: ' + JSON.stringify(selState));
      if (selState) {
        let prevFct; let nextFct; let canNavigate;
        if (isFilteredByState) {
          canNavigate = Object.entries(uiStatesDoc.devicesImages).length > 1;
          const currentIndex = Object.keys(uiStatesDoc.devicesImages).findIndex((device) => device === selDeviceName);
          prevFct = () => setDeviceQuery(Object.keys(uiStatesDoc.devicesImages)[currentIndex === 0 ? currentIndex : currentIndex - 1]);
          nextFct = () => setDeviceQuery(Object.keys(uiStatesDoc.devicesImages)[currentIndex === Object.keys(uiStatesDoc.devicesImages).length - 1 ? currentIndex : currentIndex + 1]);
        } else {
          canNavigate = uiStatesDoc.devicesImages[selDeviceName].images.length > 1;
          const currentIndex = uiStatesDoc.devicesImages[selDeviceName].images.findIndex((state) => state.id === selStateId);
          prevFct = () => setStateQuery(uiStatesDoc.devicesImages[selDeviceName].images[currentIndex === 0 ? currentIndex : currentIndex - 1].id, false, location.state);
          nextFct = () => setStateQuery(uiStatesDoc.devicesImages[selDeviceName].images[currentIndex === uiStatesDoc.devicesImages[selDeviceName].images.length - 1 ? currentIndex : currentIndex + 1].id, false, location.state);
        }
        zoomModal = <StateFullView stateInfo={selState} onClose={() => isFilteredByState ? resetParamOrGoBack('device') : resetParamOrGoBack('state')} canNavigate={canNavigate} onPrevClick={prevFct} onNextClick={nextFct} />;
      }
    }
  }

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
    }}>
      {!uiStatesDoc && <LinearProgress color="secondary" />}
      <Box sx={{display: 'flex', flexWrap: 'wrap', marginBottom: '20px', paddingLeft: '50px', paddingRight: '50px'}}>
        <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', paddingLeft: '16px', paddingRight: '16px', minHeight: '56px'}}>
          <MenuButton edge="start" color="inherit" onClick={(e) => {
            e.stopPropagation();
            navigate(`/accounts/${accountId}/apps/${appId}/ui-gallery/${appVersion}`);
          }}>
            <ArrowBack />
          </MenuButton>
          <Typography variant="h6" sx={{whiteSpace: 'nowrap'}}>{screenName}</Typography>
        </Box>
        <Box marginLeft='20px' marginRight='20px' display='flex' alignItems="center">
          <VerifiedUserIcon color="secondary" />
          <Typography color="secondary" variant="h8">{appVersions.find((v) => v.code === parseInt(appVersion, 10)).name} ({appVersion})</Typography>
          <Box paddingLeft='24px'>{timeStampDate !== '' && <DocDate timeStampDate={timeStampDate} />}</Box>
        </Box>
        <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', minHeight: '56px'}}>
          {deviceSelect}
          {stateSelect}
        </Box>
      </Box>
      <Box sx={{
        // height: 'calc(100vh - 160px)',
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '20px',
        // height: '400px',
        // marginTop: '0px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
        <Grid container spacing={6} columns={{xs: 12, sm: 12, md: 12, lg: 12, xl: 10}}>
          {stateViews}
        </Grid>
      </Box>
      {zoomModal}
    </Box>
  );
};
export default ScreenDetails;
