import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import LogEvent from './LogEvent';


const ComponentDetailsOld = () => {

  const { accountId, appId, appVersion, componentId, action: secondary } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [aggData, setAggData] = useState([]);
  const [aggDataDocIds, setAggDataDocIds] = useState([]);

  useEffect(() => {
    let subscription;
    if (secondary != null) {
      if (parseInt(appVersion, 10) == 172) {
      subscription = Data.getInstance().streamAggLogData172(`Accounts/${accountId}/Apps/${appId}`, parseInt(appVersion, 10), componentId, secondary)
      .subscribe(
        (aggsFound) => {
          if (aggsFound.length) {
            setAggData(aggsFound[0].seen);
            setAggDataDocIds(aggsFound[0].idsSeen);
          }
          setLoading(false);
        },
      );
    } else {
        //stream for group+event >v172
        subscription = Data.getInstance().streamAggLogDataGroupEvent(`Accounts/${accountId}/Apps/${appId}`, parseInt(appVersion, 10), componentId, secondary)
        .subscribe(
          (aggsFound) => {
            if (aggsFound.length) {
              setAggData(aggsFound[0].seen);
              setAggDataDocIds(aggsFound[0].idsSeen);
            }
            setLoading(false);
          },
        );
      }
    } else {
    subscription = Data.getInstance().streamAggLogData(`Accounts/${accountId}/Apps/${appId}`, parseInt(appVersion, 10), componentId)
      .subscribe(
        (aggsFound) => {
          if (aggsFound.length) {
            setAggData(aggsFound[0].seen);
            setAggDataDocIds(aggsFound[0].idsSeeen);
          }
          setLoading(false);
        },
      );
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, appVersion, componentId, secondary]);

  /* useEffect(() => {
    setScreenViews(googleAnalytics.reduce((map, obj) => {
      try {
        const jsObj = JSON.parse(obj.message);
        const distinguishers = ['&t', '&ec', '&ea']
        const attr = jsObj[distinguishers[0]]
        if (attr)
          map[attr] = mapAttributes(jsObj, attr, distinguishers.slice(1, distinguishers.length), map[attr])
      } catch (e) {
        map['other'] = obj.message
      }
      console.log("Returning map " + JSON.stringify(map))
      return map;
    }, {}))
    Object.keys(screenViews).forEach((key) => console.log(`Keys ${key}`))
  }, [googleAnalytics]); */

  const mapAttributes = (jsObj, attr, distinguishers, current) => {
    console.log(`mapAttributes ${JSON.stringify(jsObj)} ${attr} ${distinguishers} ${JSON.stringify(current)}`)
    if (!distinguishers || !distinguishers.length || !jsObj[distinguishers[0]]) { //if only this item doesnt have the attribute but others do, then this should be put into a "others" list
      const arr = (!current ? [] : current).concat(jsObj);
      console.log("Returning " + JSON.stringify(arr))
      return arr;
    } else {
      const attr2 = jsObj[distinguishers[0]] //InspOverview
      const returning = current ? current : {}
      // const obj = (!current || !current[attr2]) ? {test: 1} : current[attr2]
      // returning[attr2] = obj;
      returning[attr2] = mapAttributes(jsObj, attr2, distinguishers.length > 1 ? distinguishers.slice(1, distinguishers.length) : [], current ? current[attr2] : null)
      console.log("Returning " + JSON.stringify(returning))
      return returning;
      // return Object.assign(, {})
    }
  }

  const removeItem = (arr, value) => { 
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const showAttr = (attr, aggDataDocIds, headerLevel, treePos) => {
    console.log("content attr ",attr)
    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
      { (Array.isArray(attr)) ? attr.map((val,i)=>[val,i]).sort((a,b) => a[0]-b[0]).map((pair, i) => {
          let str = pair[0];
          /* let refDoc;
          if (typeof content == 'string') {
            str = content;
          } else {
            refDoc = Object.keys(content)[0]
            str = content[refDoc]
          } */
          var show = "";
          try {
            const jsonObj = JSON.parse(str);
            try {
              delete jsonObj.avType
            } catch(e){}
            show = JSON.stringify(jsonObj, null, 2)
          } catch (e) {
            show = str
          }
          let jump = ""
          if (aggDataDocIds && (pair[1] < aggDataDocIds.length))
        jump = <Button variant='outlined' sx={{maxWidth:'max-content'}} onClick={()=>onSessionClick(aggDataDocIds[pair[1]])}>
          Jump to session 
          {/* {aggDataDocIds[pair[1]]} */}
          </Button>
        return (<Box key={aggDataDocIds ? aggDataDocIds[pair[1]] : i} sx={{display:'flex', flexDirection: 'column'}}><div><pre>{show}</pre></div>{jump}</Box>);
        }) : Object.keys(attr).sort()
        // .map((key0,origI) => {console.log('MAP:',[key0,origI]);return [key0,origI]}).sort((a,b) => a[0]-b[0])
        .map((key1, index) => {
        // const key1 = keyIPair[0]
        // console.log(`showing for key ${key1}: ${JSON.stringify(attr[key1])}`)
        console.log(`showing log level for key ${key1}, lookIn: ${JSON.stringify(aggDataDocIds)}`)
        const items = attr[key1];
        const docIds = aggDataDocIds ? aggDataDocIds[key1] : [];
        const expandKey = treePos+"#"+index;
        const expandThis = (expanded.indexOf(expandKey) >= 0);
        return (<Box>
          <Box sx={{ fontSize: 'h'+headerLevel+'.fontSize', marginLeft: (40*headerLevel)+'px' }}>
            {key1}
            <IconButton color="inherit" onClick={() => setExpanded(old => {
              const expandThis2 = (expanded.indexOf(expandKey) >= 0);
              console.log("expandWas " + old + ". check for " + expandKey + ". expandThis: "+ expandThis2);
              let newarr = old.slice(0)
              if (expandThis2)
                return removeItem(newarr, expandKey);
              else {
                newarr.push(expandKey);
                return newarr;
              }
            })}>
              {expandThis ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          {!expandThis ? "" : showAttr(items, docIds, headerLevel+1, treePos + "#" + index)
          /* (<List sx={{ bgcolor: 'background.paper' }}>
          {[...new Set(items.map(json => JSON.stringify(json, null, 2)))].map((item) => {
            var show = "";
            show = item;
            return (<ListItem alignItems="flex-start" key={show} sx={{ overflow: 'auto' }}>
              <ListItemText
                primary={
                  <Box paddingLeft='24px' sx={{ overflow: 'auto', maxWidth: '200px' }}>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <div><pre>
                        {show}
                      </pre></div>
                    </Typography>
                  </Box>
                } />
            </ListItem>)
          })}
        </List>) */
          }
          {/* {if (!Arrays.isArray(items)) {
            showAttr(items)
          } else {
            
            }
          } */}
      </Box>)
      })}
    </Box>)
  }

  const onSessionClick = (docId) => {
    navigate(`/accounts/${accountId}/apps/${appId}/monitor?logDocId=${docId}`);
  }

  if (loading) return "Loading";

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!aggData ? "Launch test app and collect events first" :

          (<React.Fragment>
            <Box>
          <Box sx={{ fontSize: 'h3.fontSize' }}>{componentId}{(secondary != null) ? (" - "+secondary) : ""}</Box>
              {showAttr(aggData, aggDataDocIds, 4, "ga")}
            </Box>
          </React.Fragment>)}
      </Box>
    </Box>
  );
};

export default ComponentDetailsOld;
