import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import LogEvent from './LogEvent';
import ComponentLogList from './ComponentLogList';


const ComponentDetails = () => {

  const { accountId, appId, appVersion, componentId, action: secondary } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [aggArgData, setAggArgData] = useState([]);
  const [aggData, setAggData] = useState();

  useEffect(() => {
    const subscription = Data.getInstance().streamAggLogDataGroupEvent(`Accounts/${accountId}/Apps/${appId}`, parseInt(appVersion, 10), componentId, secondary)
    .subscribe(
      (aggsFound) => {
        if (aggsFound.length) {
          console.log("ComponentDetails", JSON.stringify(aggsFound[0]))
          if (aggsFound[0].args && Object.values(aggsFound[0].args).length) {
            setAggArgData(Object.values(aggsFound[0].args));
          }
          if (aggsFound[0].message && Object.keys(aggsFound[0].message).length)
            setAggData(aggsFound[0].message);
        }
        setLoading(false);
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, appVersion, componentId, secondary]);

  /* useEffect(() => {
    setScreenViews(googleAnalytics.reduce((map, obj) => {
      try {
        const jsObj = JSON.parse(obj.message);
        const distinguishers = ['&t', '&ec', '&ea']
        const attr = jsObj[distinguishers[0]]
        if (attr)
          map[attr] = mapAttributes(jsObj, attr, distinguishers.slice(1, distinguishers.length), map[attr])
      } catch (e) {
        map['other'] = obj.message
      }
      console.log("Returning map " + JSON.stringify(map))
      return map;
    }, {}))
    Object.keys(screenViews).forEach((key) => console.log(`Keys ${key}`))
  }, [googleAnalytics]); */

  const mapAttributes = (jsObj, attr, distinguishers, current) => {
    console.log(`mapAttributes ${JSON.stringify(jsObj)} ${attr} ${distinguishers} ${JSON.stringify(current)}`)
    if (!distinguishers || !distinguishers.length || !jsObj[distinguishers[0]]) { //if only this item doesnt have the attribute but others do, then this should be put into a "others" list
      const arr = (!current ? [] : current).concat(jsObj);
      console.log("Returning " + JSON.stringify(arr))
      return arr;
    } else {
      const attr2 = jsObj[distinguishers[0]] //InspOverview
      const returning = current ? current : {}
      // const obj = (!current || !current[attr2]) ? {test: 1} : current[attr2]
      // returning[attr2] = obj;
      returning[attr2] = mapAttributes(jsObj, attr2, distinguishers.length > 1 ? distinguishers.slice(1, distinguishers.length) : [], current ? current[attr2] : null)
      console.log("Returning " + JSON.stringify(returning))
      return returning;
      // return Object.assign(, {})
    }
  }

  const removeItem = (arr, value) => { 
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const showAttr = (aggDataDocIds, headerLevel, treePos) => {
    console.log("content aggDataDocIds ",aggDataDocIds)
    return (<Box key={treePos} sx={{ display: 'flex', flexDirection: 'column' }}>
      { (Array.isArray(aggDataDocIds)) ? <ComponentLogList logRefs={aggDataDocIds} /> : Object.keys(aggDataDocIds).sort()
        .map((key1, index) => {
        console.log(`showing log level for key ${key1}, lookIn: ${JSON.stringify(aggDataDocIds)}`)
        const docIds = aggDataDocIds ? aggDataDocIds[key1] : [];
        const expandKey = treePos+"#"+index;
        const expandThis = (expanded.indexOf(expandKey) >= 0);
        return (<Box key={expandKey}>
          <Box sx={{ fontSize: 'h'+headerLevel+'.fontSize', marginLeft: (40*headerLevel)+'px' }}>
            {key1}
            <IconButton color="inherit" onClick={() => setExpanded(old => {
              const expandThis2 = (expanded.indexOf(expandKey) >= 0);
              console.log("expandWas " + old + ". check for " + expandKey + ". expandThis: "+ expandThis2);
              let newarr = old.slice(0)
              if (expandThis2)
                return removeItem(newarr, expandKey);
              else {
                newarr.push(expandKey);
                return newarr;
              }
            })}>
              {expandThis ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          {!expandThis ? "" : showAttr(docIds, headerLevel+1, treePos + "#" + index)
          }
      </Box>)
      })}
    </Box>)
  }

  const onSessionClick = (docId) => {
    navigate(`/accounts/${accountId}/apps/${appId}/monitor?logDocId=${docId}`);
  }

  if (loading) return "Loading";

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {(!aggData && (!aggArgData || !aggArgData.length)) ? "Launch test app and collect events first" :

          (<React.Fragment>
            <Box>
              <Box sx={{ fontSize: 'h3.fontSize' }}>{componentId}{(secondary != null) ? (" - "+secondary) : ""}</Box>
              {(!aggArgData || !aggArgData.length) ? "" : <><span>--></span> {aggArgData.map((aggArg,i) => {
                return showAttr(aggArg.idsSeen, 4, "arg"+i)
              })}</>}
              
              {!aggData ? "" : <><span>&lt;--</span> {showAttr(aggData.idsSeen, 4, "ga")}</>}
            </Box>
          </React.Fragment>)}
      </Box>
    </Box>
  );
};

export default ComponentDetails;
