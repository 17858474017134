import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InsightsIcon from '@mui/icons-material/Insights';
import CircularProgress from '@mui/material/CircularProgress';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  flatMap,
  first,
} from 'rxjs/operators';
import ActivityInOut from './ActivityInOut';
import ActivityLayout from './ActivityLayout';


const AiActivityDocLayout = ({layoutDocId, onVmTypeNameSelected}) => {

  const { accountId, appId, repoSnapshotId } = useParams();
  const [layout, setLayout] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!layoutDocId) return () => { };
    const subscription = Data.getInstance().streamComponent(`Accounts/${accountId}/Apps/${appId}`, repoSnapshotId, layoutDocId)
      .subscribe(
        (comp) => {
          setLayout(comp);
          setLoading(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, repoSnapshotId, layoutDocId]);


  return (
    <Box>
      {loading && <Typography variant="h8" component="h4">Loading...</Typography>}
      {/* {layout && JSON.stringify(layout.fileCheck.layoutElements)} */}
        {(layout && layout.fileCheck && Array.isArray(layout.fileCheck.layoutElements) && layout.fileCheck.layoutElements.length) && <ActivityLayout json={layout.fileCheck.layoutElements} vmVars={layout.fileCheck.layoutVariables} onVmTypeNameSelected={onVmTypeNameSelected} />}
        </Box>
  );
};

export default AiActivityDocLayout;