import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InsightsIcon from '@mui/icons-material/Insights';
import CircularProgress from '@mui/material/CircularProgress';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button, TextField, Tabs, Tab, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  flatMap,
  first,
} from 'rxjs/operators';
import ActivityInOut from './ActivityInOut';
import AiActivityDocLayout from './AiActivityDocLayout';


const AiActivityDoc = () => {

  const MyCard = styled(Card)({
    height: 480,
    width: 400,
    cursor: 'pointer',
    overflowY: 'auto',
  });

  const { accountId, appId, fileDocId } = useParams();
  const appPath = `Accounts/${accountId}/Apps/${appId}`;
  const navigate = useNavigate();
  const [act, setAct] = useState();
  const [activities, setActivities] = useState([]);
  const [viewModels, setViewModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [target, setTarget] = useState();
  const [aiScanTriggerForAct, setAiScanTriggerForAct] = useState();
  const [value, setValue] = React.useState(0);

  /* useEffect(() => {
    if (!act || !snap.repoSnapId) return () => { };
    const subscription = Data.getInstance().streamViewModels(`Accounts/${accountId}/Apps/${appId}`, snap.repoSnapId)
      .subscribe(
        (vms) => {
          setViewModels(vms);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, snap]); */

  useEffect(() => {
    if (!fileDocId) return () => { };
    const subscription = Data.getInstance().streamFile(appPath, fileDocId)
      .subscribe(
        (comp) => {
          setAct(comp);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, fileDocId]);


  useEffect(() => {
    console.log('aiScanTriggered ' + aiScanTriggerForAct);
    if (aiScanTriggerForAct) {
      const subscription = Data.getInstance().startActivityAiScan(appPath, fileDocId)
        .subscribe(() => setAiScanTriggerForAct(null));

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [aiScanTriggerForAct]);

  const onComponentNameSelected = (compName) => {
    navigate(`/accounts/${accountId}/apps/${appId}/aidocs/s/${repoSnapshotId}/q/${compName}`);
  };

  
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      {!act && <Typography variant="h8" component="h4">Loading...</Typography>}
        {act && (<Box sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '2mu0px',
        }}>
        <Typography variant="h4">{act.fileCheck ? act.fileCheck.name : 'Adding'}</Typography>
        {/* {(location.hostname === 'localhost') && (<Typography variant="h8" component="h6">{fileDocId}</Typography>)} */}
        {/* <Typography variant="h8" component="h6">{act.fullPath}</Typography> */}
        {/* <Typography variant="h8" component="h6">{act.state}</Typography> */}
        
        </Box>)}
        {act && (<Box>
          <Paper sx={{width: '840px', padding: '20px', marginTop: '40px'}}>
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '400px'}}><b>AI agent analysis</b></Box>
            <Box><b>Behavior</b></Box>
          </Box>
          {
            act.dummies && act.dummies.filter((entry) => !entry.hide).map((entry, i) =>
              <Box sx={{ width: '800px', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '16px', paddingBottom: '16px', borderTop: (i == 0 ? 0 : 1) + 'px solid #eee' }}>
                <Box sx={{ width: '800px', display: 'flex' }}>
                  <Box sx={{ minWidth: '400px', maxWidth: '400px', paddingRight: '10px' }}>{entry.state === 'loading' ? <CircularProgress /> : entry.result}</Box>
                  <Box>{entry.event}</Box>
                </Box>
                {entry.error && <Box sx={{display: 'flex', alignItems: 'center', padding: '8px', marginTop: '6px', background: '#222', borderRadius: '4px'}}><WarningAmberOutlinedIcon sx={{color:'red', marginRight: '6px'}}/><Box sx={{color: '#e8e8e8'}}>Our agent could no longer verify this behavior in v 3.8.4</Box><Button>Retry</Button><Button>Remove</Button></Box>}
              </Box>
            )
          }
          </Paper>
        </Box>)}
        {act && false && (<Grid item>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <TextField multiline sx={{width: '400px'}} id="standard-basic" label="Event to analyze" variant="standard" />
        {!act.aiResult && <Button
          sx={{width: '200px', marginTop: '10px'}}
          variant="outlined"
          startIcon={act.state === 'aiScanRunning' ? <CircularProgress size={24} /> : <InsightsIcon />}
          disabled={act.state === 'aiScanRunning'}
          onClick={() => {
            setAiScanTriggerForAct(true);
          }}
        >
          Analyze with AI
        </Button>}
        {act.aiResult && act.aiResult.trigger && (
          <Box sx={{width: '1200px', display: 'flex', flexDirection: 'column'}}>
            
            <Paper sx={{width: '840px', padding: '20px', marginTop: '40px'}}>
          <Box sx={{display: 'flex'}}>
            <Box sx={{width: '400px'}}><b>Trigger</b></Box>
            <Box><b>Behavior</b></Box>
          </Box>
          <Box sx={{width: '800px', display: 'flex'}}>
            <Box sx={{minWidth: '400px', maxWidth: '400px'}}>{act.aiResult.trigger}</Box>
            <Box>{act.aiResult.behavior}</Box>
          </Box>
          </Paper>
          <Box sx={{ width: '100%', marginTop: '80px' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab label="GoogleAnalyticsManager" />
                <Tab label="AnalyticsManager" />
                <Tab label="MyBrandsStore" />
                <Tab label="MyBrandsViewModel" />
                <Tab label="MyBrands-View" />
                <Tab label="Summary" />
                <Tab label="Shortened" />
              </Tabs>
              <TabPanel value={value} index={0}>
              The functionality of this class is to manage and track events for Google Analytics. It contains methods to activate Google Analytics, set session information, track page views, track screen views, track product transactions, and track various events such as login, filter applied, category clicked, etc.

To understand what leads to calls to the `GoogleAnalyticsProcessor`'s method `trackGoogleAnalyticsEvent`, we need to analyze the `activate` method. In this method, we can see that events are being subscribed to from the `analyticsManager.eventsStream()`. These events are then mapped to `GoogleAnalyticsEvent` objects and passed to the `googleAnalyticsProcessor.trackGoogleAnalyticsEvent` method.

To understand what happens when events are received from the `AnalyticsManager`'s `eventsStream`, we need to analyze the `activate` method as well. In this method, we can see that various types of events are being subscribed to from the `eventStream`. These events are then mapped to `GoogleAnalyticsEvent` objects and passed to the `googleAnalyticsProcessor.trackGoogleAnalyticsEvent` method.

If `trackGoogleAnalyticsEvent` is called with 'Category: FavoriteBrands, Action: Brand_deleted, Label: brand: Abercrombie & Fitch | attribute: Casual', the following events were received from the `AnalyticsManager`'s `eventsStream`:
- `MyBrandsEvent.RemovedBrand` with brand name 'Abercrombie & Fitch' and brand attribute 'Casual'
              </TabPanel>
              <TabPanel value={value} index={1}>
              Functionality of the class:
The `AnalyticsManager` class is responsible for managing and reporting analytics events in the application. It provides methods to activate the analytics manager, report screen events, and retrieve the stream of events.

To understand what leads to events emitted to callers of the `eventsStream` method, we need to analyze the following steps:

1. The `activate` method is called, which initializes the analytics manager.
2. Inside the `activate` method, several observables are merged using the `mergeArray` operator.
3. Each observable represents a different event source and maps it to a corresponding tracking event.
4. The resulting observables are then merged into a single stream using the `merge` operator.
5. The merged stream is subscribed to, and each emitted event is passed to the `reportScreenEvent` method.

To understand what happens when calls are received on the `reportScreenEvent` method, we need to analyze the following steps:

1. The `reportScreenEvent` method is called with a `TrackingEvent` parameter.
2. The method logs the tracking event using Timber.
3. An `AvEvent` is created using the tracking event and sent to `AvLogger` for further processing.
4. The tracking event is emitted to callers of the `eventsStream` using the `onNext` method of the `eventsStream` subject.

If `MyBrandsEvent.RemovedBrand` with brand name 'Abercrombie & Fitch' and brand attribute 'Casual' was emitted to callers of the `eventsStream` method, the calls received on the `reportScreenEvent` method would be:
"eventType": "RemovedBrand", "brandName": "Abercrombie & Fitch", "brandAttribute": "Casual"
              </TabPanel>
              <TabPanel value={value} index={2}>
              This class is a singleton class called `MyBrandsStore` that represents a store for managing a user's favorite brands. It provides functionality to load, add, and remove brands from the user's list of favorite brands.

To understand what leads to calls to the `AnalyticsManager`'s method `reportScreenEvent`, we need to analyze the following steps:

1. The `stream` variable is an `Observable` that represents the state of the user's favorite brands. It is initialized with a call to `reloads.startWith(Any()).switchMap`.
2. Inside the `switchMap` block, it first checks if the user is not logged in. If not logged in, it emits `MyBrandsLoadState.NotLoggedIn`.
3. If the user is logged in, it makes a network request to get the user's favorite brands using `ApiService.getInstance().getUsersFavoriteBrands(userOpt.get().userToken())`.
4. If the network request is successful, it maps the response to `MyBrandsLoadState.Error` if it is not successful, or to `MyBrandsLoadState.Loaded` if it is successful.
5. Inside the `flatMap` block, it listens for changes in the `changeEvents` `PublishSubject`.
6. If the change event is `MyBrandsChangeEvent.AddBrand`, it makes a network request to add the brand to the user's favorite brands using `ApiService.getInstance().addUsersFavoriteBrand(userOpt.get().userToken(), change.brand.id())`.
7. If the network request is successful, it maps the response to `MyBrandState.ON_LIST` if successful or `MyBrandState.NOT_ON_LIST` if not successful.
8. It then calls `AnalyticsManager.getInstance().reportScreenEvent` with `MyBrandsEvent.SavedBrand` and the brand's name and attribute.
9. If the change event is `MyBrandsChangeEvent.RemoveBrand`, it makes a network request to remove the brand from the user's favorite brands using `ApiService.getInstance().removeUsersFavoriteBrand(userOpt.get().userToken(), change.brand.id())`.
10. If the network request is successful, it maps the response to `MyBrandState.NOT_ON_LIST` if successful or `MyBrandState.ON_LIST` if not successful.
11. It then calls `AnalyticsManager.getInstance().reportScreenEvent` with `MyBrandsEvent.RemovedBrand` and the brand's name and attribute.

To understand what happens when calls are received on the `applyChange` method, we need to analyze the following steps:

1. The `applyChange` method takes a `MyBrandsChangeEvent` as a parameter.
2. It calls `changeEvents.onNext(change)` to emit the change event to the `changeEvents` `PublishSubject`.
3. The emitted change event will be picked up by the `stream` `Observable` and processed accordingly.

If `reportScreenEvent` is called with `MyBrandsEvent.RemovedBrand` with brand name 'Abercrombie & Fitch' and brand attribute 'Casual', the following calls were received on the `applyChange` method:

```
applyChange(MyBrandsChangeEvent.RemoveBrand(Brand("Abercrombie & Fitch", "Casual")))
```
</TabPanel>
              <TabPanel value={value} index={3}>
              The functionality of this class is to handle the logic and data flow for the My Brands feature in the app. It interacts with various data stores and manages the UI state based on user actions and data changes.

To understand what leads to calls to the `applyChange` method in the `MyBrandsStore`, we need to analyze the following steps:

1. Look for any references to the `MyBrandsStore` object in the code.
2. Identify any methods or events that are called on the `mUiEvents` PublishSubject.
3. Look for any instances where the `MyBrandsStore` is modified or updated.
4. Identify any methods or events that are called on the `mUiModelStream` MutableLiveData.
5. Look for any instances where the `mUiModelStream` is observed or subscribed to.

By analyzing these steps, we can determine the flow of data and events that lead to calls to the `applyChange` method in the `MyBrandsStore`.

To understand what happens when calls are received on the `onBrandStarClick` method, we need to analyze the following steps:

1. Look for any references to the `onBrandStarClick` method in the code.
2. Identify any methods or events that call the `onBrandStarClick` method.
3. Look for any instances where the `MyBrandsStore` is modified or updated.
4. Identify any methods or events that are called on the `mUiModelStream` MutableLiveData.
5. Look for any instances where the `mUiModelStream` is observed or subscribed to.

By analyzing these steps, we can determine the flow of data and events that occur when calls are received on the `onBrandStarClick` method.

To determine what calls with what parameters on the `onBrandStarClick` method triggered a call to `applyChange` with `MyBrandsChangeEvent.RemoveBrand(Brand("Abercrombie & Fitch", "Casual"))`, we need to analyze the following steps:

1. Look for any calls to the `onBrandStarClick` method with the specified parameters:
   - In the code provided, we can see that the `onBrandStarClick` method is called in the `postUiEvent` method with the `MyBrandsUiEvent.BrandStarClicked` event. This event is triggered when the user clicks on a brand star in the UI.
   - The `MyBrandsUiEvent.BrandStarClicked` event is created with the `brand` parameter and the `unstar` parameter, which indicates whether the brand should be unstarred.

2. Identify any methods or events that are called within the `onBrandStarClick` method:
   - In the code provided, there are no additional methods or events called within the `onBrandStarClick` method.

3. Look for any instances where the `MyBrandsStore` is modified or updated:
   - In the code provided, we can see that the `MyBrandsStore` is modified or updated in the `onBrandStarClick` method using the `myBrandsStore.applyChange` method.
   - The `applyChange` method is called with the `MyBrandsStore.MyBrandsChangeEvent.RemoveBrand` event when `unstar` is `true`, indicating that the brand should be removed.

4. Identify any methods or events that are called on the `mUiModelStream` MutableLiveData:
   - In the code provided, there are no methods or events called directly on the `mUiModelStream` MutableLiveData within the `onBrandStarClick` method.

5. Look for any instances where the `mUiModelStream` is observed or subscribed to:
   - In the code provided, the `mUiModelStream` MutableLiveData is observed in the `createStream` method using the `subscribe` method.
   - The `createStream` method is called in the `init` block of the `MyBrandsViewModel` class, which ensures that the `mUiModelStream` is observed and updated when the ViewModel is initialized.

Based on this analysis, we can conclude that calls to the `onBrandStarClick` method with the specified parameters (`Brand("Abercrombie & Fitch", "Casual")` and `unstar = true`) will trigger a call to `applyChange` with `MyBrandsChangeEvent.RemoveBrand(Brand("Abercrombie & Fitch", "Casual"))` in the `MyBrandsStore`.
              </TabPanel>
              <TabPanel value={value} index={4}>
              Functionality of the layout:
This layout represents a screen for displaying a list of brands. It consists of a toolbar, a top header section (which may or may not be visible), a teaser section (which may or may not be visible), a long list of brands, and a "Discover" button.

To understand what leads to calls to the `MyBrandsViewModelImpl`'s method `onBrandStarClick`, we need to analyze the following steps:

1. Look for the `myBrandStarClickListener` attribute in the `RecyclerView` with `myBrandSectionedItems` binding expression. This attribute is set to `model::onBrandStarClick`, which means that when the user clicks on the star icon associated with a brand in the long list, the `onBrandStarClick` method of the `MyBrandsViewModelImpl` class will be called.

2. Look for the `myBrandStarClickListener` attribute in the `RecyclerView` with `myBrandItems` binding expression. This attribute is set to `model::onBrandStarClick`, which means that when the user clicks on the star icon associated with a brand in the short list, the `onBrandStarClick` method of the `MyBrandsViewModelImpl` class will be called.

3. Analyze the `onBrandStarClick` method in the `MyBrandsViewModelImpl` class to understand its behavior and what parameters it expects. This method is called when the user clicks on the star icon associated with a brand. It takes two parameters: a `Brand` object and a boolean value indicating whether the brand should be starred or unstarred.

4. Look for the `android:onClick` attribute in the `TextView` with id `loginButton`. This attribute is set to `() -> model.onLoginClick()`, which means that when the user clicks on the login button, the `onLoginClick` method of the `MyBrandsViewModelImpl` class will be called.

5. Look for the `android:onClick` attribute in the `TextView` with style `MyBrandsButtonStyle` and id `discoverFirstButtonVisible`. This attribute is set to `() -> model.onDiscoverClick()`, which means that when the user clicks on the "Discover" button in the top header section, the `onDiscoverClick` method of the `MyBrandsViewModelImpl` class will be called.

6. Look for the `android:onClick` attribute in the `FrameLayout` with style `MyBrandsTeaserWidth`. This attribute is set to `() -> model.onTeaserClick()`, which means that when the user clicks on the teaser section, the `onTeaserClick` method of the `MyBrandsViewModelImpl` class will be called.

Based on the above analysis, the user behavior that triggered a call to the `onBrandStarClick` method with the specified parameters (`Brand("Abercrombie & Fitch", "Casual")` and `unstar = true`) would be the user clicking on the star icon associated with the brand "Abercrombie & Fitch" in either the short list or the long list of brands.
</TabPanel>
              <TabPanel value={value} index={5}>
              Based on the above analysis, the user behavior that triggered a call to the `onBrandStarClick` method with the specified parameters (`Brand("Abercrombie & Fitch", "Casual")` and `unstar = true`) would be the user clicking on the star icon associated with the brand "Abercrombie & Fitch" in either the short list or the long list of brands.
              </TabPanel>
              <TabPanel value={value} index={6}>
              The user clicking on the star icon associated with the brand "Abercrombie & Fitch" in either the short list or the long list of brands
              </TabPanel>
            </Box>
          </Box>
        )}
        {act.aiResult && !act.aiResult.trigger && <ActivityInOut aiResult={act.aiResult} onVmTypeNameSelected={onComponentNameSelected} />}
        {(act.fileCheck.relatedLayoutFiles) && act.fileCheck.relatedLayoutFiles.map((layoutFile) =>
          <Box key={layoutFile.id} sx={{marginTop: '20px'}}>
            <Typography variant="h8" component="h4">Layout: {layoutFile.name}</Typography>
            {/* {layoutFile.bindsViewModel && (<Button variant="outlined">AI check layout</Button>)} */}
            {layoutFile.bindsViewModel && <AiActivityDocLayout layoutDocId={layoutFile.id} onVmTypeNameSelected={onComponentNameSelected} />}
            {layoutFile.aiResult && !layoutFile.bindsViewModel && <Typography variant="h8" component="h6">Not binding ViewModel</Typography>}
            </Box>
        )}
      </Box>
    </Grid>)}
        </Box>
  );
};

export default AiActivityDoc;