import React from 'react';
import { Box, Chip, Tooltip, Typography, Card, CardContent, Button, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import AiTextExpandable from './AiTextExpandable';

const ActivityInOut = ({ aiResult, onVmTypeNameSelected }) => {
  const sortedUsages = aiResult.variableUsages.map((variable) => {
    return {
      ...{ name: variable.name, type: variable.type, parentType: variable.parentType },
      usages: variable.usages.sort((a, b) => {
        if (a.isFunctionCall && !b.isFunctionCall) return -1;
        if (!a.isFunctionCall && b.isFunctionCall) return 1;
        if (a.isAnObservedLiveDataStream && !b.isAnObservedLiveDataStream) return -1;
        if (!a.isAnObservedLiveDataStream && b.isAnObservedLiveDataStream) return 1;
        return 0;
      }),
    };
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
    }}>
      {sortedUsages.map((variable) => (
        <Box key={variable.name}>
          <Box sx={{
            display: 'flex',
          }}>
            <Box>
              {variable.usages.map((usage, index) => (
                <Box key={index} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '4px',
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    {(usage.functionAnalysis && (usage.functionAnalysis.analysis || usage.functionAnalysis.answer)) && <AiTextExpandable usageAnalysis={usage.functionAnalysis} />}
                    {(usage.liveDataAnalysis && (usage.liveDataAnalysis.analysis || usage.liveDataAnalysis.answer)) && <AiTextExpandable usageAnalysis={usage.liveDataAnalysis} />}
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '300px',
                    }}>
                      {usage.isFunctionCall && !usage.isAnObservedLiveDataStream && <ArrowForwardIcon />}
                      {usage.isAnObservedLiveDataStream && <ArrowBackIcon />}
                      <Box key={index} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '4px',
                }}>
                      <Typography variant="body2" sx={{ paddingRight: '4px' }}>{usage.varOrFunctionAccess}</Typography>
                      {usage.functionAnalysis && usage.functionAnalysis.passedFunctionParameters && <Box sx={{
                    marginLeft: '4px',
                    //backgroundColor: '#ccc'
                  }}>
                    <Tooltip title={usage.functionAnalysis.passedFunctionParameters}>
                    <Chip label={usage.functionAnalysis.passedFunctionParameters.substring(0, 40) + '...'} />
                    </Tooltip>
                  </Box>}
                      </Box>
                      {/* {(usage.functionAnalysis && (usage.functionAnalysis.analysis || usage.functionAnalysis.answer)) && <Tooltip title={`${usage.functionAnalysis.analysis} ### ${usage.functionAnalysis.answer}`}>
                      <InfoIcon fontSize="small" />
                    </Tooltip>}
                    {(usage.liveDataAnalysis && (usage.liveDataAnalysis.analysis || usage.liveDataAnalysis.answer)) && <Tooltip title={`${usage.liveDataAnalysis.analysis} ### ${usage.liveDataAnalysis.answer}`}>
                      <InfoIcon fontSize="small" />
                    </Tooltip>} */}
                    </Box>
                  </Box>
                  
                </Box>
              ))}
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '40px'
            }}>
              <Card sx={{
                display: 'flex',
                width: '240px',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <CardContent>
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <IconButton
                    sx={{ marginTop: '0px' }}
                    variant="outlined"
                    onClick={() => onVmTypeNameSelected(variable.type)}
                  ><ZoomInOutlinedIcon /></IconButton>
                  <Typography variant="subtitle2">{variable.type}</Typography>
                  </Box>
                  
                  {variable.parentType && <Box sx={{display: 'flex', alignItems: 'center'}}><IconButton
                    sx={{ marginTop: '0px' }}
                    variant="outlined"
                    onClick={() => onVmTypeNameSelected(variable.parentType)}
                  ><ZoomInOutlinedIcon /></IconButton>
                  <Typography variant="subtitle2">{variable.parentType}</Typography>
                  </Box>}
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ActivityInOut;