import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TableVirtuoso, TableComponents } from 'react-virtuoso'
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, Button, ButtonGroup, Paper, Tooltip, Chip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { CheckCircle, RemoveCircle, AddOutlined } from '@mui/icons-material';
import PlatformInfo from './PlatformInfo';
import LogMessageRow from './LogMessageRow';
import LogEvent from './LogEvent';
import SessionSelector from './SessionSelector';
import { groupBy } from './Fct.js'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { distinctUntilChanged, tap, map, scan, filter, sampleTime } from 'rxjs/operators';
import { merge } from 'rxjs';
dayjs.extend(relativeTime);


const LogOverviewRows = () => {

  const { accountId, appId } = useParams();
  const appPath = `Accounts/${accountId}/Apps/${appId}`;
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [allLogs, setAllLogs] = useState([new Set(), []]);
  const [logs, setLogs] = useState([new Set(), []]);
  const [groups, setGroups] = useState();
  const [nGroups, setnGroups] = useState(0);
  const [selectedGroupFilters, setSelectedGroupFilters] = useState();
  const [allGroupFilters, setAllGroupFilters] = useState([]);
  const [latestLogTime, setLatestLogTime] = useState();
  const [earliestLogTime, setEarliestLogTime] = useState();

  const [catMap, setCatMap] = useState();
  const [brandMap, setBrandMap] = useState();
  const [colorMap, setColorMap] = useState();
  const sessionSelected = searchParams.get('session');
  const sessionAtDoc = searchParams.get('logDocId');
  const [open, setOpen] = useState();

  const groupCompareFct = (a, b) => {
    if (a=='Screens') return -1
    if (b=='Screens') return 1
    return a.localeCompare(b)
  }

  useEffect(() => {
    // Load the saved group filter from local storage
    const savedGroupFilter = localStorage.getItem('groupFilter');
    setSelectedGroupFilters(savedGroupFilter ? JSON.parse(savedGroupFilter) : []);
  }, []);

  useEffect(() => {
    if (!selectedGroupFilters) return; //make sure saved groups loaded first
    const sortedSelectedGroupFilters = selectedGroupFilters.slice().sort(groupCompareFct);

    const onlyAdditionalGroups = !groups ? [] : groups.filter(group => !selectedGroupFilters.includes(group));

    const combinedArray = sortedSelectedGroupFilters.map(group => [group, true])
      .concat(onlyAdditionalGroups.map(group => [group, false]));
    setAllGroupFilters(combinedArray)
  }, [selectedGroupFilters, groups]);

  const saveGroupFilter = (groupString) => {
    // Add groupString to the current group filter
    const updatedGroupFilter = [...selectedGroupFilters, groupString];
    setSelectedGroupFilters(updatedGroupFilter);

    // Save the updated group filter to local storage
    localStorage.setItem('groupFilter', JSON.stringify(updatedGroupFilter));
  };

  const removeGroupFilter = (groupString) => {
    // Remove groupString from the current group filter
    const updatedGroupFilter = selectedGroupFilters.filter((group) => group !== groupString);
    setSelectedGroupFilters(updatedGroupFilter);

    // Save the updated group filter to local storage
    localStorage.setItem('groupFilter', JSON.stringify(updatedGroupFilter));
  };

  useEffect(() => {
    const subscription = Data.getInstance().streamCategories(appPath)
      .subscribe(
        (maps) => {
          setCatMap(maps);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId]);

  useEffect(() => {
    const subscription = Data.getInstance().streamBrands(appPath)
      .subscribe(
        (maps) => {
          setBrandMap(maps);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId]);

  useEffect(() => {
    const subscription = Data.getInstance().streamColors(appPath)
      .subscribe(
        (maps) => {
          setColorMap(maps);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId]);

  useEffect(() => {
    console.log("loadLogChange start")
    if (!isValidStr(sessionSelected) || !selectedGroupFilters) return () => {};
    setLogs([new Set(), []])
    const subscription = processResult(Data.getInstance().streamLogChanges(appPath, sessionSelected))
      .subscribe(
        (logMap) => {
          setnGroups(logMap[0].size);
          setGroups([...logMap[0]]);
          setLogs(logMap);
          // setAllLogs(logMap);
          console.log(logMap[1])
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, sessionSelected, selectedGroupFilters]);

  useEffect(() => {
    console.log("sessionAtDoc start")
    if (!isValidStr(sessionAtDoc) || !selectedGroupFilters) return () => {};
    setLogs([new Set(), []])
    const subscription = processResult(Data.getInstance().streamLogChangesFromDocId(appPath, sessionAtDoc))
      .subscribe(
        (logMap) => {
          setnGroups(logMap[0].size);
          setGroups([...logMap[0]]);
          setLogs(logMap);
          // setAllLogs(logMap);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, sessionAtDoc, selectedGroupFilters]);

  useEffect(() => {
    const filtered = allLogs
    /* setnGroups(allLogs[0].size);
    setGroups([...allLogs[0]]);
    setLogs(allLogs);     */
  }, [allLogs]);

  const processResult = (obs) => {
    return obs.pipe(
      tap(newItem => {
        console.log("loadLogChange " + JSON.stringify(newItem.docId))
        /* if (list && list.length>0){
          setLatestLogTime(list[0].createdAt);
          setEarliestLogTime(list[list.length-1].createdAt);
        } */
      }),
      map(item => {
        if (item.id) {
          item.group = item.id
          item.event = item.id
        } else if (item.caller) {
          item.group = item.caller
          item.event = item.action
          item.inst = item.callerInst
        }
        if (item.group && item.group.endsWith('Activity')) {
          var packages = item.group.split(".");
          const activityName = packages[packages.length-1]
          item.screen = activityName
          item.group = "Screens"
        }
        return item;
      }),
      scan((setArr, item) => {
        if (item.group !== "AV_SYS_LOGS")
          setArr[0].add(item.group);
        // console.log(setArr[0]);
        return [setArr[0], item];
      }, [new Set(["Screens"]), {}]), // rx-acc a Set with the groups, so they are ordered by appearance (don't jump around). The index determines the indent by px for each occured event.
      // filter(setItemArr => !selectedGroupFilters || selectedGroupFilters.length == 0 || selectedGroupFilters.includes(setItemArr[1].group) || setItemArr[1].group === "AV_SYS_LOGS"), //also allow an item if its ID is the ?focused=LOG_ID (to be impl., nice for link sharing)
      // skip(1),
      // tap(item => console.log(item)),
      scan((accSetList, setItemArr) => {
        const idSet = setItemArr[0];
        console.log("idSet");
        console.log(idSet);
        const newItem = setItemArr[1];
        const currList = accSetList[1];
        if (selectedGroupFilters && selectedGroupFilters.length > 0 && !selectedGroupFilters.includes(setItemArr[1].group) && setItemArr[1].group !== "AV_SYS_LOGS") {
          //also allow an item if its ID is the ?focused=LOG_ID (to be impl., nice for link sharing)
          return [idSet, currList]; //make sure to also return updated groups(idSet) if item itself is filtered out by current selectedGroupFilters
        }
        if (newItem.v >= 3 && newItem.sdkV >= 4) {
          const newItemTime = newItem.msInSession
          const currLatestTime = (currList.length == 0) ? 0 : currList[0].msInSession
          const appendedArr = [...[newItem], ...currList].filter(el => !el.avdiff).sort((a,b) => b.sessionLogIndex - a.sessionLogIndex)
          var i = appendedArr.length - 1
          do { 
            if (i > 0) {
              const currItem = appendedArr[i]
              const currItemTime = currItem.msInSession
              const newerItem = appendedArr[i - 1]
              const newerItemTime = newerItem.msInSession
              const diff = (newerItemTime - currItemTime)/1000
              // console.log(`timediff newerItemTime ${newerItemTime} currItemTime ${currItemTime} diff ${diff}`)
              if (diff > 1) {
                appendedArr.splice(i, 0, {docId: currItem.docId+'time', avdiff: diff, sessionLogIndex: currItem.sessionLogIndex+0.1});
              }
              // console.log("newArr " + appendedArr.map(el => el.docId))
              i = i - 1;
            }
          } while (i > 0)
          /* const indexOfNewItem = appendedArr.findIndex(val => val.docId == newItem.docId)
          const prevIndex = indexOfNewItem + 1
          const hasPrevLog = appendedArr.length > prevIndex
          console.log(`hasPrevLog ${hasPrevLog} indexOfNewItem ${indexOfNewItem}`)
          if (hasPrevLog) {
            const prevItem = appendedArr[prevIndex];
            const prevItemTime = prevItem.msInSession
            const diff = (newItemTime - prevItemTime)/1000
            console.log(`prevItemTime ${prevItemTime} diff ${diff}`)
            if (diff > 1) {
              appendedArr.splice(prevIndex, 0, {docId: newItem.docId+'time', avdiff: diff, msInSession: prevItemTime, sessionLogIndex: parseFloat((parseFloat(prevItem)+0.1).toFixed(2))});
            }
          } */
          return [idSet, appendedArr];
        } else {
          const newItemTime = newItem.createdAt.toMillis()
          const currLatestTime = (currList.length == 0) ? 0 : currList[0].createdAt.toMillis()
          const comingLate = currList.length > 0 && (currLatestTime > newItemTime)
          const diff = (comingLate || currList.length == 0) ? 0 : (newItemTime - currLatestTime)/1000;
          const timeDiff = (diff <= 1) ? [] : [{docId: newItem.docId+'time', avdiff: diff, avtimems: currLatestTime+1}]
          const appendedArr = [...[newItem,...timeDiff], ...currList]
          if (comingLate){
            console.log("TOOLATE")
            appendedArr.sort((a,b) => (b.avtimems || b.createdAt.toMillis()) - (a.avtimems || a.createdAt.toMillis()))
          }
          const toReturn = [idSet, appendedArr];
          // console.log(`idSet ${idSet.size} newItem ${JSON.stringify(newItem.docId)} currList ${currList.length} timeDiff ${JSON.stringify(timeDiff)} toReturn ${JSON.stringify("toReturn")}`);
          return toReturn;
        }
      },[new Set(), []]),//the caller/ids set+the overall list incl time gaps
      // map(list => groupBy(list, ((item) => item.caller || item.id), (list) => list.sort((a,b) => b.createdAt.toMillis() - a.createdAt.toMillis()))),
      // tap((res) => console.log('streamLogCols ', !res ?"none" : res[0].size)),
      sampleTime(50),
    )
  }

  const isValidStr = (toCheck) => {
    // console.log("checksession ", toCheck)
    return ((typeof toCheck === 'string') && toCheck.length > 0)
  }
  
  const colWidth = 200

  // const vTableCallback = useCallback(() => <Footer {...props} />, []);


  
  const handleClose = () => {
    setOpen();
  };

  const handleGroupFilterClick = (groupName, adding) => {
    // const selectedOrNot = allGroupFilters.find(([name]) => name === groupName)[1];
    // const action = selectedOrNot ? 'remove' : 'add';
    if (adding) {
      saveGroupFilter(groupName);
    } else {
      removeGroupFilter(groupName);
    }
    console.log(`User clickedadding ${adding} for group ${groupName}`);
  };

  return (
    <React.Fragment>
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      // paddingTop: '40px',
      paddingBottom: '20px',
      background: '#f1f1f1',
      overflowY: 'hidden',
      overflowX: 'hidden',
    }}>
        <SessionSelector />
        {(!isValidStr(sessionSelected) && !isValidStr(sessionAtDoc)) ? "" : <GroupFilters allGroupFilters={allGroupFilters} handleGroupFilterClick={handleGroupFilterClick} />}

        {(!isValidStr(sessionSelected) && !isValidStr(sessionAtDoc)) ? <Box sx={{paddingLeft: '50px'}}>Launch test app or select device &amp; session above first</Box> : 
        ((nGroups == 0 || logs[1].length == 0) ? <Box sx={{height: '100%'}}/> : (<Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '20px',
          paddingRight: '20px',
          overflowY: 'auto',
          overflowX: 'auto',
        }}>
          <LogTable logGroups={(!selectedGroupFilters || selectedGroupFilters.length == 0) ? groups : selectedGroupFilters.slice().sort(groupCompareFct)} logArr={logs[1]} colWidth={colWidth} onOpen={(rowLog) => setOpen(rowLog)} />
          {/* <Box sx={{
            display: 'flex',
          }}>{
            [...logs[0]].map(group => <Box sx={{width: colWidth+'px', border: 1}}><Typography noWrap>{group}</Typography></Box>)
          }</Box>
          {
            logs[1].map(log => {
              if (log.avdiff)
                return <TimeDiff key={log.docId} timePassed={log.avdiff} />
              else
                return <LogRow key={log.docId} log={log} indent={colWidth*([...logs[0]].indexOf(log.group))}/>
            })
          } */}
        </Box>))
        }
    </Box>
    <Dialog
    fullWidth={false}
    maxWidth={'md'}
    open={open != null}
    onClose={handleClose}
  >
    {open && (<>
    <DialogTitle>{open.group}: {open.event}</DialogTitle>
    <DialogContent>
      {/* <DialogContentText>
        You can set my maximum width and whether to adapt or not.
      </DialogContentText> */}
      <Box>
        {window.location.href.startsWith("http://localhost") ? open.docId : ""}
      <LogMessageRow log={open} showjsTree={true} fullString={true} addableAsTestCase={true} appPath={appPath} />
        {/* <ReactJson src={open} theme="codeschool" name={false} collapsed={1} collapseStringsAfterLength={15} groupArraysAfterLength={10} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} /> */}
        {open.msInSession && <Box sx={{fontSize: '10px'}}>@{open.msInSession.toLocaleString()}ms</Box>}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
    </>)}
  </Dialog>
  </React.Fragment>
  );
};

export default LogOverviewRows;

const LogTable = ({logArr, logGroups, colWidth, onOpen}) => {
  
  function fixedHeaderContent(){ 
    // console.log("VIRTU fixedHeaderContent fctrender " + logGroups.length);
    // return <VTableHeader a={2000} />
    return (
    <TableRow>
      {
      logGroups.map((column) => (
        <Tooltip key={column} title={column}>
          <TableCell
            variant="head"
            align='left'
            style={{ maxWidth: colWidth+'px', minWidth: colWidth+'px', overflowX:'hidden' }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column}
          </TableCell>
        </Tooltip>
      ))}
    </TableRow>
  );}

  const resolveSysMsg = (log) => {
    if (log.event == "mocks_received")
      return "Test values received"
    if (log.event == "mock_read")
      return "Test value read: " + (log.args.length ? log.args[0] : "-")
    return log.event
  }
  
  function rowContent(_index, rowLog) {
    const isTime = !rowLog.group
    const isSysMsg = rowLog.group === "AV_SYS_LOGS"
    const isFullRow = isTime || isSysMsg
    const rowGrpIndex = isFullRow ? -1 : logGroups.indexOf(rowLog.group)
    return (
      <React.Fragment>
        {/* {_index+ ""+((!isTime && _index > 0 && logs[1][_index-1].createdAt) ? (rowLog.createdAt.toMillis() - logs[1][_index-1].createdAt.toMillis()) : "NAP")} */}
        {logGroups.filter((gr, i) => (isFullRow && i == 0) || (!isFullRow && i <= rowGrpIndex)).map((column, index) => {
          if (isTime) {
              return (<TableCell
              key={column}
              align='left'
              colSpan={logGroups.length}
              style={{ maxWidth: colWidth+'px', minWidth: colWidth+'px', padding: '0px' }}
            >
              {<TimeDiff timePassed={rowLog.avdiff} />}
            </TableCell>)
          } else if (isSysMsg) {
            return (<TableCell
              key={column}
              align='left'
              colSpan={logGroups.length}
              style={{ maxWidth: colWidth+'px', minWidth: colWidth+'px', padding: '0px' }}
            >
              {<Box sx={{display:'flex', justifyContent:'center', padding:'2px', background:'#eee', color:'#00897A', fontSize: '12px'}}>{resolveSysMsg(rowLog)}</Box>}
            </TableCell>)
          } else {
            const isRelCol = rowLog.group && rowLog.group == column;
            return (<TableCell
              key={column}
              align='left'
              colSpan={isRelCol ? (logGroups.length - rowGrpIndex) : 1}
              style={{ maxWidth: colWidth+'px', minWidth: colWidth+'px', padding: '0px', cursor: isRelCol ? 'pointer' : 'auto' }}
              onClick={() => onOpen(rowLog)}
            >
              {isRelCol ? <LogMessageRow log={rowLog}/> : ""
              // + rowLog.docId
              }
            </TableCell>)
          }
        })}
      </React.Fragment>
    );
  }
  const VFiller = ({ height }) => {
    return (
      <tr>
        <td
          colSpan={logGroups.length || 1}
          style={{ height: height, padding: 0, border: 0 }}
        ></td>
      </tr>
    );
  }
  return <TableVirtuoso
  data={logArr}
  components={{
    Scroller: VScroller,
    Table: VTable,
    TableHead,
    TableRow: VTRow,
    TableBody: VBody,
    // set the colspan below to the amount of columns you have.
    FillerRow: VFiller,
  }}
  fixedHeaderContent={fixedHeaderContent}
  itemContent={rowContent}
/>
}

const VScroller = React.forwardRef((props, ref) => (
  <TableContainer component={Paper} {...props} ref={ref} />
))
const VTRow = ({ item: _item, ...props }) => <TableRow {...props} />

const VTable = ({...props}) => <Table {...props} sx={{ borderCollapse: 'separate' }} />

const TimeDiff = ({timePassed}) => {
  // {console.log("timePassed: "+JSON.stringify(timePassed))}
  if (!timePassed) return "";
  return <Box sx={{display:'flex', justifyContent:'center', padding:'2px', background:'#eee', color:'#ccd'}}><i>{timePassed}s passed</i></Box>
}

const VBody = React.forwardRef((props, ref) => {
  // console.log("VBody render");
  return <TableBody {...props} ref={ref} />
})

const GroupFilters = ({ allGroupFilters, handleGroupFilterClick }) => {

  return (
    <Box sx={{
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '6px',
    }}>
      <Box sx={{
        display: 'flex',
      }}>
        <Typography sx={{ marginRight: '8px' }} variant="overline">Filters:</Typography>
        <Box sx={{
          display: 'flex',
          paddingBottom: '4px',
          overflowY: 'hidden',
          overflowX: 'auto',
          '::-webkit-scrollbar-track': {
            background: "#00000000",
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#00000000',
          },

          '::-webkit-scrollbar': {
            height: '6px',
          },
          '&:hover': {
            '::-webkit-scrollbar-track': {
              background: "#e8e8e8",
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#ddd',
              borderRadius: 8,
            }
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#bbb'
          }
        }}>
          {allGroupFilters.filter(([groupName, selectedOrNot]) => selectedOrNot).map(([groupName, selectedOrNot]) => groupName).map((name) =>
            // <Typography sx={{ marginRight: '8px'}} variant="overline">{name}</Typography>
            <Chip key={name} sx={{ marginRight: '4px' }} variant="outlined" color="secondary" label={name} onDelete={() => handleGroupFilterClick(name, false)} />
          )}
          {allGroupFilters.filter(([groupName, selectedOrNot]) => !selectedOrNot).map(([groupName, selectedOrNot]) => groupName).map((name) =>
            // <Typography sx={{ marginRight: '8px'}} variant="overline">{name}</Typography>
            <Chip key={name} sx={{ marginRight: '4px' }} variant="outlined" color="primary" icon={<AddOutlined />} label={name} onClick={() => handleGroupFilterClick(name, true)} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
