const code = `/**
* A [NewsResource] with additional user information such as whether the user is following the
* news resource's topics and whether they have saved (bookmarked) this news resource.
*/
data class UserNewsResource internal constructor(
   val id: String,
   val title: String,
   val content: String,
   val url: String,
   val headerImageUrl: String?,
   val publishDate: Instant,
   val type: String,
   val followableTopics: List<FollowableTopic>,
   val isSaved: Boolean,
   val hasBeenViewed: Boolean,
) {
   constructor(newsResource: NewsResource, userData: UserData) : this(
       id = newsResource.id,
       title = newsResource.title,
       content = newsResource.content,
       url = newsResource.url,
       headerImageUrl = newsResource.headerImageUrl,
       publishDate = newsResource.publishDate,
       type = newsResource.type,
       followableTopics = newsResource.topics.map { topic ->
           FollowableTopic(
               topic = topic,
               isFollowed = topic.id in userData.followedTopics,
           )
       },
       isSaved = newsResource.id in userData.bookmarkedNewsResources,
       hasBeenViewed = newsResource.id in userData.viewedNewsResources,
   )
}`;

export default code;