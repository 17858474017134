import {Box, Grid, Typography, IconButton, Tooltip} from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';

const StateView = ({title, imageUrl, onClick, showFilter, uniqueForState, sessionLink, onFilterByClick, onSessionLinkClick}) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Box sx={{
        // padding: '0px',
        // height: '640px',
        // width: '560px',
        // width: {xs: '220px', md: '420px', xl: '560px'},
        textAlign: 'center',
      }}>
        <Box key={imageUrl} component="img" alt={title} onClick={(e) => {
          e.stopPropagation(); onClick();
        }} src={imageUrl} sx={{
          'display': 'flex',
          // height: 'auto',
          // width: 'auto',
          'margin': 'auto',
          'marginTop': '20px',
          // paddingBottom: '20px',
          'maxWidth': '100%',
          // maxWidth: {xs: '200px', md: '400px', xl: '540px'},
          'maxHeight': '500px',
          // background: 'red',
          'cursor': 'zoom-in',
          'outline': '1px solid rgba(180, 180, 180, 0.3)',
          '&:hover': {
            outline: '2px solid #00B0FF',
          },
        }} />
      </Box>
      <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '8px'}}>
        <Typography variant="caption" sx={{maxHeight: '60px', overflow: 'hidden', marginRight: '10px'}}>
          {title}
        </Typography>
        {showFilter && <Tooltip title={uniqueForState ? 'List all devices for this state' : 'List all states for this device'}>
          <IconButton onClick={(ev) => {
            ev.stopPropagation(); onFilterByClick();
          }}>
            {uniqueForState ? <DevicesIcon /> : <BackupTableIcon />}
          </IconButton>
        </Tooltip>}
        {sessionLink && <Tooltip title='Jump to app session where this state was observed.'>
          <IconButton onClick={(ev) => {
            ev.stopPropagation(); onSessionLinkClick();
          }}>
            <PlaylistPlayIcon />
          </IconButton>
        </Tooltip>}
      </Box>
    </Grid>
  );
};
export default StateView;
