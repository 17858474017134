import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import InsightsIcon from '@mui/icons-material/Insights';
import CircularProgress from '@mui/material/CircularProgress';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  flatMap,
  first,
} from 'rxjs/operators';
import ActivityInOut from './ActivityInOut';
import AiDocsActivityTile from './AiDocsActivityTile';


const AiCompSelect = () => {

  const MyCard = styled(Card)({
    height: 480,
    width: 400,
    cursor: 'pointer',
    overflowY: 'auto',
  });

  const { accountId, appId, repoSnapshotId, componentNameQuery } = useParams();
  const navigate = useNavigate();
  const [components, setComponents] = useState([]);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    if (!accountId || !appId || !repoSnapshotId || !componentNameQuery || componentNameQuery.length == 0) return () => {};
    const subscription = Data.getInstance().streamComponentsByName(`Accounts/${accountId}/Apps/${appId}`, repoSnapshotId, componentNameQuery)
      .subscribe(
        (list) => {
          console.log("streamComponentsByName " + componentNameQuery + "-> " + JSON.stringify(list));
          if (list.length == 1) {
            const onlyComp = list[0];
            navigate(`/accounts/${accountId}/apps/${appId}/aidocs/s/${repoSnapshotId}/c/${onlyComp.docId}`, {replace: true});
          } else {
            setComponents(list);
            setLoading(false);
          }
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, repoSnapshotId, componentNameQuery]);

  const onComponentSelected = (compDocId) => {
      navigate(`/accounts/${accountId}/apps/${appId}/aidocs/s/${repoSnapshotId}/c/${compDocId}`);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      {!loading && (!components || components.length == 0) && "No components found"}
      {loading ? "Loading components" : components.map((comp) => 
        <MyCard onClick={() => onComponentSelected(comp.docId)}><CardContent>
        <Typography variant="h8" component="h4">{comp.name}</Typography>
        <Typography variant="h8" component="h6">{comp.path}</Typography>
      </CardContent></MyCard>
      )}
    </Box>
  );
};

export default AiCompSelect;
