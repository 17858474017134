// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {getAuth, GoogleAuthProvider, EmailAuthProvider} from 'firebase/auth';
import FirebaseManager from './FirebaseManager';
import styles from './css/Login.module.css';

// FirebaseManager.getInstance().init();

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function Login() {
  return (
    <div className={styles.container}>
      <img src="/av_txtlogo 1200.png" className={styles.logo} />
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={getAuth()}
      />
      {/* <div className={styles.terms}>
        <span>
          You hereby agree to our terms of service.
        </span>
      </div> */}
    </div>
  );
}

export default Login;
