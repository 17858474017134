import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InsightsIcon from '@mui/icons-material/Insights';
import CircularProgress from '@mui/material/CircularProgress';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  flatMap,
  first,
} from 'rxjs/operators';
import ActivityInOut from './ActivityInOut';
import AiActivityDocLayout from './AiActivityDocLayout';


const AiActivityDocOfRepoSnap = () => {

  const MyCard = styled(Card)({
    height: 480,
    width: 400,
    cursor: 'pointer',
    overflowY: 'auto',
  });

  const { accountId, appId, repoSnapshotId, componentDocId } = useParams();
  const navigate = useNavigate();
  const [act, setAct] = useState();
  const [activities, setActivities] = useState([]);
  const [viewModels, setViewModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aiScanTriggerForAct, setAiScanTriggerForAct] = useState(false);

  /* useEffect(() => {
    if (!act || !snap.repoSnapId) return () => { };
    const subscription = Data.getInstance().streamViewModels(`Accounts/${accountId}/Apps/${appId}`, snap.repoSnapId)
      .subscribe(
        (vms) => {
          setViewModels(vms);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, snap]); */

  useEffect(() => {
    if (!componentDocId) return () => { };
    const subscription = Data.getInstance().streamComponent(`Accounts/${accountId}/Apps/${appId}`, repoSnapshotId, componentDocId)
      .subscribe(
        (comp) => {
          setAct(comp);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, repoSnapshotId, componentDocId]);


  useEffect(() => {
    console.log('aiScanTriggered ' + aiScanTriggerForAct);
    if (aiScanTriggerForAct) {
      const subscription = Data.getInstance().startActivityAiScan(act.avPath, act.docId)
        .subscribe(() => setAiScanTriggerForAct(false));

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [aiScanTriggerForAct]);

  const onComponentNameSelected = (compName) => {
    navigate(`/accounts/${accountId}/apps/${appId}/aidocs/s/${repoSnapshotId}/q/${compName}`);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      {!act && <Typography variant="h8" component="h4">Loading...</Typography>}
        {act && (<Box sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '40px',
        }}>
        <Typography variant="h8" component="h4">{act.name}</Typography>
        {(location.hostname === 'localhost') && (<Typography variant="h8" component="h6">{componentDocId}</Typography>)}
        <Typography variant="h8" component="h6">{act.path}</Typography>
        <Typography variant="h8" component="h6">{act.state}</Typography>
        
        </Box>)}
        {act && (<Grid item>
      <Box>
        {!act.aiResult && <Button
          variant="outlined"
          startIcon={act.state === 'aiScanRunning' ? <CircularProgress size={24} /> : <InsightsIcon />}
          disabled={act.state === 'aiScanRunning'}
          onClick={() => {
            setAiScanTriggerForAct(true);
          }}
        >
          Analyze with AI
        </Button>}
        {act.aiResult && <ActivityInOut aiResult={act.aiResult} onVmTypeNameSelected={onComponentNameSelected} />}
        {(act.fileCheck.relatedLayoutFiles) && act.fileCheck.relatedLayoutFiles.map((layoutFile) =>
          <Box key={layoutFile.id} sx={{marginTop: '20px'}}>
            <Typography variant="h8" component="h4">Layout: {layoutFile.name}</Typography>
            {/* {layoutFile.bindsViewModel && (<Button variant="outlined">AI check layout</Button>)} */}
            {layoutFile.bindsViewModel && <AiActivityDocLayout layoutDocId={layoutFile.id} onVmTypeNameSelected={onComponentNameSelected} />}
            {layoutFile.aiResult && !layoutFile.bindsViewModel && <Typography variant="h8" component="h6">Not binding ViewModel</Typography>}
            </Box>
        )}
      </Box>
    </Grid>)}
        </Box>
  );
};

export default AiActivityDocOfRepoSnap;