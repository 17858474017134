import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams, createSearchParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import LogOverviewSingleTry from './LogOverviewSingleTry';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { distinctUntilChanged, tap, map } from 'rxjs/operators';
import { merge } from 'rxjs';
import MockingDialog from './MockingDialog';
dayjs.extend(relativeTime);


const SessionSelector = () => {

  const { accountId, appId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionSelected = searchParams.get('session');

  const [installsAvail, setInstallsAvail] = useState([]);
  const [installSelected, setInstallSelected] = useState();
  const [sessionAvail, setSessionAvail] = useState();
  const [openMockEditing, setOpenMockEditing] = useState(false);

  
  const setSessionSelected = (session) => {
    const newParams = createSearchParams(searchParams);
    if (session == null)
      newParams.delete('session');
    else {
      newParams.set('session', session.session);
      newParams.delete('logDocId');
    }
    setSearchParams(newParams, {replace: true});
  };

  useEffect(() => {
    const subscription = Data.getInstance().streamDevices(`Accounts/${accountId}/Apps/${appId}`)
      .subscribe(
        (devicesSessionsMap) => {
          setInstallsAvail(devicesSessionsMap);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId]);

  useEffect(() => {
    if (!installsAvail || !Object.keys(installsAvail).length || !installSelected || !installsAvail[installSelected.installId] || !installsAvail[installSelected.installId].sessions) {
      setSessionAvail();
      // setSessionSelected(); We cant reset this in case we visit a session later (when no active installs/sessions might exist)
      setInstallSelected();
      return () => {};
    };
    console.log("installSelected " + JSON.stringify(installSelected) + " ###installsAvail: " + JSON.stringify(installsAvail));
    const sessions = installsAvail[installSelected.installId].sessions;
    setSessionAvail((Object.keys(sessions).length > 1) ? sessions : null);
    setSessionSelected((Object.keys(sessions).length > 0) ? Object.values(sessions)[0] : null); //always select latest sesh
    //TODO: Fix that newest session gets selected even when other devices appear (user might be browsing old session)

    /* if (Object.keys(sessions).length > 1 || sessionAvail)
      setSessionAvail(sessions);
    if (Object.keys(sessions).length == 1) {
      setSessionAvail();
      setSessionSelected(Object.keys(sessions)[0]);
    } else if (!sessions[sessionSelected])
      setSessionSelected(); */
  }, [installsAvail, installSelected]);

  return (
    <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '26px',
      overflowY: 'hidden',
      // overflowX: 'auto',
      
    }}>
      {/* <Box sx={{ display: 'flex',
      overflowX: 'auto',
      flexDirection: 'column' 
      }}> */}
        <Typography  variant="overline">
        <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto',
          '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {backgroundColor: '#00000000',},'::-webkit-scrollbar': {height: '6px',},'&:hover': {'::-webkit-scrollbar-track': {background: "#e8e8e8",},'::-webkit-scrollbar-thumb': {backgroundColor: '#ddd',borderRadius: 8,}},'::-webkit-scrollbar-thumb:hover': {background: '#bbb'}
        }}><Box sx={{minWidth: '120px'}}>Installs seen:</Box>
          {
            !(Object.keys(installsAvail).length) ? " No Devices seen lately. Launch test app." : Object.values(installsAvail).map((install) => {
              const isSelected = (typeof installSelected !== 'undefined' && (install.installId === installSelected.installId));
              return (<Button color={isSelected ? 'secondary' : 'primary'} variant='outlined' sx={{marginLeft: '4px', whiteSpace: 'nowrap', minWidth: 'auto'}} key={install.installId} onClick={() => setInstallSelected(install)}>{install.device}</Button>
            )}) // sx={{...(typeof deviceSelected !== 'undefined' && (deviceSessions[0] === deviceSelected[0]) && {color: 'red'}), marginLeft: '4px'}}
          }
        </Box>
        {sessionAvail && Object.keys(sessionAvail).length && (<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px', whiteSpace: 'nowrap', overflowX: 'auto',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {backgroundColor: '#00000000',},'::-webkit-scrollbar': {height: '6px',},'&:hover': {'::-webkit-scrollbar-track': {background: "#e8e8e8",},'::-webkit-scrollbar-thumb': {backgroundColor: '#ddd',borderRadius: 8,}},'::-webkit-scrollbar-thumb:hover': {background: '#bbb'}
        }}><Box sx={{minWidth: '120px'}}>Select session:</Box>
          {
            Object.values(sessionAvail).map((session) => {
              const isSelected = typeof sessionSelected !== 'undefined' && (session.session === sessionSelected);
            return <Button color={isSelected ? 'secondary' : 'primary'} variant='outlined' sx={{marginLeft: '4px', minWidth: 'auto'}} key={session.session} onClick={() => setSessionSelected(session)}>{dayjs(new Date(session.lastSeenAt.toMillis())).fromNow()}</Button> //sx={{...( isSelected && {color: 'red'})}}
            })
          }
        </Box>)}
        {((location.hostname === 'localhost') || appId === 'UbNoUbhfNDXZJyCZ2KSi') && installSelected && (<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px', whiteSpace: 'nowrap', overflowX: 'auto',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {backgroundColor: '#00000000',},'::-webkit-scrollbar': {height: '6px',},'&:hover': {'::-webkit-scrollbar-track': {background: "#e8e8e8",},'::-webkit-scrollbar-thumb': {backgroundColor: '#ddd',borderRadius: 8,}},'::-webkit-scrollbar-thumb:hover': {background: '#bbb'}
        }}><Box sx={{minWidth: '120px'}}>Test values:</Box>
          {
            (installsAvail && installSelected && installsAvail[installSelected.installId] && typeof sessionSelected !== 'undefined' && installsAvail[installSelected.installId].sessions[sessionSelected]) && installsAvail[installSelected.installId].sessions[sessionSelected].mocksReceived && (
              <Typography variant="caption"><Box display="flex">
                Received in this session:&nbsp;{installsAvail[installSelected.installId].sessions[sessionSelected].mocksReceived.map((mock) => (
                <Box display="flex">
                  <Typography variant="caption" sx={{background: '#aaa', paddingLeft: '6px', paddingRight: '6px', borderRadius:'2px'}}>{mock.key}&nbsp;&nbsp;&nbsp;<Typography variant="caption" sx={{background: '#ddd', paddingLeft: '4px', paddingRight: '4px', borderRadius:'2px'}}>{mock.value}</Typography></Typography></Box>
              ))}
              </Box></Typography>
              )
          }
          {
            (<Button sx={{marginLeft:'10px'}} onClick={() => setOpenMockEditing(true)}>Set test values for {installSelected.device}</Button>)
          }
        </Box>)}
        </Typography>
    {/* </Box> */}
    </Box>
    {installsAvail && installSelected && installsAvail[installSelected.installId] && (<MockingDialog deviceName={installsAvail[installSelected.installId].device} installId={installSelected.installId} isOpen={openMockEditing} onClose={() => setOpenMockEditing(false)} />)}
    </>
  );
};

export default SessionSelector;
