import React, {useState, useEffect, createContext} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import UserBar from './UserBar';
import {Box, LinearProgress} from '@mui/material';
import Data from './Data';
// import ConfirmEmail from "./ConfirmEmail";

export const AppContext = createContext();

const AppFrame = ({user}) => {
  const {accountId, appId} = useParams();
  const [appSelected, setAppSelected] = useState();

  useEffect(() => {
    if (accountId === 'OSjnyurha9B0VDIOufy3' && appId === 'H87PoZ81sOpJ7Ml95pSy') {
      setAppSelected({name:'NowInAndroid', platform: 'android'});
      return;
    }
    const subscription = Data.getInstance().streamApp(`Accounts/${accountId}/Apps/${appId}`)
        .subscribe(
            (appLoaded) => {
              setAppSelected(appLoaded);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId]);

  return (
    <div>
      <UserBar user={user} currentApp={appSelected} onNavigateBack={() => setAppSelected(null)} />
      <Box sx={{
        // padding: 50,
        // paddingTop: 20,
        // paddingBottom: 20,
        height: 'calc(100vh - 64px)',
        background: '#fafafa',
      }}>
        {!appSelected &&  <LinearProgress color="secondary" />}
        {appSelected && <AppContext.Provider value={{app: appSelected}}>
          <Outlet />
        </AppContext.Provider>}
      </Box>
    </div>
  );
};

export default AppFrame;