import React from 'react';
import { Box, Typography, Card, CardContent, Chip } from '@mui/material';
import { ArrowForward as ArrowForwardIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const ActivityLayout = ({ json, vmVars, onVmTypeNameSelected }) => {
  const renderComponent = (component, index, level, showVmPerLine) => {
    const { name, attributes, children } = component;

    const indent = level == 0 ? 0 : 0;
    const contentPadding = 20;
    const width = 600 - (indent+contentPadding+6)*level;
    const endOfRow = 600 - (indent+contentPadding)*(level+1);
    const attributeRows = attributes.map((attribute) => (
      <Box
        key={attribute.name}
        sx={{
          // width: endOfRow+300+'px',
          display: 'flex',
          // backgroundColor: 'red',
        }}
      >
      <Box
        sx={{
          // width: endOfRow+600+'px',
          display: 'flex',
          alignItems: 'center',
          // backgroundColor: 'red',
        }}
      >
        <Box
        key={attribute.name}
        sx={{
          width: endOfRow+'px',
          display: 'flex',
          alignItems: 'center',
          // backgroundColor: 'blue',
        }}
      >
        
        <Typography variant="h8" component="h6" sx={{ paddingRight: '4px' }}>
          {attribute.name}
        </Typography>
        <Typography variant="h8" component="h6">{attribute.value}</Typography>
        </Box>
        <Box
        sx={{
          width: 300+'px',
          display: 'flex',
          alignItems: 'center',
          // backgroundColor: 'red',
        }}
      >
        {attribute.varRef && (attribute.varRef.isFunction ? (
          <ArrowForwardIcon />
        ) : (
          <ArrowBackIcon />
        ))}
        {attribute.varRef && attribute.varRef.vmVarAccess}
      </Box>
      </Box>
      {/* TODO onClick to vm type via onVmTypeNameSelected*/}
      {showVmPerLine && attribute.varRef && attribute.varRef.vmVarRef && <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '40px'
            }}>
              <Card sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <CardContent>
                  <Typography variant="subtitle2">{attribute.varRef.vmVarRef}</Typography>
                </CardContent>
              </Card>
            </Box>}
      
      </Box>
    ));

    const childComponents = children.map((child, childIndex) => renderComponent(child, index+'-'+childIndex, level+1, showVmPerLine));
    let borderColor = 'secondary.main'
    if (["LinearLayout", "FrameLayout", "RelativeLayout", "CoordinatorLayout", "ConstraintLayout"].includes(name)) {
      borderColor = '#ccc'
    }
    return (
      <Box
        key={index}
        sx={{
          width: width+'px',
          border: '1px solid',
          borderColor: borderColor,
          borderRadius: '4px',
          padding: contentPadding+'px',
          // marginLeft: indent+'px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
          }}
        >
          {name && (
            <Typography variant="body2" sx={{ paddingRight: '4px' }}>
              {name}
            </Typography>
          )}
          {attributeRows}
        </Box>
        {childComponents}
      </Box>
    );
  };

  const components = json.map((component, index) => renderComponent(component, index, 0, vmVars && vmVars.length > 1));
  let singleVmType = "";
  if (vmVars && vmVars.length == 1) {
    const typeFull = vmVars[0].type;
    const fullSplit = typeFull.split(".");
    singleVmType = fullSplit[fullSplit.length-1];
  }
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '20px',
      }}
    >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {components}
    </Box>
    {(vmVars && vmVars.length == 1) && <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '340px'
            }}>
              <Card sx={{
                display: 'flex',
                width: '240px',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => onVmTypeNameSelected(singleVmType)}>
                <CardContent>
                  <Typography variant="subtitle2">{singleVmType}</Typography>
                </CardContent>
              </Card>
            </Box>}
    </Box>
  );
};

export default ActivityLayout;