import {Observable, of, forkJoin, combineLatest, from, throwError} from 'rxjs';
import {
  switchMap,
  map,
  distinctUntilChanged,
  tap,
  take,
  catchError,
  shareReplay,
  sampleTime,
  flatMap,
  first,
  concatAll,
} from 'rxjs/operators';
import {getAuth} from 'firebase/auth';
import {getFirestore, connectFirestoreEmulator, doc, collection, addDoc, onSnapshot, updateDoc, deleteDoc, query, where, documentId, orderBy, limit, startAt, serverTimestamp, arrayUnion, getDoc, getDocs} from 'firebase/firestore';
import {getStorage, ref} from 'firebase/storage';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getApp } from "firebase/app";
import FirebaseManager from './FirebaseManager';
import {collectionData, docData, collectionChanges, doc as fetchDoc} from 'rxfire/firestore';
import {authState} from 'rxfire/auth';
import {getDownloadURL} from 'rxfire/storage';
import update from 'immutability-helper';

const Data = (function() {
  function SingletonClass() {
    this.streamLoginState = () => {
      return Observable.create(function(observer) {
        getAuth().onIdTokenChanged(function(user) {
          console.log('onIdTokenChanged ' + JSON.stringify(user));
          observer.next(user);
        });
      })
          .pipe(
              switchMap((user) => {
                /* if (user && !user.emailVerified)
                            return ctx.sendVerificationEmail().pipe(map(x => user), catchError(err => of(user)));
                        else */
                return of(user);
              }),
              switchMap((user) => {
                if (user) {
                  return docData(doc(db, 'Users', user.uid), {idField: 'id'})
                      .pipe(
                          map((userData) => {
                            if (userData) {
                              userData.email = user.email; userData.emailVerified = user.emailVerified; userData.name = user.displayName; userData.photoURL = user.photoURL;
                              return userData;
                            } else {
                              user.id = user.uid;
                              user.name = user.displayName;
                              return user;
                            }
                          }),
                          switchMap((userData) => {
                            console.log('new data from user ' + userData.id);
                            let appStreams;
                            if (userData && userData.access) {
                              appStreams = Object.keys(userData.access).map((appPath) => {
                                console.log('Object.keys ' + appPath);
                                return docData(doc(db, appPath), {idField: 'id'})
                                    .pipe(
                                        catchError(() => of({error: true})),
                                        tap((app) => console.log("got app " + appPath + JSON.stringify(app))),
                                        map((app) => {
                                          app.path = appPath; return app;
                                        }),
                                    );
                              });
                            }
                            console.log("streams " + (appStreams ? appStreams.length : 0));
                            if (appStreams && appStreams.length) {
                              return combineLatest(appStreams).pipe(
                                tap((apps) => console.log("got2 app " + JSON.stringify(apps))),
                                  map((apps) => {
                                    userData.apps = apps.filter(app => !app.error); return userData;
                                  }),
                                  catchError(() => of(userData)),
                              );
                            } else return of(userData);
                          }),
                      );
                } else return of(null);
              }),
              distinctUntilChanged(),
          );
    };

    this.sendVerificationEmail = () => {
      return authState(getAuth())
          .pipe(
              take(1),
              switchMap((user) => {
                if (user && !user.emailVerified) {
                  return Observable.create(function(observer) {
                    user.sendEmailVerification().then(function() {
                      console.log('Sent verification email');
                      observer.next('');
                      observer.complete();
                    }).catch(function(error) {
                      console.error('Error sending verification email: ', error);
                      observer.error(error);
                    });
                  });
                } else return of(null);
              }));
    };

    this.refreshUser = () => {
      const user = getAuth().currentUser;
      console.log('refreshUser ' + JSON.stringify(user));
      if (user) {
        user.reload();
        user.getIdToken(true);
      } else {
        // No user is signed in.
      }
    };

    this.logout = () => {
      getAuth().signOut();
    };

    this.copyHelp = () => {
      onSnapshot(doc(db, '/Accounts/iUcNANMezArLXXtK4PHx/Apps/UbNoUbhfNDXZJyCZ2KSi/DocParts/PTYQgTf3JKuGYlfmxQHQ'),
          function(doc) {
            if (doc.exists) {
              console.log('Found: ', doc.data());

              addDoc(collection(db, '/Accounts/iUcNANMezArLXXtK4PHx/Apps/UbNoUbhfNDXZJyCZ2KSi/DocParts/PTYQgTf3JKuGYlfmxQHQ/Revisions'), doc.data())
                  .then(function(docRef) {
                    console.log('Document written with ID: ', docRef.id);
                  })
                  .catch(function(error) {
                    console.error('Error writing document: ', error);
                  });
            }
          },
      );
    };

    this.createAppForUser = (appName) => {
      console.log('createAppForUser ' + appName);
      const callable = httpsCallable(functions, 'createaccountapp');
      return callable({appName: appName})
      .then((result) => {console.log("createResponse: " + result.data);return result.data})
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.error(`createResponseERROR code ${code} message ${message} details ${details}`)
      })
    };

    this.createAppForUserHttps = (user, appName) => {
      console.log('createAppForUserHttps ' + user.id);
      return from(addDoc(
        collection(db, 'Accounts'),
        {
          'createdByUser': user.id,
          'createdAt': serverTimestamp()
        }
      )
      .catch(function(error) {
        console.error('Error writing Account: ', error);
      })
      .then(function(docRef) {
        console.log('createdAppForUser Account with ID: ', docRef.id);
        return addDoc(
          collection(db, `Accounts/${docRef.id}/Apps`),
          {
            'createdByUser': user.id,
            'createdAt': serverTimestamp(),
            'name': appName,
            'platform': 'android',
          }
        )
        .catch(function(error) {
          console.error('Error writing Account: ', error);
        })
      }))
    }

    this.addUserToApp = (appPath, userId, role) => {
      console.log('addUserToApp ' + appPath + ' ' + userId);
      return updateDoc(doc(db, appPath + '/Restricted', 'ACL'), {
        [`roles.${userId}`]: role,
      })
      .catch((e) => console.error('addUserToApp err', e));
    };

    this.fetchApiKey = (appPath) => {
      console.log('fetchApiKey ' + appPath);
      return fetchDoc(doc(db, appPath + '/Restricted', 'Keys'))
      .pipe(
        catchError(e => console.error('fetchApiKey err', e)),
        map(snapshot => snapshot.data().apiKey),
        tap(key => console.log("Got key : " + key))
      );
    };

    this.streamApp = (appPath) => {
      console.log('streamApp ' + appPath);
      return fetchDoc(doc(db, appPath))
          .pipe(
              tap((snapshot) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              map((snapshot) => Object.assign({}, snapshot.data(), {'id': snapshot.id, 'path': appPath})),
              switchMap((app) =>
                collectionData(query(collection(db, appPath + '/Versions'), orderBy('code', 'desc'), limit(20))).pipe(
                    map((versions) => update(app, {$merge: {versions: versions}})),
                    tap((res) => console.log('streamedApp ' + JSON.stringify(res))),
                ),
              ),
          );
    };

    this.streamDevices = (appPath) => {
      console.log('streamDevices ' + appPath);
      return collectionData(query(collection(db, appPath + '/ActiveDevices'), where('lastSeenAt', '>', new Date((new Date()).getTime() - 18000000)), orderBy('lastSeenAt', 'desc'), limit(50)), 'docId')
        .pipe(
          map((arr) => {
            console.log("streamedDeviceLogs " + arr.length);
            var mapp = arr.reduce((map, item) => {
              var installRef = item.install || item.device; //there are old test versions w/o installId.
              var deviceSessionsMap = map[installRef] || { device: item.device, installId: installRef, sessions: {} };
              if (!deviceSessionsMap.sessions[item.session])
                deviceSessionsMap.sessions[item.session] = item;
              map[installRef] = deviceSessionsMap;
              return map;
            }, {});
            console.log("MAPP: " + JSON.stringify(mapp));
            return mapp;
          }),
          // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
          // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
          tap((res) => console.log('streamedDevices ' + JSON.stringify(res))),
        );
    };

    this.reportMonitorDeviceSelect = (appPath, deviceStr) => {
      console.log('reportMonitorDeviceSelect ' + deviceStr);
      return from(addDoc(
        collection(db, appPath + '/MonitorDevices'),
        {
          'name': deviceStr,
          'selectedAt': serverTimestamp()
        }
      )
      .then(function(docRef) {
        console.log('reported MonitorDeviceSelect with ID: ', docRef.id);
      })
      .catch(function(error) {
        console.error('Error writing MonitorDeviceSelect: ', error);
      }));
    }
    
    this.deleteHelper = (appPath, session) => {
      const ref = query(collection(db, appPath + '/Logs'),where('session', '==', 'isanodh78zaodas89'), orderBy('createdAt', 'desc')); //, where('appVersionCode', '==', 173)

      onSnapshot(ref, (snap) => {
        console.log("docIddn ", snap.size)
        deleteDoc(snap.docs[0].ref)
        snap.forEach((doc) => 
        console.log("docIdd:" + doc.id)
        )
      })

      /* return collectionData(ref, { idField: 'docId' })
      .pipe(
        tap(x => console.log("thisdocId ", x.length))
      ) */
    }

    this.streamIfAnyLogsExist = (appPath) => {
      console.log(`streamIfAnyLogsExist path ${appPath}`);
      return collectionData(query(collection(db, appPath + '/Logs'), limit(1), orderBy('session', 'asc')))
          .pipe(
            map(list => list.length > 0),
            tap((hasEntries) => console.log('streamIfAnyLogsExist hasEntries: ' + hasEntries)),
            distinctUntilChanged(),
          );
    };

    this.streamLogChangesFromDocId = (appPath, docId) => {
      //TODO: optimize with expand rx-operator and fs-startAt (recursively) to only fetch future added items from fs.Always only take first result from fs(or just fetch ones) (fast enough? or slows down)
      console.log(`streamLogChangesFromDocId path ${appPath} docId ${docId} `);

      const obs = fetchDoc(doc(db, appPath + '/Logs/'+docId))
      .pipe(first(), flatMap(snap =>
        Observable.create(observer =>  {
          const session = snap.data().session;
          const queryConstraints = []
          queryConstraints.push(where('session', '==', session))
          queryConstraints.push((snap.data().v >= 3 && snap.data().sdkV >= 4) ? orderBy('sessionLogIndex', 'desc') : orderBy('createdAt', 'desc'))
          queryConstraints.push(startAt(snap), limit(500))
          const ref = query(collection(db, appPath + '/Logs'), ...queryConstraints); //, where('createdAt', '>', new Date((new Date()).getTime() - 300000))
          const unsubscribe = onSnapshot(ref, (snapshot) => {
            console.log("docChanges n: ", snapshot.docChanges().length);
            snapshot.docChanges().forEach((change) => {
              console.log("docChanges: ", change.type, change.doc.id);
              if (change.type === "added") {
                const doc = change.doc.data();
                doc.docId = change.doc.id;
                  console.log("New doc added: ", doc);
                  observer.next(doc);
              }
            });
          })
        return {unsubscribe};
      })
      ))
      return obs
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              tap((res) => console.log('streamedLogChangess n: ' + JSON.stringify(Object.keys(res)))),
          );
    };

    this.streamLogChanges = (appPath, session) => {
      //TODO: optimize with expand rx-operator and fs-startAt (recursively) to only fetch future added items from fs.Always only take first result from fs(or just fetch ones) (fast enough? or slows down)
      console.log(`streamLogChangess path ${appPath} sesh ${session} `);
      const refOneOnly = query(collection(db, appPath + '/Logs'), where('session', '==', session), limit(1));
      const obs = collectionData(refOneOnly)
      .pipe(first(), flatMap(oneSeshList => {
        const queryConstraints = []
        queryConstraints.push(where('session', '==', session))
        queryConstraints.push((oneSeshList.length > 0 && oneSeshList[0].v >= 3 && oneSeshList[0].sdkV >= 4) ? orderBy('sessionLogIndex', 'desc') : orderBy('createdAt', 'desc'))
        queryConstraints.push(limit(500))
        const ref = query(collection(db, appPath + '/Logs'), ...queryConstraints); //, where('createdAt', '>', new Date((new Date()).getTime() - 300000))
        return Observable.create(observer =>  {
        const unsubscribe = onSnapshot(ref, (snapshot) => {
          console.log(`docChanges n: ${snapshot.docChanges().length} docsN: ${snapshot.size}`);
          snapshot.docChanges().forEach((change) => {
            console.log("docChanges: ", change.type, change.doc.id);
            if (change.type === "added") {
              const doc = change.doc.data();
              doc.docId = change.doc.id;
              console.log("New doc added: ", doc);
              observer.next(doc);
            }
          });
        });

        return {unsubscribe};
      })}
      ))
      return obs
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              // tap((res) => console.log('streamedLogChangess n: ' + JSON.stringify(Object.keys(res)))),
          );
    };

    this.streamLogs = (appPath, session) => {
      console.log(`streamLogs path ${appPath} sesh ${session} `);
      return collectionData(query(collection(db, appPath + '/Logs'), where('session', '==', session), where('createdAt', '>', new Date((new Date()).getTime() - 300000)), orderBy('createdAt', 'desc'), limit(1500)), { idField: 'docId' })
          .pipe(
              sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              tap((res) => console.log('streamedLogs n: ' + JSON.stringify(res.length))),
          );
    };

    this.streamLogsForId = (appPath, device, session, id) => {
      console.log(`streamLogsFor path ${appPath} device ${device} sesh ${session} Id ${id} `);
      return collectionData(query(collection(db, appPath + '/Logs'), where('device', '==', device), where('session', '==', session), where('id', '==', id), orderBy('createdAt', 'desc'), limit(50)), { idField: 'docId' }) //, where('createdAt', '>', new Date((new Date()).getTime() - 1800000))
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              tap((res) => console.log('streamedLogsForId ' + id + ': ' + JSON.stringify(res))),
          );
    };

    this.streamLogsForAction = (appPath, device, session, action) => {
      console.log(`streamLogsForAction path ${appPath} device ${device} sesh ${session} action ${action} `);
      return collectionData(query(collection(db, appPath + '/Logs'), where('device', '==', device), where('session', '==', session), where('action', '==', action), orderBy('createdAt', 'desc'), limit(50)), { idField: 'docId' }) //, where('createdAt', '>', new Date((new Date()).getTime() - 1800000))
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              tap((res) => console.log('streamedLogsForAction ' + action + ': ' + JSON.stringify(res))),
          );
    };

    this.streamLogsScreenInstances = (appPath, session, targetType) => {
      console.log(`streamLogsScreenInstances path ${appPath} sesh ${session} targetType ${targetType}`);
      return collectionData(query(collection(db, appPath + '/Logs'), where('session', '==', session), where('target', '==', targetType), orderBy('createdAt', 'desc'), limit(50)), { idField: 'docId' }) //, where('createdAt', '>', new Date((new Date()).getTime() - 1800000))
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              // tap((res) => console.log('streamedLogsScreenInstances ' + targetType + ': ' + JSON.stringify(res))),
          );
    };

    this.streamLogsForTarget = (appPath, session, targetType, targetInstance) => {
      console.log(`streamLogsForTarget path ${appPath} sesh ${session} targetType ${targetType} targetInstance ${targetInstance}`);
      return collectionData(query(collection(db, appPath + '/Logs'), where('session', '==', session), where('target', '==', targetType), where('targetInst', '==', targetInstance), orderBy('createdAt', 'desc'), limit(50)), { idField: 'docId' }) //, where('createdAt', '>', new Date((new Date()).getTime() - 1800000))
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              tap((res) => console.log('streamedLogsForTarget ' + targetType + ': ' + JSON.stringify(res))),
          );
    };

    this.streamLogsForCaller = (appPath, session, callerType, callerInstance) => {
      console.log(`streamLogsForCaller path ${appPath} sesh ${session} callerType ${callerType} callerInstance ${callerInstance}`);
      return collectionData(query(collection(db, appPath + '/Logs'), where('session', '==', session), where('caller', '==', callerType), where('callerInst', '==', callerInstance), orderBy('createdAt', 'desc'), limit(50)), { idField: 'docId' }) //, where('createdAt', '>', new Date((new Date()).getTime() - 1800000))
          .pipe(
              // sampleTime(100),
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
              tap((res) => console.log('streamedLogsForCaller ' + callerType + ': ' + JSON.stringify(res))),
          );
    };

    this.streamAggLogData = (appPath, appVersion, eventId) => {
      console.log('streamLoggedData ' + appPath);
      return collectionData(query(collection(db, appPath + '/LogAggregation'), where('id', '==', eventId), where('appv', '==', appVersion), orderBy('createdAt', 'desc'), limit(1)), { idField: 'docId' })
      .pipe(
          // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
          // map((snapshot) => Object.assign({}, snapshot.data(), {'docId': snapshot.id, 'path': appPath})),
          tap((res) => console.log('streamedLoggedData ')),
      );
    }

    this.streamAggLogData172 = (appPath, appVersion, target, action) => {
      console.log('streamLoggedData ' + appPath);
      return collectionData(query(collection(db, appPath + '/LogAggregation'), where('target', '==', target), where('action', '==', action), where('appv', '==', appVersion), orderBy('createdAt', 'desc'), limit(1)), { idField: 'docId' })
      .pipe(
          tap((res) => console.log('streamedLoggedData ')),
      );
    }

    this.streamAggLogDataGroup = (appPath, appVersion) => {
      console.log('streamAggLogDataGroup ' + appPath);
      return collectionData(query(collection(db, appPath + '/LogAggregation'), where('appv', '==', appVersion)), { idField: 'docId' })
      .pipe(
          tap((res) => console.log('streamedAggLogDataGroup ')),
      );
    }

    this.streamAggLogDataGroupEvent = (appPath, appVersion, group, event) => {
      console.log('streamAggLogDataGroupEvent ' + appPath);
      return collectionData(query(collection(db, appPath + '/LogAggregation'), where('group', '==', group), where('event', '==', event), where('appv', '==', appVersion), orderBy('createdAt', 'desc'), limit(1)), { idField: 'docId' })
      .pipe(
          tap((res) => console.log('streamedAggLogDataGroupEvent ')),
      );
    }

    this.streamAggLogDataByRefs = (appPath, refs) => {
      console.log('streamAggLogDataByRefs ' + appPath, refs);
      const refCuts = [];
      let offset = 0;
      while (true) {
        const end = offset + 10;
        refCuts.push(refs.slice(offset, end));
        if (end >= refs.length) {            
            break;
        }
        offset = end;
      }
      console.log('streamAggLogDataByRefBlocks ', refCuts);
      const obsArr = refCuts.map(cut => collectionData(query(collection(db, appPath + '/Logs'), where(documentId(), 'in', cut)), { idField: 'docId' }).pipe(first()));
      return forkJoin(obsArr)
      .pipe(
        map(res => [].concat(...res)),
        tap((docs) => console.log('streamedAggLogDataByRefs1 ', docs)),
      );
    }

    this.streamLatestRepoSnapshot = (appPath) => {
      console.log('streamLatestRepoSnapshot ' + appPath);

      return fetchDoc(doc(db, appPath))
          .pipe(
              map((snapshot) => Object.assign({}, snapshot.data(), {appDocId: snapshot.id, path: appPath})),
              switchMap((data) =>
                fetchDoc(doc(db, appPath + '/repoSnapshots/' + data.lastSnapshotDoc))
                .pipe(map(repoSnap => Object.assign({}, data, {repoSnapId: repoSnap.id, repoSnapshot: repoSnap.data()})))
              ),
          );
    }

    this.addFileToScan = (appPath, filePath) => {
      console.log('addFileToScan ' + appPath + ' ' + filePath);
      return from(addDoc(collection(db, appPath + '/files'), {
        fullPath: filePath,
        state: 'added',
      }).catch((e) => console.error('addFileToScan err', e)));
    };

    this.startActivityAiScan = (path, fileDocId) => {
      console.log('startActivityAiScan ' + path + ' ' + fileDocId);
      return from(updateDoc(doc(db, path + '/files', fileDocId), {
        aiResult: null,
        state: 'aiScanRunning',
      }).catch((e) => console.error('startActivityAiScan err', e)));
    };

    this.addTestToFile = (appPath, fileDocId, test) => {
      const uuid = self.crypto.randomUUID()
      test.uuid = uuid;
      test.createdAt = serverTimestamp()
      console.log('addTestToFile ' + appPath + ' ' + uuid + " " + JSON.stringify(test) );
      const filesCollection = collection(db, appPath + '/files')
      return from(updateDoc(doc(db, appPath + '/files', fileDocId), {
        [`tests.${uuid}`]: test,
      }).catch((e) => console.error('addTestToFile err', e)));
    };

    this.addVariationToTest = (appPath, fileDocId, testId, variation) => {
      const uuid = self.crypto.randomUUID()
      variation.uuid = uuid;
      variation.createdAt = serverTimestamp()
      console.log('addVariationToTest ' + appPath + ' ' + fileDocId + " " +testId + " " + uuid + " " +JSON.stringify(variation) );
      return from(updateDoc(doc(db, appPath + '/files', fileDocId), {
        [`tests.${testId}.variations.${uuid}`]: variation,
      }).catch((e) => console.error('addVariationToTest err', e)));
    };
/* 
    this.analyzeTest = (appPath, fileDocId, testId) => {
      console.log('analyzeTest ' + appPath + ' ' + fileDocId + " " +testId );
      return from(updateDoc(doc(db, appPath + '/files', fileDocId), {
        [`tests.${testId}.state`]: 'aiRunning',
      }).catch((e) => console.error('analyzeTest err', e)));
    }; */

    this.startAiForTest = (appPath, fileDocId, fileRepoPath, test, variation) => {
      console.log('startAiForTest ' + appPath + ' ' + fileDocId + " " +test.uuid + " " + (variation ? variation.uuid : "noVariation") );
      return from(updateDoc(doc(db, appPath), {
        aiRun: {
          fileDocId: fileDocId,
          fileRepoPath: fileRepoPath,
          test: test,
          variation: variation,
        },
      }).catch((e) => console.error('startAiForTest err', e)));
    };

    this.addAnalysisTargetToFile = (appPath, filePath, question) => {
      console.log('addAnalysisTargetToFile ' + appPath + ' ' + filePath + " " +question );
      const filesCollection = collection(db, appPath + '/files')
      return from(
        getDocs(query(filesCollection, where('fullPath', '==', filePath))).then((snap) => {
            if (!snap.empty) {
              const found = snap.docs[0]
              return updateDoc(doc(db, appPath + '/files', found.id), {
                analysisTargets: arrayUnion(question),
              }).catch((e) => console.error('updateAnalysisTargetToFile err', e))
            } else {
              return addDoc(filesCollection, {
                fullPath: filePath,
                state: 'added',
                analysisTargets: [question],
              }).catch((e) => console.error('addAnalysisTargetToFile err', e))
            }
          }
        )          
      );
    };

    this.addParentConnectToFile = (appPath, fileDocId, parentFilePath) => {
      console.log('addParentConnectToFile ' + appPath + ' ' + fileDocId + " " +parentFilePath );
      return from(updateDoc(doc(db, appPath + '/files', fileDocId), {
        parentPathConnected: parentFilePath,
      }).catch((e) => console.error('addParentConnectToFile err', e)));
    };

    this.addCompConnectToFile = (appPath, fileDocId, connectedFilePath, interaction) => {
      console.log('addCompConnectToFile ' + appPath + ' ' + fileDocId + " " +connectedFilePath );
      return from(updateDoc(doc(db, appPath + '/files', fileDocId), {
        compConnected: arrayUnion({filePath: connectedFilePath, interaction: interaction}),
        compConnectedList: arrayUnion(connectedFilePath)
      }).catch((e) => console.error('addCompConnectToFile err', e)));
    };

    this.updateConnectedComps = (appPath, fileDocId, compConnected) => {
      console.log('updateConnectedComps ' + appPath + ' ' + fileDocId + " " +JSON.stringify(compConnected) );
      return from(updateDoc(doc(db, appPath + '/files', fileDocId), {
        compConnected: compConnected
      }).catch((e) => console.error('updateConnectedComps err', e)));
    };

    this.streamAddedFiles = (appPath) => {
      console.log('streamAddedFiles ');

      return collectionData(query(collection(db, appPath + '/files'), orderBy('fullPath'), limit(100)), { idField: 'docId' })
          .pipe(
              tap((list) => console.log(list)),
          );
    }

    this.streamConnectedFiles = (appPath, filePaths) => {
      console.log('streamConnectedFiles ' + filePaths);
      return collectionData(query(collection(db, appPath + '/files'), where('compConnectedList', 'array-contains-any', filePaths), orderBy('fullPath'), limit(100)), { idField: 'docId' })
          .pipe(
              tap((list) => console.log(list)),
          );
    }

    this.streamActivities = (appPath) => {
      console.log('streamActivities ');

      return collectionData(query(collection(db, appPath + '/files'), where('addedAsActivity', '==', true), orderBy('fullPath'), limit(100)), { idField: 'docId' })
          .pipe(
              tap((list) => console.log(list)),
          );
    }

    this.streamViewModels = (appPath, repoSnapId) => {
      console.log('streamViewModels ' + repoSnapId);

      return collectionData(query(collection(db, appPath + '/repoSnapshots/' + repoSnapId + '/files'), where('fileCheck.isViewModel', '==', true), orderBy('name'), limit(100)), { idField: 'docId' })
          .pipe(
              tap((list) => console.log(list)),
          );
    }

    this.streamComponentsByName = (appPath, repoSnapId, nameQuery) => {
      console.log('streamComponentsByName ' + repoSnapId + " " + nameQuery);

      return collectionData(query(collection(db, appPath + '/repoSnapshots/' + repoSnapId + '/files'), where('name', '==', nameQuery), orderBy('path'), limit(100)), { idField: 'docId' })
          .pipe(
              tap((list) => console.log(list)),
              // map((doc) => Object.assign({}, doc.data(), {'id': doc.docId})),
          );
    }

    this.streamFileByPath = (appPath, fullPath) => {
      console.log('streamFileByName ' + fullPath);

      return collectionData(query(collection(db, appPath + '/files'), where('fullPath', '==', fullPath), limit(1)), { idField: 'docId' })
          .pipe(
              tap((list) => console.log(list)),
              map((list) => list.length ? list[0] : {})
              // map((doc) => Object.assign({}, doc.data(), {'id': doc.docId})),
          );
    }

    this.streamComponent = (appPath, repoSnapId, compId) => {
      const path = appPath + '/repoSnapshots/' + repoSnapId + '/files/' + compId;
      console.log('streamComponent ' + path);

      return fetchDoc(doc(db, path))
          .pipe(
              tap((comp) => console.log("Comp fetched:")),
              map((doc) => Object.assign({}, doc.data(), {docId: doc.id})),
              tap((comp) => console.log(comp))
          );
    }

    this.streamFile = (appPath, fileDocId) => {
      const path = appPath + '/files/' + fileDocId;
      console.log('streamComponent ' + path);

      return fetchDoc(doc(db, path))
          .pipe(
              tap((comp) => console.log("Comp fetched:")),
              map((doc) => Object.assign({}, doc.data(), {docId: doc.id})),
              tap((comp) => console.log(comp))
          );
    }

    this.addTestVariantToGroup = (appPath, groupId, variantId, variantLabel, data) => {
      console.log('addTestVariantToGroup ' + groupId + ' ' + variantId);
      return addDoc(
        collection(db, appPath + '/Models/' + groupId + '/models'),
        {
          'id': variantId,
          'label': variantLabel,
          'sortOrder': 99,
          'createdAt': serverTimestamp(),
          'variant': data,
        }
      )
      .then(function(docRef) {
        console.log(`added TestVariant ${variantId} ToGroup ${groupId} with docID: ${docRef.id} to appPath ${appPath}`);
      })
      .catch(function(error) {
        console.error(`Error adding TestVariant ${variantId} ToGroup ${groupId} at appPath ${appPath}`, error);
      });
    }

    this.streamCategories = (appPath) => {
      console.log('streamCategories ' + appPath);
      return fetchDoc(doc(db, appPath + '/LogMappings/Categories'))
          .pipe(
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              map((snapshot) => Object.assign({}, snapshot.data(), {'id': snapshot.id, 'path': appPath})),
              // tap((snapshot) => console.log('streamedCategories ' + JSON.stringify(snapshot))),
          );
    };

    this.streamBrands = (appPath) => {
      console.log('streamBrands ' + appPath);
      return fetchDoc(doc(db, appPath + '/LogMappings/Brands'))
          .pipe(
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              map((snapshot) => Object.assign({}, snapshot.data(), {'id': snapshot.id, 'path': appPath})),
              // tap((snapshot) => console.log('streamedBrands ' + JSON.stringify(snapshot))),
          );
    };

    this.streamColors = (appPath) => {
      console.log('streamColors ' + appPath);
      return fetchDoc(doc(db, appPath + '/LogMappings/Colors'))
          .pipe(
              // tap((logs) => console.log('firerx ' + snapshot.id + '  path: ' + appPath + '   DATA: ' + JSON.stringify(snapshot.data()))),
              map((snapshot) => Object.assign({}, snapshot.data(), {'id': snapshot.id, 'path': appPath})),
              // tap((snapshot) => console.log('streamedColors ' + JSON.stringify(snapshot))),
          );
    };

    this.releaseVersion = (appPath, versionCode) => {
      console.log('releaseVersion ' + versionCode);
      return from(updateDoc(doc(db, appPath + '/Versions', String(versionCode)), {
        release: true,
      }).catch((e) => console.error('releaseVersion err', e)));
    };

    this.isTablet = (device) => {
      const widthDp = device.width / (device.dpi / 160);
      const heightDp = device.height / (device.dpi / 160);
      const smallerSideDp = Math.min(widthDp, heightDp);
      return smallerSideDp >= 600;
    }

    this.streamAppScreens = (appPath, appVersion) => {
      const ctx = this;
      const ref = collection(db, appPath + '/Versions/' + appVersion + '/Docs');
      return collectionData(ref, {idField: 'docId'})
          .pipe(
              switchMap((docs) =>
                forkJoin(docs.map((doc) => {
                  if (!doc.image && !doc.images) {
                    return of(doc);
                  } else {
                    let relevantImage = "";
                    if (doc.image) {
                      relevantImage = doc.image;
                    } else {
                      const imgSet = Object.values(doc.images).map(device => [ctx.isTablet(device), device.image]);
                      const phoneSet = imgSet.filter(set => !set[0])
                      relevantImage = (phoneSet.length > 0) ? phoneSet[0][1] : imgSet[0][1];
                    }
                    return ctx.resolveFileUrl(appPath + '/screens/' + relevantImage)
                        .pipe(map((url) =>
                          update(doc, {$merge: {imageUrl: url}}),
                        ));
                  }
                })),
              ),
              tap((x) => console.log('THISSS: ' + JSON.stringify(x))),
              map((arr) => arr.sort((a, b) => a.index - b.index)),
          )
      /* .pipe(
                    switchMap(screens =>
                        forkJoin(screens.map(screen => {
                            if (Array.isArray(screen.uiStates) && screen.uiStates.length) {
                                return ctx.resolveFileUrl('/apps/' + appId + '/' + appVersion + '/screens/' + screen.id + '/' + screen.uiStates[0].id + '.jpeg')
                                    .pipe(
                                        map(url => { screen.previewUrl = url; return screen; })
                                        // ,tap(scr => console.log("changed scr: " + JSON.stringify(scr)))
                                    );
                            } else {
                                return of(screen);
                            }
                        }))
                    )
                ) */
      ;
    };

    this.streamDocName = (appPath, appVersion, docId) => {
      const ref = doc(db, appPath + '/Versions/' + appVersion + '/Docs/', docId);
      return docData(ref, {idField: 'id'})
          .pipe(
              tap((x) => console.log('doc name: ' + JSON.stringify(x))),
              map((doc) => doc.name)
              , shareReplay(),
          );
    };

    this.streamLabels = (appPath) => {
      const ref = collection(db, appPath + '/Labels');
      return collectionData(ref, { idField: 'id' })
          .pipe(
              tap((x) => console.log('Labels before: ' + JSON.stringify(x))),
              map((labels) => labels.reduce((map, obj) => {
                map[obj.id] = obj.label; return map;
              }, {})),
              tap((x) => console.log('Labels map: ' + JSON.stringify(x)))
              , shareReplay(),
          );
    };

    this.streamUiStates = (appPath, appVersion, screenId) => {
      console.log(`QUERYING path: ${appPath} v: ${appVersion} scr: ${screenId}`);
      return this.streamDocPartsForFireRef(appPath, appVersion, query(collection(db, appPath + '/DocParts'), where('appVersion', '==', appVersion), where('docId', '==', screenId), where('type', '==', 'uiStates')));
    };

    this.streamDocParts = (appPath, appVersion, docId) => {
      return this.streamDocPartsForFireRef(appPath, appVersion, query(collection(db, appPath + '/DocParts'), where('appVersion', '==', appVersion), where('docId', '==', docId)));
    };

    this.streamDocPartsForRefId = (appPath, appVersion, refId) => {
      // return this.streamDocPartsForFireRef(appPath, appId, appVersion, db.collection(appPath + "/DocParts").where("appVersion", "==", appVersion).where("refs." + refId, "!=", false));
      return this.streamDocPartsForFireRef(appPath, appVersion, query(collection(db, appPath + '/DocParts'), where('appVersion', '==', appVersion), where('refList', 'array-contains', refId)));
    };

    this.streamDocPartsForFireRef = (appPath, appVersion, fireRef) => {
      const ctx = this;
      return collectionData(fireRef, { idField: 'id' })
          .pipe(
              tap((x) => console.log('DocParts: ' + JSON.stringify(x))),
              switchMap((parts) => {
                if (!parts.length) return throwError('Screen not found');
                return combineLatest(parts.map((part) => {
                  console.log('part ' + JSON.stringify(part));
                  if (part.type === 'uiStates' && Array.isArray(part.uiStates) && part.uiStates.length) {
                    if (part.images && Object.keys(part.images).length) {
                      return forkJoin(
                          Object.keys(part.images).map((deviceId) => {
                            const device = part.images[deviceId];
                            // console.log(`oneDevice ${deviceId}: ${JSON.stringify(device)}`);
                            return forkJoin(
                                Object.keys(device).filter((id) => !id.startsWith('av_int_')).map((stateId) =>
                                  ctx.resolveFileUrl(appPath + '/screens/' + device[stateId])
                                      .pipe(map((url) => {
                                        const state = part.uiStates.find((st) => st.id === stateId);
                                        return {
                                          id: state.id,
                                          desc: state.desc,
                                          meta: state.meta,
                                          imageUrl: url,
                                        };
                                      })),
                                ),
                            ).pipe(
                                map((stateInfos) =>
                                  ({
                                    id: deviceId,
                                    meta: device.av_int_meta ? device.av_int_meta.device : null,
                                    capturedAt: device.av_int_meta ? device.av_int_meta.modified : device.av_int_modified,
                                    images: stateInfos.sort(function(a, b) {
                                      const x = a.id.toLowerCase();
                                      const y = b.id.toLowerCase();
                                      if (x < y) {
                                        return -1;
                                      }
                                      if (x > y) {
                                        return 1;
                                      }
                                      return 0;
                                    }),
                                  }),
                                ),
                            );
                          }),
                      )
                          .pipe(
                              map((deviceInfos) =>
                                update(part, {$merge: {devicesImages: deviceInfos.reduce((map, infos) => {
                                  map[infos.id] = infos; return map;
                                }, {})}}),
                              ),
                          );
                    } else {
                      return of(part);
                    }
                  } else if (part.type === 'table') {
                    return of(part)
                        .pipe(
                            switchMap((part1) => ctx.streamLabels(appPath).pipe(map((labelMap) => [part1, labelMap]))),
                            map((partAndLabelMap) => {
                              const thePart = JSON.parse(JSON.stringify(partAndLabelMap[0]));
                              const labels = partAndLabelMap[1];
                              console.log('ThePart: ' + JSON.stringify(thePart));
                              if (thePart.rows && Array.isArray(thePart.rows) && thePart.rows.length) {
                                thePart.rows.forEach(function(row, index) {
                                  const newRow = {};
                                  Object.keys(row).forEach((colId) => {
                                    const contents = row[colId];
                                    if (contents && Array.isArray(contents) && contents.length) {
                                      contents.forEach(function(contentItem, index) {
                                        if (contentItem.type === 'ref') {
                                          const label = labels[contentItem.refId];
                                          contentItem.content = label && label.length ? label : contentItem.refId;
                                        }
                                        this[index] = contentItem;
                                      }, contents);
                                    }
                                    newRow[colId] = contents;
                                  }, newRow);
                                  this[index] = newRow;
                                }, thePart.rows);
                              }
                              return thePart;
                            }),
                        );
                  } else {
                    return of(part);
                  }
                }),
                );
              },
              ),
              tap((parts) => console.log('switching for docname ' + JSON.stringify(parts)))
              , map((parts) => parts[0]),
              /* ,switchMap(parts =>
                        ctx.streamDocName(appPath, appVersion, parts[0].docId).pipe(map(name => update(parts[0], {$merge: { docName: name }})))
                        // combineLatest(parts.map(part => ctx.streamDocName(appPath, appVersion, part.docId).pipe(map(name => update(part, {$merge: { docName: name }})))))
                    ) */
          );
    };

    this.resolveFileUrl = (path) => {
      const pathReference = ref(storage, path);
      // console.log("load screenshotUrl " + pathReference);
      return getDownloadURL(pathReference)
          .pipe(
              catchError((error) => {
                console.log('Error imgUrl load ' + error);
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/object-not-found':
                    console.log('File doesn\'t exist');
                    break;

                  case 'storage/unauthorized':
                    console.log('User doesn\'t have permission to access the object');
                    break;

                  case 'storage/canceled':
                    console.log('User canceled the upload');
                    break;

                  case 'storage/unknown':
                    console.log('Unknown error occurred, inspect the server response');
                    break;

                  default:
                    console.log('Unknown error');
                    break;
                }
                return of('');
              }),
              tap((url) => console.log('imgUrl loaded ' + url)),
          );
    };

    this.setAppRepo = (appPath, installId, repoOwner, repoName) => {
      console.log('appPath ' + appPath);
      console.log('installId ' + installId);
      // console.log('targetVariants ' + targetVariants);
      //const path = `Accounts/${accountId}/Apps/${appId}`
      return from(updateDoc(doc(db, appPath), {
        repo: {
          provider: 'github',
          installId: installId,
          state: "installed",
          owner: repoOwner,
          name: repoName,
          // targetVariants: targetVariants,
        },
      }).catch((e) => console.error('setAppRepo err', e)));
    };
  }

  let instance;
  let db;
  let storage;
  let functions;

  return {
    getInstance: function() {
      if (instance == null) {
        instance = new SingletonClass();
        FirebaseManager.getInstance().init();
        getAuth().useDeviceLanguage();
        db = getFirestore();
        storage = getStorage();
        functions = getFunctions(getApp(), 'europe-west1');
        // functions = firebase.app().functions('europe-west1');
        if (location.hostname === 'localhost') {
          // connectFunctionsEmulator(functions, "127.0.0.1", 5001);
          // connectFirestoreEmulator(db, '127.0.0.1', 8080);
          // OLD auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
        }

        instance.constructor = null;
      }
      return instance;
    },
  };
})();

export default Data;
