import React, { useState, useEffect, useContext } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import UserBar from './UserBar';
import { Box, Card, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import Data from './Data';
import { AppContext } from './AppFrame';
import { styled } from '@mui/material/styles';

const MenuCard = styled(Card)({
  display:'block', width: '500px', maxWidth: '100%', height:'300px', marginTop: '32px', textDecoration: 'none'
});

const AppMenu = ({ user }) => {
  const { accountId, appId } = useParams();
  const { app } = useContext(AppContext);
  /* 
    useEffect(() => {
      const subscription = Data.getInstance().streamApp(`Accounts/${accountId}/Apps/${appId}`)
          .subscribe(
              (appLoaded) => {
                setAppSelected(appLoaded);
              },
          );
  
      return () => {
        subscription.unsubscribe();
      };
    }, [accountId, appId]); */

  return (
      <Box sx={{
        height: '100%',
        // maxWidth: '100%',
        padding: 8,
        // paddingTop: 20,
        // paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '8px'},
      }}>
        <Typography variant="h3">{app.name}</Typography>
        <Grid container columnSpacing={4}>
          <Grid  item sx={{maxWidth: '100%'}}>
            <MenuCard component={Link} to={`monitor`}>
              <CardContent>
                <Typography variant="h5">Live Event Monitor</Typography>
                <Box sx={{ marginTop: '0px', height: '255px', maxHeight: '280px', width: '100%', border: 0, borderColor: '#ffc108', backgroundImage: "url(/teaser-monitor.png)", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></Box>
              </CardContent>
            </MenuCard>
          </Grid>
          <Grid item sx={{maxWidth: '100%'}}>
            <MenuCard component={Link} to={`ui-gallery`}>
              <CardContent>
                <Typography variant="h5">UI Gallery</Typography>
                <Box sx={{ marginTop: '0px', height: '255px', maxHeight: '280px', width: '100%', border: 0, borderColor: '#ffc108', backgroundImage: "url(/teaser-ui-docs.png)", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></Box>
              </CardContent>
            </MenuCard>
          </Grid>
          <Grid item sx={{maxWidth: '100%'}}>
            {(accountId === 'OSjnyurha9B0VDIOufy3' && appId === 'H87PoZ81sOpJ7Ml95pSy') ? (<MenuCard component={Link} to={`aidocs`}>
              <CardContent>
                <Typography variant="h5">AI-powered diagram</Typography>
                <Box sx={{ marginTop: '0px', height: '255px', maxHeight: '280px', width: '100%', border: 0, borderColor: '#ffc108', backgroundImage: "url(/teaser-ai-docs.png)", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></Box>
              </CardContent>
            </MenuCard>) :
            (<MenuCard>
              <CardContent>
                <Typography variant="h5">AI-powered documentation (Soon)</Typography>
                <Box sx={{ marginTop: '0px', height: '255px', maxHeight: '280px', width: '100%', border: 0, borderColor: '#ffc108', backgroundImage: "url(/teaser-ai-docs.png)", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></Box>
              </CardContent>
            </MenuCard>)}
          </Grid>
        </Grid>
      </Box>
  );
};

export default AppMenu;