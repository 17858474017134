
import {NativeSelect, FormControl} from '@mui/material';

const VersionSelector = ({userIsDev, currentVersion, allVersions, onVersionChange}) => {
  return (
    <FormControl sx={{width: 'max-content', maxWidth: '200px'}}>
      <NativeSelect
        defaultValue={currentVersion.code}
        name="version"
        onChange={(e) => onVersionChange(parseInt(e.target.value))}
        inputProps={{'aria-label': 'version'}}
      >
        {allVersions.filter((v) => userIsDev || v.release).map((v) => <option key={v.code} value={v.code}>{v.name} ({v.code}) {v.release ? '' : 'Draft'}</option>)}
      </NativeSelect>
    </FormControl>
  );
};
export default VersionSelector;
