import {Box, Tooltip} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const DocDateMonitor = ({timeStampDate}) => {
  const dateInMillis = timeStampDate.toMillis();
  const date = new Date(dateInMillis).toDateString() + ' at ' + new Date(dateInMillis).toLocaleTimeString();
  const preciseTime = new Date(dateInMillis).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3});
  const relTime = dayjs(new Date(dateInMillis)).fromNow();
  return (
    <Tooltip title={date}>
      <Box>
        <span style={{color: '#cccccc', fontSize: '12px'}}>🕙 {preciseTime}</span>
      </Box>
    </Tooltip>
  );
};
export default DocDateMonitor;
