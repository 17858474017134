import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useParams, useNavigate, Link } from 'react-router-dom';
import UserBar from './UserBar';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import GroupIcon from '@mui/icons-material/Group';
import DataObjectIcon from '@mui/icons-material/DataObject';
import InsightsIcon from '@mui/icons-material/Insights';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import Data from './Data';
import { AppContext } from './AppFrame';

const MonitorIntro = () => {

  return (
      <Box sx={{ height: '100%', textAlign: 'center', paddingTop: '40px', paddingBottom: '60px', paddingLeft: '10px', paddingRight: '10px', overflowY: 'auto',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '8px'}, }}>
        <Typography variant='h2' sx={{ textAlign: 'center' }}>Live Event Monitor</Typography>
        <Box sx={{ marginTop: '6px', marginBottom: '20px', textAlign: 'center' }}>Watch events and data flows while using test versions of your app to verify behavior and fix issues.<br/>Collecting this data will also set up an aggregated documentation. Reliable and always up-to-date.</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', padding: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '300px' }}><VerifiedUserOutlinedIcon sx={{ marginRight: '6px' }} /><Typography variant='subtitle2'>Easily test behavior</Typography></Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '300px' }}><GroupIcon sx={{ marginRight: '6px' }} /><Typography variant='subtitle2'>Access for the whole team</Typography></Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '300px' }}><DataObjectIcon sx={{ marginRight: '6px' }} /><Typography variant='subtitle2'>Data viewing with JSON support</Typography></Box>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '300px' }}><InsightsIcon sx={{ marginRight: '6px' }} /><Typography variant='subtitle2'>Event sourcing for aggregated docs</Typography></Box>
        </Box>
        <Typography variant='subtitle1' color={'secondary'}><a href="https://appviewer.io/event-monitor">Learn more</a></Typography>
        <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '20px' }}>Integrate our SDK in just 1 min.</Typography>
        <Button variant='contained' onClick={() => window.open("https://docs.appviewer.io", '_blank')}>Read Docs</Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ marginTop: '40px', height: '430px', maxHeight: '50vw', width: '90%', maxWidth: '760px', border: 8, borderColor: '#ffc108', backgroundImage: "url(/monitor-example.gif)", backgroundPosition: 'top', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
          </Box>
        </Box>
      </Box>
  );
};

export default MonitorIntro;