import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate, useParams} from 'react-router-dom';
import Login from './Login';
import AppsList from './AppsList';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import {LinearProgress} from '@mui/material';
import Window from './Window';
import AppOverview from './AppOverview';
import ScreenDetails from './ScreenDetails';
import LogOverviewRows from './LogOverviewRows';
import ComponentsOverview from './ComponentsOverview';
import ComponentDetails from './ComponentDetails';
import ComponentDetailsOld from './ComponentDetailsOld';
import ComponentsOverviewOld from './ComponentsOverviewOld';
import Repo from './Repo';
import GitHubRepoInstalled from './GitHubRepoInstalled';
import AiDocs from './AiDocs';
import AiActivityDoc from './AiActivityDoc';
import AiActivityDocOfRepoSnap from './AiActivityDocOfRepoSnap';
import AiCompSelect from './AiCompSelect';
import AppFrame from './AppFrame';
import CreateApp from './CreateApp';
import AppMenu from './AppMenu';
import UiGalleryMain from './UiGalleryMain';
import MonitorMain from './MonitorMain';
import AiDocsMain from './AiDocsMain';
import Mocking from './MockingDialog';
import AiArcDemo from './AiArcDemo';
// import ConfirmEmail from "./ConfirmEmail";

// FirebaseManager.getInstance().init();

const LoginState = {Init: 'Init', UnconfirmedEmail: 'UnconfirmedEmail', LoggedIn: 'LoggedIn', LoggedOut: 'LoggedOut'};

function AppRoot() {
  const [loginState, setLoginState] = useState(LoginState.Init);
  const [user, setUser] = useState();

  useEffect(() => {
    const subscription = Data.getInstance().streamLoginState()
        .subscribe(
        // (user) => this.setState({isSignedIn: !!user})
            (user) => {
              console.log('Logged in ' + (user ? JSON.stringify(user) : 'no user'));
              setUser(user);
              setLoginState(user ? (user.emailVerified === true ? LoginState.LoggedIn : LoginState.UnconfirmedEmail) : LoginState.LoggedOut);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if ((loginState === LoginState.LoggedIn || loginState === LoginState.UnconfirmedEmail) && user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Window user={user} />}>
            <Route path="signin" element={<Navigate to="/" replace />} />
            <Route index element={user.apps ? (user.apps.length == 1 ? <Navigate to={user.apps[0].path} replace /> : <Navigate to="/apps" replace />) : <Navigate to="/createApp" replace />} />
            <Route path="createApp" element={<CreateApp user={user} />} />
            <Route path="apps" element={<AppsList apps={user.apps} />} />
            <Route path="octoInstalled" element={<GitHubRepoInstalled />} />
            <Route
              path="*"
              element={
                <main style={{padding: '1rem'}}>
                  <p>There&apos;s nothing here!</p>
                </main>
              }
            />
          </Route>
          <Route path="accounts/:accountId/apps/:appId/" element={<AppFrame user={user} />}>
            <Route index element={<AppMenu user={user} />} />
            <Route path="ui-gallery" element={<UiGalleryMain user={user} />} />
            <Route path="ui-gallery/:appVersion" element={<AppOverview user={user} />} />
            <Route path="ui-gallery/:appVersion/:screenId" element={<ScreenDetails />} />
            <Route path=":appVersion/docs" element={<ComponentsOverviewWrapper />} />
            <Route path=":appVersion/docs/:componentId" element={<ComponentDetailsOld />} />
            <Route path=":appVersion/docs/:componentId/:action" element={<ComponentDetailsWrapper />} />
            <Route path="monitor" element={<MonitorMain />} />
            {/* <Route path="simulate" element={<Mocking />} /> */}
            {/* <Route path="aidocs/repo-connect" element={<Repo />} /> */}
            {/* <Route path="aidocs" element={<AiDocsMain />} /> */}
            <Route path="aidocs" element={<AiArcDemo />} />
            <Route path="aidocs/f/:fileDocId" element={<AiActivityDoc />} />
            <Route path="aidocs/s/:repoSnapshotId/c/:componentDocId" element={<AiActivityDocOfRepoSnap />} />
            <Route path="aidocs/s/:repoSnapshotId/q/:componentNameQuery" element={<AiCompSelect />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (loginState === LoginState.LoggedOut) {
return (<BrowserRouter>
        <Routes>
          <Route path="/" element={<Window user={user} />}>
            <Route index element={<Navigate to="/signin" replace />} />
            <Route path="signin" element={<Login />} />
            <Route
              path="*"
              element={
                <main style={{padding: '1rem'}}>
                  <p>There&apos;s nothing here!</p>
                </main>
              }
            />
          </Route>
          <Route path="accounts/:accountId/apps/:appId/" element={<AppFrame />}>
            <Route index element={<AppMenu />} />
            <Route path="ui-gallery" element={<UiGalleryMain />} />
            <Route path="monitor" element={<MonitorMain />} />
            <Route path="aidocs" element={<AiArcDemo />} />
          </Route>
        </Routes>
      </BrowserRouter>);

    // return <Login />;
  } else return <LinearProgress color="secondary" />;
};

export default AppRoot;

const ComponentsOverviewWrapper = () => {
  const { accountId, appId, appVersion } = useParams();
  return (accountId == 'iUcNANMezArLXXtK4PHx' && appId == 'UbNoUbhfNDXZJyCZ2KSi' && (parseInt(appVersion, 10) < 175)) ? <ComponentsOverviewOld /> : <ComponentsOverview />;
};

const ComponentDetailsWrapper = () => {
  const { accountId, appId, appVersion } = useParams();
  return (accountId == 'iUcNANMezArLXXtK4PHx' && appId == 'UbNoUbhfNDXZJyCZ2KSi' && (parseInt(appVersion, 10) < 175)) ? <ComponentDetailsOld /> : <ComponentDetails />;
};