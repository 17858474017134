import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import UserBar from './UserBar';
import {Box} from '@mui/material';
// import ConfirmEmail from "./ConfirmEmail";


const Window = ({user, appSelected}) => {
  // const [appSelected, setAppSelected] = useState();

  return (
    <div>
      <UserBar user={user} currentApp={appSelected} 
      // onNavigateBack={() => setAppSelected(null)} 
      />
      <Box sx={{
        // padding: 50,
        // paddingTop: 20,
        // paddingBottom: 20,
        height: 'calc(100vh - 64px)',
        background: '#fafafa',
      }}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Window;
