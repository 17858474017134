
import {styled} from '@mui/material/styles';
import styles from './css/ScreenView.module.css';
import {Card, Typography, CardContent} from '@mui/material';

const ScreenView = ({screen, onScreenClick}) => {
  const MyCard = styled(Card)({
    height: 440,
    width: 200,
    cursor: 'pointer',
  });

  return (
    <MyCard onClick={(e) => {
      e.stopPropagation();
      onScreenClick();
    }}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {screen.name}
        </Typography>
        <img className={styles.image}
          key={screen.imageUrl}
          src={screen.imageUrl}
          alt=""
        />
      </CardContent>
    </MyCard>
  );
};
export default ScreenView;
