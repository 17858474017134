import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {styled} from '@mui/material/styles';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, IconButton, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import LogEvent from './LogEvent';


const ComponentsOverviewOld = () => {

  const MyCard = styled(Card)({
    height: 240,
    width: 200,
    cursor: 'pointer',
  });

  const { accountId, appId, appVersion } = useParams();
  const navigate = useNavigate();
  const [aggs, setAggs] = useState([]);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    const appV = parseInt(appVersion, 10);
    /* if (appV < 174) {
      setLoading(false);
      return () => {};
    } */
    const subscription = Data.getInstance().streamAggLogDataGroup(`Accounts/${accountId}/Apps/${appId}`, appV)
      .subscribe(
        (aggsFound) => {
          if (aggsFound.length) {
            setAggs(aggsFound);
          }
          setLoading(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, appVersion]);

  const onComponentSelected = (compId) => {
    if (Array.isArray(compId)) {
      navigate(`/accounts/${accountId}/apps/${appId}/${appVersion}/docs/${compId[0]}/${compId[1]}`);
    } else
      navigate(`/accounts/${accountId}/apps/${appId}/${appVersion}/docs/${compId}`);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'auto',
    }}>
      {loading ? "Loading" : (aggs ? (<Grid container spacing={4}>{aggs.map((agg) => 
        <Grid key={agg.docId} item>
          <MyCard onClick={(e) => {
            e.stopPropagation();
            onComponentSelected([agg.group, agg.event]);
          }}><CardContent>
          <Typography variant="h5" component="h2">
          {agg.group}: {agg.event}
          </Typography>
        </CardContent></MyCard>          
        </Grid>
      )}</Grid>) : (
      <Grid container spacing={4}>
        {[
          ['GoogleAnalytics', 'Google Analytics: Events'],
          ['gaPageview', 'Google Analytics: Pageviews'],
          // parseInt(appVersion, 10) >= 172 ? [['de.mybestbrands.api.ApiService','trackDyPageview'], 'DY: Pageviews'] : ['dyTrackPageview', 'DY: Pageviews'],
          parseInt(appVersion, 10) >= 172 ? [['de.mybestbrands.api.ApiService','trackDyEvent'], 'DY: Events'] : ['trackDyEvent', 'DY: Events'],
          parseInt(appVersion, 10) >= 172 ? [] : ['dyTrackPageview', 'DY: Pageviews'],
          // parseInt(appVersion, 10) >= 172 ? [] : ['trackDyEvent', 'DY: Events'],
          parseInt(appVersion, 10) >= 172 ? [['FacebookSDK', 'logEvent'], 'FacebookSDK: logEvent'] : [],
          parseInt(appVersion, 10) >= 172 ? [['FacebookSDK', 'logPurchase'], 'FacebookSDK: logPurchase'] : []
        ].filter(pair => pair.length > 0).map(pair => 
        (<Grid key={pair[0]} item>
          <MyCard onClick={(e) => {
            e.stopPropagation();
            onComponentSelected(pair[0]);
          }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {pair[1]}
              </Typography>
            </CardContent>
          </MyCard>
        </Grid>)
      )}
      </Grid>))}
    </Box>
  );
};

export default ComponentsOverviewOld;
