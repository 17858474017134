import React, {useEffect, useState, useContext} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import PlatformInfo from './PlatformInfo';
import Data from './Data';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {Box, LinearProgress, Button, Typography} from '@mui/material';
import ScreenList from './ScreenList';
import VersionSelector from './VersionSelector';
import { AppContext } from './AppFrame';

const AppOverview = ({user}) => {
  const {accountId, appId, appVersion} = useParams();
  const {app} = useContext(AppContext);
  const navigate = useNavigate();
  const [userIsDev, setUserIsDev] = useState(['dev', 'admin'].includes(user.access[app.path]));
  // const [appp, setAppp] = useState();
  const [version, setVersion] = useState();
  const [versionToRelease, setVersionToRelease] = useState(-1);

  /* useEffect(() => {
    const subscription = Data.getInstance().streamApp(`Accounts/${accountId}/Apps/${appId}`)
        .subscribe(
            (appLoaded) => {
              setAppp(appLoaded);
              setUserIsDev(user.access[appLoaded.path] === 'dev');
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, user]); */

  useEffect(() => {
    console.log("AppOver " + JSON.stringify(app))
    if (app && appVersion) {
      const version = app.versions.find((v) => v.code === parseInt(appVersion, 10));
      console.log('setting new version ' + JSON.stringify(version));
      setVersion(version);
    }
  }, [app, appVersion]);

  useEffect(() => {
    console.log('versionToRelease ' + versionToRelease);
    if (versionToRelease >= 0) {
      const subscription = Data.getInstance().releaseVersion(`Accounts/${accountId}/Apps/${appId}`, versionToRelease)
          .subscribe(() => setVersionToRelease(-1));

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [accountId, appId, versionToRelease]);

  const onVersionChange = (newVersion) => {
    navigate(`/accounts/${accountId}/apps/${appId}/ui-gallery/${newVersion}`);
  };

  if (!app) {
    return <LinearProgress color="secondary" />;
  } else if (!version) {
    return <>VERSION NOT FOUND</>;
  } else {
    return (
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '20px',
      }}>
        <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '40px', paddingLeft: '50px', paddingRight: '50px'}}>
          {/* <Typography variant="h4">{app.name}</Typography>
          <Box height='14px' marginLeft='10px' marginRight='20px'>
            <PlatformInfo platform={app.platform} />
          </Box> */}
          <Box display='flex' alignItems="center">
            <Box display='flex' alignItems="center" sx={{minWidth: '120px', flexWrap: 'nowrap'}}>
              <VerifiedUserIcon color="secondary" sx={{marginRight: '4px'}} />
              <VersionSelector userIsDev={userIsDev} currentVersion={version} allVersions={app.versions} onVersionChange={onVersionChange} />
            </Box>
            {(userIsDev && !version.release) && <Button onClick={(ev) => {
              ev.stopPropagation(); setVersionToRelease(version.code);
            }}>Publish</Button>}
          </Box>
        </Box>
        <Box sx={{
          paddingLeft: '50px',
          paddingRight: '50px',
          paddingBottom: '20px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
          <ScreenList accountId={accountId} appProjectId={appId} appVersionCode={appVersion} />
        </Box>
      </Box>);
  }
};

export default AppOverview;
