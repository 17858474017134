import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LinearProgress, Grid} from '@mui/material';
import Data from './Data';
import ScreenView from './ScreenView';


const ScreenList = ({accountId, appProjectId, appVersionCode}) => {
  const navigate = useNavigate();
  const [screens, setScreens] = useState();

  useEffect(() => {
    setScreens(null);
    const subscription = Data.getInstance().streamAppScreens(`Accounts/${accountId}/Apps/${appProjectId}`, appVersionCode)
        .subscribe((screens) => {
          setScreens(screens);
        });

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appProjectId, appVersionCode]);

  if (!screens) {
    return <LinearProgress color="secondary" />;
  } else {
    let screenViews = [];
    screenViews = screens.map((screen) => {
      return (
        <Grid key={screen.docId + appVersionCode} item>
          <ScreenView screen={screen} onScreenClick={() => navigate(`/accounts/${accountId}/apps/${appProjectId}/ui-gallery/${appVersionCode}/${screen.docId}`)} />
        </Grid>
      );
    });
    return (
      <Grid container spacing={4}>
        {screenViews}
      </Grid>
    );
  }
};
export default ScreenList;
