import { Box, IconButton, Paper } from "@mui/material";
import React, {useState} from "react";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const CompInteraction = ({ interaction, icon }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Paper
        elevation={1}
        sx={{
          margin: 1,
          padding: 1,
          display: "flex",
          flexDirection: 'column',
          borderRadius: "4px",
          fontSize: "0.8rem",
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
        }}>
        <Box size="small" sx={{ marginRight: 1 }}>
          {icon}
        </Box>
        {interaction.behavior ||
          interaction.naviTarget ||
          interaction.target  ||
          ""}
          {!interaction.explain ? "" : (expanded ? <IconButton color="secondary" onClick={() => { setExpanded(false); }}><ExpandLessOutlinedIcon /></IconButton> : <IconButton color="secondary" onClick={() => { setExpanded(true); }}><ExpandMoreOutlinedIcon /></IconButton>)}
          </Box>
          {interaction.explain && expanded && <Box sx={{padding: '10px', maxWidth: '360px', whiteSpace: 'pre-wrap'}}>{interaction.explain}</Box>}
      </Paper>
  );
}
// (interaction.target &&
//   `${interaction.target} ${interaction.resultHandling || interaction.passed
//     ? " -> " + (interaction.resultHandling || interaction.passed)
//     : ""
//   }`) ||
export default CompInteraction;