import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDate from './DocDate';
import { CardContent, Typography, Grid, Card, Box, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import LogEvent from './LogEvent';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { distinctUntilChanged, tap, map } from 'rxjs/operators';
import { merge } from 'rxjs';
import { groupBy, groupBy3 } from './Fct.js';
dayjs.extend(relativeTime);

//targeted/calling: ['hide', 'offer', 'preload']
const LogOverviewSingleTry = ({accountId, appId, session, type, instanceId, targeted = 'hide', calling = 'hide', ignore=[]}) => {
  
  const [loadTargeted, setLoadTargeted] = useState(targeted == 'preload');
  const [loadCalling, setLoadCalling] = useState(calling == 'preload');
  const [logs, setLogs] = useState([]);
  const [callerLogs, setCallerLogs] = useState([]);
  const path = `Accounts/${accountId}/Apps/${appId}`;

  useEffect(() => {
    if (!session || !loadTargeted) return () => {};
      
    const subscription = merge(
      Data.getInstance().streamLogsForTarget(path, session, type, instanceId)
        .pipe(
          // map(logs => logs.reduce((acc, log) => {acc[log.action || "Other"] = log; return acc;}, {})),
          map(logs => groupBy3(logs, "caller", "callerInst", "action")),
          tap(logMap => setLogs(logMap))
        ),
    ).subscribe({});

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, session, type, instanceId, loadTargeted]);

  useEffect(() => {
    console.log("loadCalling " + loadCalling)
    if (!session || !loadCalling) return () => {};
      
    const subscription = merge(
      Data.getInstance().streamLogsForCaller(path, session, type, instanceId)
        .pipe(
          // map(logs => logs.reduce((acc, log) => {acc[log.action || "Other"] = log; return acc;}, {})),
          map(logs => groupBy3(logs, "target", "targetInst", "action")),
          tap(logMap => setCallerLogs(logMap))
        )
    ).subscribe({});

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, session, type, instanceId, loadCalling]);

  var typeParts = type.split(".");
  const typeShown = typeParts[typeParts.length-1]

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      paddingTop: '40px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      overflowY: 'auto',
      overflowX: 'hidden',
    }}>
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* {!logs || Object.keys(logs).length == 0 ? (targeted == 'offer' && <Button onClick={() => setLoadTargeted(true)}>Expand</Button>) : <Box>ForTarget:  {Object.keys(logs).map(action => <Box>{action}: <LogEvent logs={logs[action]} /></Box>)}</Box>} */}
      
        
        
      </Box>
    <Box sx={{padding:'10px', border: 1,
        display: 'flex',
        flexDirection: 'column',}}>{typeShown}<Box sx={{fontSize: '10px'}}>({instanceId})</Box></Box>
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {!logs || Object.keys(logs).length == 0 ? (targeted == 'offer' && <Button onClick={() => setLoadTargeted(true)}>Expand</Button>) :
          <Box>{Object.keys(logs).filter(tgtype=>ignore.length == 0 || tgtype!=ignore[0]).sort().map(tgtype => 
            Object.keys(logs[tgtype]).filter(tginst=>ignore.length == 0 || tginst!=ignore[1]).sort().map(tginst => 
              Object.keys(logs[tgtype][tginst]).sort().map(action => 
                <Box sx={{display: 'flex'}}>
                  <Box>{action}: <LogEvent logs={logs[tgtype][tginst][action]} reverse={true} /></Box>
                  <LogOverviewSingleTry accountId={accountId} appId={appId} session={session} type={tgtype} instanceId={tginst} targeted={'hide'} calling={'offer'} ignore={[type, instanceId]} />
                </Box>
              )
            )
          )}</Box>
        }
        {!callerLogs || Object.keys(callerLogs).length == 0 ? (calling == 'offer' && <Button onClick={() => setLoadCalling(true)}>Expand</Button>) :
          <Box>{Object.keys(callerLogs).filter(tgtype=>ignore.length == 0 || tgtype!=ignore[0]).sort().map(tgtype => 
            Object.keys(callerLogs[tgtype]).filter(tginst=>ignore.length == 0 || tginst!=ignore[1]).sort().map(tginst => 
              Object.keys(callerLogs[tgtype][tginst]).sort().map(action => 
                <Box sx={{display: 'flex'}}>
                  <Box>{action}: <LogEvent logs={callerLogs[tgtype][tginst][action]} /></Box>
                  <LogOverviewSingleTry accountId={accountId} appId={appId} session={session} type={tgtype} instanceId={tginst} targeted={'offer'} calling={'offer'} ignore={[type, instanceId]} />
                </Box>
              )
            )
          )}</Box>
        }
      </Box>
    </Box>
  );
};

export default LogOverviewSingleTry;
