import {Dialog, DialogTitle, DialogContent, IconButton, Typography, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const StateFullView = ({stateInfo, onClose, canNavigate, onPrevClick, onNextClick}) => {
  return (
    <Dialog
      fullWidth={false}
      maxWidth="xl"
      open={true}
      onClose={onClose}
      sx={{textAlign: 'center'}}
    >
      <DialogTitle>
        <Box sx={{minHeight: '40px'}}>
          <IconButton sx={{position: 'absolute', left: '30px'}} edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton><Box>
            {canNavigate && <>
              <IconButton color="inherit" onClick={(ev) => {
                ev.stopPropagation(); onPrevClick();
              }} ><NavigateBeforeIcon /></IconButton>
              <IconButton color="inherit" onClick={(ev) => {
                ev.stopPropagation(); onNextClick();
              }} ><NavigateNextIcon /></IconButton>
            </>}</Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="img"
          alt={stateInfo.desc}
          src={stateInfo.imageUrl}
          key={stateInfo.imageUrl}
          sx={{
            height: 'auto',
            width: 'auto',
            margin: 'auto',
            maxWidth: '100%',
            maxHeight: '76vh',
          }}
        />
        <Typography variant="subtitle1" sx={{height: '60px', maxWidth: '800px', padding: '8px', margin: 'auto', overflow: 'hidden'}}>{stateInfo.desc}</Typography>
      </DialogContent>
    </Dialog>
  );
};
export default StateFullView;
