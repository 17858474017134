import React from "react";

import AiArcComponent from "./AiArcComponent";
import interests from "./interests.json";
import interestsVm from "./interestsVm.json";
import styles from './css/AiArcDemo.module.css';

function AiArcDemo() {
  return (
    <div className={styles.ArcDemoRoot}>
    <div className={styles.ArcDemoRootChild}>
      <AiArcComponent name={"ForYouScreen"} />
    </div></div>
  );
}
export default AiArcDemo;