import React, { useState, useEffect, useContext } from 'react';
import { RadioGroup, FormControlLabel, Radio, Button, CircularProgress, Box, Typography, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { AppContext } from './AppFrame';

const MockingDialog = ({ deviceName, installId, isOpen, onClose }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputDeeplink, setInputDeeplink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { app } = useContext(AppContext);

  const handleChange = (event) => {
    setSuccess(false);
    setError(false);
    setSelectedValue(event.target.value);
  };

  const handleClick = () => {
    setLoading(true);
  };

  const onDeleteValue = () => {
    setSuccess(false);
    setError(false);
    setSelectedValue(null);
  }

  const onDeleteDeeplinkValue = () => {
    setInputDeeplink(null);
  }

  useEffect(() => {
    if (loading) {
      setSuccess(false);
      setError(false);
      fetch('https://api.appviewer.io/setKvVal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          apiKey: 'kvCfAv23',
          installId: installId,
          kvs: [
            {
              key: 'app-update-state',
              ...(selectedValue && { value: selectedValue })
            },
            /* {
              key: 'deeplink',
              ...(inputDeeplink && inputDeeplink.length && { value: inputDeeplink })
            } */
          ]
        })
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong');
        })
        .then(data => {
          console.log(data);
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setError(true);
          setLoading(false);
        });
    }
  }, [loading]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Test values</DialogTitle>
      <DialogContent>
        <DialogContentText>
          App Update state:
        </DialogContentText>
        <RadioGroup
          value={selectedValue}
          onChange={handleChange}
        >
          <FormControlLabel value="uptodate" control={<Radio />} label="Up to date" />
          <FormControlLabel value="recommended" control={<Radio />} label="Update recommended" />
          <FormControlLabel value="required-grace" control={<Radio />} label="Update required (grace period)" />
          <FormControlLabel value="required" control={<Radio />} label="Update required" />
        </RadioGroup>
        <Button onClick={onDeleteValue}>Delete&nbsp;<i>app update state</i>&nbsp;test value</Button>
        {/* <DialogContentText>
          Deeplink:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="deeplink"
          label="Enter deeplink"
          fullWidth
          variant="standard"
          value={inputDeeplink}
          onChange={(ev) => setInputDeeplink(ev.target.value)}
        />
        <Button onClick={onDeleteDeeplinkValue}>Delete&nbsp;<i>Deeplink</i>&nbsp;test value</Button> */}
        <DialogContentText>
          Saved values will be set for new launches within the next 30 mins. for this {deviceName} installation
        </DialogContentText>
        {success && <Box sx={{ color: 'green' }}>Saved values. This can take a moment to be picked up by your app launch. Make sure to check the values received in your launched app session.</Box>}
        {error && <Box sx={{ color: 'red' }}>There was an error. Try again.</Box>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Dismiss</Button>
        <Button
          sx={{ marginTop: '10px' }}
          variant="contained"
          color="primary"
          endIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MockingDialog;