import React, {useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './css/UserBar.module.css';
import { Avatar, AppBar, Toolbar, Tooltip, Popover, IconButton, Typography, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@mui/material';
import Fingerprint from '@mui/icons-material/Fingerprint';
import ExitToApp from '@mui/icons-material/ExitToApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import Android from '@mui/icons-material/Android';
// import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import Data from './Data';
import { Link } from 'react-router-dom';
import PlatformInfo from './PlatformInfo';
import { useEffect } from 'react';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: '1px solid #000'
}));

const IconButtonSpaced = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const BigText = styled(Typography)({
  flexGrow: 1,
});

const PaddedText = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));

const PaddedButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const PaddedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const AvatarSized = styled(Avatar)({
  margin: 'auto',
});

const UserBar = ({ user, currentApp, onNavigateBack }) => {
  // console.log('POPUSER ' + user.id);
  const navigate = useNavigate();
  const [showCopied, setShowCopied] = useState(false);
  const [anchorUserIdEl, setAnchorUserIdEl] = useState(null);
  const [anchorKeyEl, setAnchorKeyEl] = useState(null);

  const { accountId, appId } = useParams();
  
  const [key, setKey] = useState();
  const [openUserAdd, setOpenUserAdd] = useState(false);
  const [userIdEntered, setUserIdEntered] = useState("");
  const [roleValue, setRoleValue] = useState("viewer");
  const [userAddTrigger, setUserAddTrigger] = useState(false);
  const [userIdAdded, setUserIdAdded] = useState();

  useEffect(() => {
    setKey(null);
    if (!user || !user.access || !currentApp) return;
    const role = user.access[currentApp.path];
    if (!role || !['dev', 'admin'].includes(role)) return;
    const subscription = Data.getInstance().fetchApiKey(`Accounts/${accountId}/Apps/${appId}`)
        .subscribe((key) => setKey(key));
    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, appId, user, currentApp]);

  const handleCloseUserAdd = () => {
    setOpenUserAdd(false);
  };

  const handleClickOpenUserAdd = (e) => {
    e.preventDefault();
    setOpenUserAdd(true);
  };
  
  useEffect(() => {
    console.log('userAddTrigger ' + userAddTrigger);
    if (userAddTrigger && userIdEntered.length) {
      const idToAdd = userIdEntered;
      setUserIdEntered("");
      Data.getInstance().addUserToApp(currentApp.path, userIdEntered, roleValue)
      .then((data) => {
         console.log(data);
         setUserIdAdded(userIdEntered);
         setUserAddTrigger(false)
         // navigate("/", {replace: true})
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
  }, [userAddTrigger]);


  const handleClick = (event) => {
    setAnchorUserIdEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUserIdEl(null);
    setShowCopied(false);
  };

  const handleKeyClick = (event) => {
    event.preventDefault();
    setAnchorKeyEl(event.currentTarget);
  };

  const handleKeyClose = () => {
    setAnchorKeyEl(null);
    setShowCopied(false);
  };

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopied(true);
  }

  const open = Boolean(anchorUserIdEl);
  const id = open ? 'simple-popover' : undefined;
  const openKey = Boolean(anchorKeyEl);
  const idKey = openKey ? 'simple-popover' : undefined;

  let userDiv = '';
  let imageDiv, idBtn;
  if (user) {
    imageDiv = (user.email && user.email === 'yc@appviewer.io' ? <AvatarSized alt={user.email} src="https://uploads-ssl.webflow.com/62aa07e4454ea97676a4eaf8/64b65540ad9df4dad1ae376e_yc-michael.JPG" /> : (user.photoURL && user.photoURL.length) ? (<AvatarSized alt={user.name} src={user.photoURL} />) : (<AvatarSized>{user.name && user.name.length ? user.name.charAt(0) : (user.email && user.email.length ? user.email.charAt(0) : '')}</AvatarSized>));
    idBtn = (
      <div>
        <Tooltip title="Show User Id">
          <IconButtonSpaced color="inherit" onClick={handleClick}>
            <Fingerprint />
          </IconButtonSpaced>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorUserIdEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <PaddedBox>
            <PaddedText>Your UserID: {user.id}</PaddedText>
            <Button onClick={() => copy(user.id)}>{showCopied ? 'Copied \u2713' : 'Copy'}</Button>
          </PaddedBox>
        </Popover>
      </div>
    );
        }
    userDiv = (
      user ? (<Box className={styles.userinfos}>
        {idBtn}
        <Tooltip title="Logout">
          <IconButtonSpaced color="inherit" onClick={() => Data.getInstance().logout()}><ExitToApp /></IconButtonSpaced>
        </Tooltip>
        <Tooltip title={user.name && user.name.length ? user.name : (user.email && user.email.length ? user.email : '')}>
          {imageDiv}
        </Tooltip>
      </Box>) : (
        <Box className={styles.userinfos}>
          <Button component={Link} to={`/signin`} sx={{ color: '#fff' }}>Sign In</Button>
      </Box>
      )
    );
    // const title = <BigText variant="h6" >{currentApp ? currentApp.name : 'Appviewer'}</BigText>;
    /* let navigation = '';
    if (currentApp) {
      navigation = (
        <IconButtonSpaced edge="start" color="inherit" onClick={onNavigateBack}>
          <ArrowBack />
        </IconButtonSpaced>
      );
    } */
  // }
  let keyBtn = "";
  if (key) {
    keyBtn = (
      <div>
        <Tooltip title="Show API key">
          <IconButton color="inherit" onClick={handleKeyClick}>
            <VpnKeyOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={idKey}
          open={openKey}
          anchorEl={anchorKeyEl}
          onClose={handleKeyClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <PaddedBox>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PaddedText><b>Your API key:</b> {key}</PaddedText>
                <PaddedText><b>Your Account Id:</b> {accountId}</PaddedText>
                <PaddedText><b>Your App Id:</b> {appId}</PaddedText>
                {/* <Button onClick={() => copy(key)}>{showCopied ? 'Copied \u2713' : 'Copy'}</Button> */}
              </Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
                Android SDK repo credentials:
                <PaddedText>&nbsp;&nbsp;<b>Username:</b> a806937e-11db-4a09-998b-5eba7690e714</PaddedText>
                <PaddedText>&nbsp;&nbsp;<b>Password:</b> 7d5939b56a963cc879633159afef60041afe14130f5ff5c07b47b8138d9f11bb</PaddedText>
              {/* </Box> */}
            </Box>
          </PaddedBox>
        </Popover>
      </div>
    );
  }
  
  return (
    <div sx={{
      flexGrow: 1,
      position: 'sticky',
      top: 0,
      zIndex: 100,
    }}>
      <AppBar position="static">
        <Toolbar>
          {/* {navigation} */}
          {/* {title} */}
          <Box className={styles.logoDiv}>
            <img
            srcSet="/av_txtlogo_166w.png 1x, /av_txtlogo_332w.png 2x"
            sizes="(max-width: 166px) 100vw, 166px"
             src="/av_txtlogo_166w.png" className={styles.logo} />
            </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            {appId && currentApp && <>
            <Box sx={{ display: 'flex', alignItems: 'center', border: 0, borderRadius: 1, borderColor: '#aaa', padding: '4px', width: 'auto', height: '40px', background:'#edad00', color:'white', textDecoration:'none' }} component={Link} to={`/accounts/${accountId}/apps/${appId}`}>
              <Box>
                {/* <Box sx={{ fontSize: 8 }} >Project</Box> */}
                <Tooltip title={currentApp.name}><Box sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }} ><PlatformInfo platform={currentApp.platform} />&nbsp;{currentApp.name.substring(0,40)}</Box></Tooltip>
              </Box>
              {user && !(accountId === 'OSjnyurha9B0VDIOufy3' && appId === 'H87PoZ81sOpJ7Ml95pSy') && (<Tooltip title="Add users to project">
                <IconButton sx={{color:'white', marginLeft: '8px'}} onClick={handleClickOpenUserAdd}><PersonAddIcon /></IconButton>
              </Tooltip>)}
              {keyBtn}
            </Box>
            <Box sx={{ padding: '10px' }}>
              <Button component={Link} to={`/accounts/${accountId}/apps/${appId}/monitor`} sx={{ color: '#fff' }}>Live Monitor</Button>
            </Box>
            {/* <Box sx={{ padding: '10px' }}>
              <Button component={Link} to={`/accounts/${accountId}/apps/${appId}/175/docs`} variant='outlined' sx={{ color: '#fff' }}>Docs</Button>
            </Box> */}
            <Box sx={{ padding: '10px' }}>
              {/* <Button component={Link} to={`/accounts/${accountId}/apps/${appId}/${currentApp.latestVersion.code}`} sx={{ color: '#fff' }}>UI Gallery</Button> */}
              <Button component={Link} to={`/accounts/${accountId}/apps/${appId}/ui-gallery`} sx={{ color: '#fff' }}>UI Gallery</Button>
            </Box>
            <Box sx={{ padding: '10px' }}>
              {(accountId === 'OSjnyurha9B0VDIOufy3' && appId === 'H87PoZ81sOpJ7Ml95pSy')?(<Button component={Link} to={`aidocs`} sx={{ color: '#fff' }}>AI diagram</Button>) :
              (<Button disabled={true} sx={{ color: '#fff' }}>AI diagram</Button>)}
            </Box>
            {/* {currentApp.id === 'UbNoUbhfNDXZJyCZ2KSi' && (<Box sx={{ padding: '10px' }}>
              <Button component={Link} to={`simulate`} sx={{ color: '#fff' }}>Simulate</Button>
            </Box>)} */}
            </>}
          </Box>
          {userDiv}
        </Toolbar>
      </AppBar>
      {appId && currentApp && !(accountId === 'OSjnyurha9B0VDIOufy3' && appId === 'H87PoZ81sOpJ7Ml95pSy') && <Dialog open={openUserAdd} onClose={handleCloseUserAdd}>
        <DialogTitle>Invite User to project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add another user to your project, ask the person to sign up and pass you the user id (fingerprint icon top right).
            Don't forget to tell them once you're done here, since there are no notifications sent out.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="User ID"
            fullWidth
            variant="standard"
            value={userIdEntered}
            onChange={(ev) => setUserIdEntered(ev.target.value)}
          />
          <FormControl>
            <FormLabel>Role:</FormLabel>
            <RadioGroup
              row
              name="role-radio-buttons-group"
              value={roleValue}
              onChange={(ev) => setRoleValue(ev.target.value)}
            >
              <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
              <FormControlLabel value="dev" control={<Radio />} label="Dev" />
              {(user && user.access && (user.access[currentApp.path] === "admin")) && <FormControlLabel value="admin" control={<Radio />} label="Admin" />}
            </RadioGroup>
          </FormControl>
          {userIdAdded && <Box sx={{color:'green'}}>Added user {userIdAdded}</Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserAdd}>Dismiss</Button>
          <Button 
          disabled={!userIdEntered.length || userAddTrigger} 
          onClick={() => {setUserIdAdded(null); setUserAddTrigger(true);}}
          startIcon={userAddTrigger ? <CircularProgress size={24} /> : <PersonAddIcon/>}
          >Add User</Button>
        </DialogActions>
      </Dialog>}
    </div>
    /* <div className={styles.bar}>
      {navigation}
      {userDiv}
    </div> */
  );
};

export default UserBar;
