import { CodeBlock, androidstudio } from "react-code-blocks";

function MyCodeComponent({code, startingLineNumber = 1}) {

  return (
    <CodeBlock
    text={code}
    language={'kotlin'}
    showLineNumbers={true}
    startingLineNumber={startingLineNumber}
    theme={androidstudio}
    />
  );
}
export default MyCodeComponent;