import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useParams, useNavigate, Link } from 'react-router-dom';
import UserBar from './UserBar';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import Data from './Data';
import { AppContext } from './AppFrame';

const UiGalleryMain = ({ user }) => {
  const { accountId, appId } = useParams();
  const navigate = useNavigate();
  const { app } = useContext(AppContext);
  const [showIntro, setShowIntro] = useState(false);

  useEffect(() => {
    if (!app || !user || !user.apps || !user.apps.length) {
      setShowIntro(true)
    } else {
      const foundAppAtI = user.apps.findIndex((el) => el.id === app.id);
      if (foundAppAtI < 0) {
        setShowIntro(true)
      } else {
        //maybe everything above unnecessary, because permissions would be denied anyway
        if (!app.latestVersion && !app.latestVersionDev) {
          setShowIntro(true)
        } else {
          let code = app.latestVersion ? app.latestVersion.code : app.latestVersionDev.code;
          navigate(`/accounts/${accountId}/apps/${appId}/ui-gallery/${code}`, { replace: true });
        }
      }
    }


  }, [app, user]);

  if (showIntro) {
    return (
        <Box sx={{ height: '100%', textAlign: 'center', paddingTop:'40px', paddingBottom:'60px', paddingLeft:'10px', paddingRight:'10px', overflowY: 'auto',
        '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '8px'}, }}>
          <Typography variant='h2' sx={{ textAlign: 'center' }}>UI Gallery</Typography>
          <Box sx={{marginTop:'6px', marginBottom:'20px', textAlign:'center'}}>Create an accurate, always up-to-date overview of your app's screens and all their variations.<br/>Publish UI changes during development for quick team reviews before finalizing test versions.</Box>
          <Box sx={{display:'flex', flexDirection:'column', gap:'10px', alignItems:'center', padding:'20px'}}>
            <Box sx={{display:'flex', alignItems:'center', width: '300px'}}><VerifiedUserOutlinedIcon sx={{marginRight:'6px'}}/><Typography variant='subtitle2'>All your scenarios and edge cases</Typography></Box>
            <Box sx={{display:'flex', alignItems:'center', width: '300px'}}><MobileFriendlyOutlinedIcon sx={{marginRight:'6px'}}/><Typography variant='subtitle2'>Multiple device sizes</Typography></Box>
            <Box sx={{display:'flex', alignItems:'center', width: '300px'}}><PublishedWithChangesOutlinedIcon sx={{marginRight:'6px'}}/><Typography variant='subtitle2'>Kept in sync with your app updates</Typography></Box>
            <Box sx={{display:'flex', alignItems:'center', width: '300px'}}><ChecklistRtlOutlinedIcon sx={{marginRight:'6px'}}/><Typography variant='subtitle2'>Publish dev snapshots for fast feedback</Typography></Box>
            <Box sx={{display:'flex', alignItems:'center', width: '300px'}}><SafetyCheckOutlinedIcon sx={{marginRight:'6px'}}/><Typography variant='subtitle2'>Time travel with version history</Typography></Box>
          </Box>
          <Typography variant='subtitle1' color={'secondary'}><a href="https://appviewer.io">Learn more</a></Typography>
          <Typography variant='h6' sx={{marginTop:'20px', marginBottom:'20px'}}>Get started with just one screen</Typography>
          <Button variant='contained' onClick={() => window.open("https://docs.appviewer.io", '_blank')}>Read Docs</Button>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ marginTop:'40px', height: '50vw', maxHeight:'430px', width: '90%', maxWidth: '800px', border: 8, borderColor: '#ffc108', backgroundImage: "url(/ui-gallery-example.jpg)", backgroundPosition: 'top', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
            </Box>
          </Box>
        </Box>
    );
  }
  return "";
};

export default UiGalleryMain;