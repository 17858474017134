import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';

const FirebaseManager = (function() {
  function SingletonClass() {
    this.init = () => {};
  }

  let instance;

  return {
    getInstance: function() {
      if (instance == null) {
        instance = new SingletonClass();
        const firebaseConfig = {
          apiKey: 'AIzaSyCeeEWy469Pf-QObTkjYVNC6K0T2AtIrbI',
          authDomain: 'appviewer-cb072.firebaseapp.com',
          // databaseURL: "https://spec-deck-c393e.firebaseio.com",
          projectId: 'appviewer-cb072',
          storageBucket: 'appviewer-cb072.appspot.com',
          messagingSenderId: '645139633212',
          appId: '1:645139633212:web:00aa8f9e3fd8efe4811f39',
          measurementId: 'G-90BK8ZBL1D',
        };
        initializeApp(firebaseConfig);
        getAnalytics();

        instance.constructor = null;
      }
      return instance;
    },
  };
})();

export default FirebaseManager;
