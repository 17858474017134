import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CellTowerIcon from '@mui/icons-material/CellTower';
import MyCodeComponent from "./CodeBlockComponent";
import ReactJson from 'react-json-view'

import onboardingUiModel from "./onboardingUiModel.js";
import newsFeedUiState from "./newsFeedUiState.js";
import newsResource from "./newsResource.js";
import followableTopic from "./followableTopic.js";
import userData from "./UserData.js";

const DataFlowItem = ({ flow }) => {
  const getCode = (type) => {
    switch (type) {
      case "OnboardingUiState":
        return onboardingUiModel;
      case "NewsFeedUiState":
        return newsFeedUiState;
        case "List<UserNewsResource>":
      case "UserNewsResource":
        return newsResource;
      case "List<FollowableTopic>":
        return followableTopic;
        case "UserData":
          return userData;
      default:
        return "";
    }
  }
  const targetTypeCode = !flow.targetType ? "" : getCode(flow.targetType);
  const [expanded, setExpanded] = useState(null);
  const [sampleIndex, setSampleIndex] = useState(0);
  const defaultExpand = flow.explain ? "explain" : (targetTypeCode !== "" ? "code" : (flow.sample ? "sample" : ""));
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', rowGap: '6px', alignItems: 'center',
      maxWidth: '100%',
      fontSize: 12,
      background: '#fcc',
      borderRadius: "4px",
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center', padding: "0px 8px",
      maxWidth: '100%', overflow: 'auto',
      '::-webkit-scrollbar-track': {background: "#eee",},'::-webkit-scrollbar-thumb': {backgroundColor: '#00000000',},'::-webkit-scrollbar': {height: '6px',},'&:hover': {'::-webkit-scrollbar-track': {background: "#e8e8e8",},'::-webkit-scrollbar-thumb': {backgroundColor: '#ccc',borderRadius: 8,}},'::-webkit-scrollbar-thumb:hover': {background: '#bbb'}
     }}>
        {flow.target}
        {flow.targetType &&
          (<Box sx={{ background: '#ccc', borderRadius: "4px", fontSize: 10, padding: "0px 6px" }}>
            {flow.targetType}
          </Box>)}
          {defaultExpand !== "" && (expanded ? <IconButton sx={{ padding: '0px' }} size="small" color="secondary" onClick={() => { setExpanded(null); }}><ExpandLessOutlinedIcon /></IconButton> : <IconButton sx={{ padding: '0px' }} size="small" color="secondary" onClick={() => { setExpanded(defaultExpand); }}><ExpandMoreOutlinedIcon /></IconButton>)}
      </Box>
      {expanded && <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '6px', alignItems: 'center', padding: "4px 10px", }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center', padding: "4px 4px", }}>
          {flow.explain && (expanded === "explain" ? <Box>Explain</Box> : <Box sx={{cursor:'pointer'}} onClick={() => { setExpanded("explain"); }}><u>Explain</u></Box>)}
          {targetTypeCode !== "" && (expanded === "code" ? <Box>Code</Box> : <Box sx={{cursor:'pointer'}} onClick={() => { setExpanded("code"); }}><u>Code</u></Box>)}
          {flow.sample && (expanded === "sample" ? <Box>SampleData</Box> : <Box sx={{cursor:'pointer'}} onClick={() => { setExpanded("sample"); }}><u>SampleData</u></Box>)}
        </Box>
        {(expanded === "explain") && <Box sx={{padding: '10px', minWidth: '300px', maxWidth: '360px', textWrap: 'wrap', whiteSpace: 'pre-wrap'}}>{flow.explain}</Box>}
        {(expanded === "code") && <Box><MyCodeComponent code={targetTypeCode} startingLineNumber={65} /></Box>}
        {(expanded === "sample") && <Box sx={{display: 'flex', flexDirection: 'column', padding: '10px'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', padding: '10px'}}>
          {Array.from(new Array(flow.sample.length),(val,index)=> (sampleIndex == index) ? <Box sx={{paddingRight:'10px'}}>{index+1}</Box> : <Box sx={{paddingRight:'10px', cursor:'pointer'}} onClick={() => { setSampleIndex(index); }}><u>{index+1}</u></Box> )}
        </Box>
        <Box sx={{
          background: '#ccc',
          borderRadius: "4px",
          padding: "2px 10px",
          margin: '4px',
          width: '180px'
        }}>NewsFeedUiState.Success</Box>
        <Box><ReactJson src={flow.sample[sampleIndex]} theme="codeschool" name={false} collapsed={5} collapseStringsAfterLength={30} groupArraysAfterLength={10} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} /></Box>
        <Typography variant="caption"><i>From Appviewer Live Monitor</i></Typography>
          </Box>}
      </Box>}
    </Box>
  );
}
export default DataFlowItem;