import React, { useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TouchAppIcon from "@mui/icons-material/TouchApp"; // Example icon for actions
import TuneIcon from "@mui/icons-material/Tune";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import EditIcon from "@mui/icons-material/Edit";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DataFlow from "./AiArcDataFlow";
import foryou from "./foryou.json";
import foryouVm from "./forYouVm.json";
// import userNewsRepo from "./userNewsRepo.json";
import CompInteraction from "./AiArcCompInteraction";

const AiArcComponent = ({ name }) => {
  const getData = () => {
    switch (name) {
      case "ForYouScreen":
        return foryou;
      case "ForYouViewModel":
        return foryouVm;
        // case "CompositeUserNewsResourceRepository":
        // return userNewsRepo;
    
      default:
        return {};
    }
  }
  const data = getData();
  const [expanded, setExpanded] = useState(false);

  const getIconForInteractionType = (type) => {
    if (type.includes("Flow"))
      return (
        <>
          <KeyboardDoubleArrowLeftIcon />
          <NotificationsNoneIcon />
        </>
      );
    switch (type) {
      case "action":
        return <TouchAppIcon />;
      case "reaction":
        return <TuneIcon />;
      case "observes":
        return (
          <>
            <KeyboardDoubleArrowLeftIcon />
            <NotificationsNoneIcon />
          </>
        );
      case "invokes":
        return (
          <>
            <ReadMoreIcon />
          </>
        );
      case "writes":
        return <EditIcon />;
      case "navigate":
        return <AssistantDirectionIcon />;
      default:
        return null;
    }
  };

  const renderInteractions = (interactions) => {
    return interactions.map((interaction, index) => (
      <CompInteraction key={index} interaction={interaction} icon={getIconForInteractionType(interaction.type)} />
    ));
  };

  const renderExposed = (exposed) => {
    return exposed.map((expose, index) => (
      <Paper
        key={index}
        elevation={1}
        sx={{
          margin: 1,
          padding: 1,
          display: "flex",
          alignItems: "center",
          borderRadius: "4px",
          fontSize: "0.8rem",
        }}
      >
        <IconButton size="small" sx={{ marginRight: 1 }}>
          {getIconForInteractionType(expose.type || "invokes")}
        </IconButton>
        {`${expose.name} ${expose.parameters && expose.parameters.lenght
          ? "(" + expose.parameters.join(",") + ")"
          : ""
          } ${expose.type ? `:${expose.type}` : ""}`}
      </Paper>
    ));
  };

  const renderUiElements = (uiElements) => {
    return uiElements.map((element, index) => (
      <Box
        key={index}
        sx={{
          margin: "8px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Chip
          size="small"
          label={element.type}
          color="warning"
          sx={{
            marginBottom: 1,
            fontSize: "0.6rem",
            padding: "2px",
          }}
        />
        <Typography variant="body2">{element.desc}</Typography>
        {element.interactions && renderInteractions(element.interactions)}
        {element.children && element.children.map((child) => <AiArcComponent data={child} />)}
      </Box>
    ));
  };

  return (<Box sx={{
    display: "flex", flexDirection: "column",
    padding: "10px", alignItems: 'center',
    width: "100%"
  }}>
    <Box sx={{
      display: "flex", flexDirection: "column",
      // width: "100%", alignItems: 'center',
      margin: "10px",
      padding: "10px",
      border: 1,
      borderColor: "grey.500",
      borderRadius: "4px",
    }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h5" component="h2" sx={{}}>
          {name}
        </Typography>
        {/* {data.uiElements && (data.uiElements.length + " UI components")} */}
        {data.uiElements && (expanded ? <IconButton color="secondary" onClick={() => { setExpanded(false); }}><ExpandLessOutlinedIcon /></IconButton> : <IconButton color="secondary" onClick={() => { setExpanded(true); }}><ExpandMoreOutlinedIcon /></IconButton>)}
      </Box>
      {expanded && (<Box sx={{ display: "flex", flexDirection: "row" }}>
        {(name === "ForYouScreen") && <Box sx={{display: 'flex', flexDirection:'column', height: 'auto', margin: 'auto'}}><img style={{maxWidth:'260px', height: 'auto', margin: 'auto'}} src="/niaforyou.png" /><Typography variant="caption"><i>From Appviewer UI Gallery</i></Typography></Box>}
        {data.uiElements && (
          <Box
            sx={{ 
              flex: 1,
            }}
          >UI Elements:
            {renderUiElements(data.uiElements)}
          </Box>
        )}
        {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
          {data.exposedVariables && (
            <Box
              sx={{
                flex: 1,
              }}
            >
              {renderExposed(data.exposedVariables)}
            </Box>
          )}
          {data.exposedMethods && (
            <Box
              sx={{
                flex: 1,
              }}
            >
              {renderExposed(data.exposedMethods)}
            </Box>
          )}
        </Box> */}
        {/* {(data.viewmodels ||
          data.callbacks ||
          data.connectedComponents) && (
            <Box sx={{ flex: 1 }}>
              {(
                data.viewmodels ||
                data.callbacks ||
                data.connectedComponents
              ).map((vm, index) => (
                <Box key={index} sx={{ margin: "8px 0" }}>
                  <Box sx={{ display: "flex" }}>
                    <CompareArrowsIcon />
                    &nbsp;&nbsp;
                    <Typography variant="h6" component="h3">
                      {vm.type}
                    </Typography>
                  </Box>
                  {vm.interactions && renderInteractions(vm.interactions)}
                </Box>
              ))}
            </Box>
          )} */}
      </Box>)}
    </Box>
    {(data.viewmodels ||
      data.callbacks ||
      data.connectedComponents) && (<Box sx={{
        display: "flex", flexDirection: "column",
        padding: "2px", alignContent: 'center', rowGap: '20px'
      }}>
        {/* <Box sx={{
      alignItems: 'center',
      margin: "10px",
      padding: "10px",
      border: '1px solid grey',
      borderTop: 'none',
      borderColor: "grey.500",
      borderRadius: "4px",
    }}/> */}
    <Box sx={{
      display: "flex", flexDirection: "row",
      padding: "2px", alignContent: 'center', columnGap: '20px', alignItems: 'end'
    }}>
        {(
          data.viewmodels ||
          data.callbacks ||
          data.connectedComponents
        ).map((vm, index) => {
          const upstream = vm.interactions.filter(inter => ["observes", "reads"].includes(inter.type));
          const downstream = vm.interactions.filter(inter => ["invokes", "writes"].includes(inter.type));
          return (
          <Box sx={{
            display: "flex", flexDirection: "column", rowGap: '10px',
            alignItems: 'center'
          }}>
          <Box sx={{
            display: "flex", flexDirection: "column", rowGap: '10px',
            alignItems: 'center'
          }}>
            <Box sx={{
              display: "flex", flexDirection: "row",
              padding: "2px", alignContent: 'center', alignItems: 'end', columnGap: '20px'
            }}>
              {upstream.length>0 && <DataFlow up={true} flows={upstream} />}
              {downstream.length>0 && <DataFlow up={false} flows={downstream} />}
            </Box>
            <Box sx={{
      width: '100%',
      margin: "10px",
      padding: "10px",
      border: '1px solid grey',
      borderTop: 'none',
      borderColor: "grey.500",
      borderRadius: "4px",
    }}/>
    </Box>
            {vm.type && <AiArcComponent name={vm.type} />}
          </Box>
        )})}
        </Box>
      </Box>)}
  </Box>
  );
};

export default AiArcComponent;
