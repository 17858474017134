import {Box, Tooltip} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const DocDate = ({timeStampDate}) => {
  const dateInMillis = timeStampDate.seconds * 1000;
  const date = new Date(dateInMillis).toDateString() + ' at ' + new Date(dateInMillis).toLocaleTimeString();
  const relTime = dayjs(new Date(dateInMillis)).fromNow();
  return (
    <Tooltip title={date}>
      <Box>
        <span style={{color: '#cccccc'}} variant="h8">{relTime}</span>
      </Box>
    </Tooltip>
  );
};
export default DocDate;
