import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseManager from './FirebaseManager';
import Data from './Data';
import DocDateMonitor from './DocDateMonitor';
import { CardContent, Typography, Grid, Card, Box, IconButton, Tooltip } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import PlatformInfo from './PlatformInfo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { css, keyframes } from "@emotion/react";
import LogMessage from './LogMessage';


const LogMessageRow = ({ log, categoriesMap, brandsMap, colorsMap, showjsTree=false, fullString = false, addableAsTestCase = false, appPath = "" }) => {

  // console.log("LOG: "+ (log.id != null ? ("ID: " + log.id) : "ACT " + log.action + " ARGS-n " + (log.args && Array.isArray(log.args) ? log.args.length : "noArr")))
            const categories = []
            const brands = []
            const colors = []
            const additionalTags = []
            var req = "";
            var resp = "";
            if (log.args && Array.isArray(log.args) && log.args.length > 0) {
              // req = log.args.map(arg => arg.substring(0, 20))
              // req = log.args.substring(0, 20)
              try {
                // console.log("THIJSON " + log.args + " (type: " + typeof log.args)
                // const jsonArr = JSON.parse(log.args);
                // console.log("THIJSON parsedArray? " + Array.isArray(jsonArr))
                // if(typeof jsonArr === 'string' || !Array.isArray(jsonArr)) throw Exception("Badly parsed")
                // console.log("ARGARR " + log.args);
                req = log.args.map((arg, i) => {
                  let parsedArg = ""
                  try {
                    parsedArg = JSON.parse(arg);
                    // console.log("ARGARRGGREQ " + log.event + (typeof parsedArg));
                    if(typeof parsedArg === 'string' || !Object.keys(parsedArg).length) throw Exception("Badly parsed")
                    // console.log("ARGARRGGREQ ", JSON.stringify(parsedArg));
                    if (log.group === "API" && log.event === "Request") {
                      additionalTags.push(<Chip key="url" label={parsedArg.method + " " + parsedArg.url} size="small" color='primary' />)
                      additionalTags.push(<Chip key="uuid" label={parsedArg.uuid} size="small" color='primary' />)
                      if (parsedArg.body) {
                        try {
                          parsedArg = JSON.parse(parsedArg.body)
                        } catch (e) {
                        }
                      } else
                        return <></>    
                    }
                    return <Box key={i} sx={{marginRight:'10px'}}><LogMessage jsObj={parsedArg} group={log.group} event={log.event} showjsTree={showjsTree} addableAsTestCase={addableAsTestCase} appPath={appPath} /></Box>
                  } catch (e) {
                    return <Tooltip title={arg} key={i}>
                    <Chip label={fullString ? arg : arg.substring(0, 80)} size="small" variant="soft" icon={<DataObjectOutlinedIcon />} sx={{marginRight:'10px'}}/>
                    </Tooltip>
                  }
                })
              } catch (e) {
                req = <div>{typeof log.args === 'string' ? log.args.substring(0, 20) : JSON.stringify(log.args)}</div>
              }
            }
            if (log.message && log.message.length > 0) {
              try {
                // console.log("THIJSON " + log.message)
                let jsonObj = JSON.parse(log.message);
                // console.log("THIJSON parsed " + Object.keys(jsonObj).length)
                if(typeof jsonObj === 'string' || !Object.keys(jsonObj).length) throw Exception("Badly parsed")
                // resp = <div>{JSON.stringify(jsonObj, null, 2)}</div>
                let objToShow = jsonObj;
                if (log.group === "API" && log.event === "Response") {
                  additionalTags.push(<Chip key="code" label={jsonObj.code + " " + jsonObj.message} size="small" color='primary' />)
                  additionalTags.push(<Chip key="url" label={jsonObj.url} size="small" color='primary' />)
                  additionalTags.push(<Chip key="uuid" label={jsonObj.uuid} size="small" color='primary' />)
                  if (jsonObj.response) {
                    try {
                      objToShow = JSON.parse(jsonObj.response)
                    } catch (e) {
                      objToShow = jsonObj.response
                    }
                  } else
                    objToShow = null
                }
                  resp = (objToShow ? <LogMessage jsObj={objToShow} showjsTree={showjsTree} addableAsTestCase={addableAsTestCase} appPath={appPath} /> : "")
                if (jsonObj.avType === 'ImmutableFeedOptions' && categoriesMap && brandsMap && colorsMap) {
                  if (jsonObj.dealFilter.mc)
                    categories.push(categoriesMap[jsonObj.dealFilter.mc])
                  if (jsonObj.dealFilter.mainCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.mainCategoryId])
                  if (jsonObj.dealFilter.sc)
                    categories.push(categoriesMap[jsonObj.dealFilter.sc])
                  if (jsonObj.dealFilter.subCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.subCategoryId])
                  if (jsonObj.dealFilter.p)
                    categories.push(categoriesMap[jsonObj.dealFilter.p])
                  if (jsonObj.dealFilter.productTagCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.productTagCategoryId])
                  if (jsonObj.dealFilter.st)
                    categories.push(categoriesMap[jsonObj.dealFilter.st])
                  if (jsonObj.dealFilter.subTagCategoryId)
                    categories.push(categoriesMap[jsonObj.dealFilter.subTagCategoryId])
                  if (jsonObj.dealFilter.b) {
                    jsonObj.dealFilter.b.forEach((brId) => brands.push(brandsMap[brId]))
                  }
                  if (jsonObj.dealFilter.brandIds) {
                    jsonObj.dealFilter.brandIds.forEach((brId) => brands.push(brandsMap[brId]))
                  }
                  if (jsonObj.dealFilter.c) {
                    jsonObj.dealFilter.c.forEach((id) => colors.push(colorsMap[id]))
                  }
                  if (jsonObj.dealFilter.colorIds) {
                    jsonObj.dealFilter.colorIds.forEach((id) => colors.push(colorsMap[id]))
                  }
                }
                if (jsonObj.avType === 'ImmutableDyChooseBody' && categoriesMap) {
                  if (jsonObj.context.page.data) {
                    jsonObj.context.page.data.forEach((id) => categories.push(categoriesMap[id]))
                  }
                }
              } catch (e) {
                resp = <div>{log.message
                  // .substring(0, 40)
                  }</div>
              }
            }

            const previewChips = <>{categories.length > 0 && <Stack direction="row" spacing={1}>
            {categories.map((cat) => {
              return <Chip key={cat} label={cat} size="small" color='primary' />
            })}
          </Stack>}
          {brands.length > 0 && <Stack sx={{marginTop:'4px'}} direction="row" spacing={1}>
            {brands.map((item) => {
              return <Chip key={item} label={item} size="small" color='primary' />
            })}
          </Stack>}
          {colors.length > 0 && <Stack sx={{marginTop:'4px'}} direction="row" spacing={1}>
            {colors.map((item) => {
              return <Chip key={item} label={item} size="small" color='primary' />
            })}
          </Stack>}</>

            return (
              /* primary={

              <Box paddingLeft='0px'><Box><DocDateMonitor timeStampDate={log.createdAt} /></Box>
              </Box>} */
                // <ListItem alignItems="flex-start" key={log.docId} sx={{ overflow: 'auto', animation: 'none', ...(highlight === log.createdAt.toMillis() && { animation: `${blink} 0.5s ease` }) }}>
                <Box sx={{display:'flex', flexDirection:'column', borderLeft: '2px solid #00b0ff', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '8px', paddingRight: '8px', fontSize: '13px'}}>
                  {/* {log.avtimems || log.createdAt.toMillis()} */}
                  {log.screen && <Box sx={{fontSize: '10px'}}>{log.screen}</Box>}
                  <Box sx={{display:'flex', fontSize: '10px'}}>{log.event}</Box>
                  {/* <Box sx={{display:'flex', fontSize: '10px'}}>{""+log.createdAt}</Box> */}
                  <Box alignItems="flex-start" key={log.docId} sx={{ overflow: 'auto',
                '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {backgroundColor: '#00000000',},'::-webkit-scrollbar': {height: '6px',},'&:hover': {'::-webkit-scrollbar-track': {background: "#e8e8e8",},'::-webkit-scrollbar-thumb': {backgroundColor: '#ccc',borderRadius: 8,}},'::-webkit-scrollbar-thumb:hover': {background: '#bbb'}
                }}>
                        
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {(additionalTags.length > 0) && <Box sx={{display: 'flex', flexDirection:'row'}}>
                        {additionalTags}
                        </Box>}
                      {(req != null && req.length >0) && <Box  sx={{display: 'flex'}} marginBottom='2px'>
                          <Box sx={{ whiteSpace: 'nowrap' }} marginRight='6px'>--&gt;</Box>
                          {req}
                        </Box>}
                      {resp && <Box>{previewChips}<Box  sx={{display: 'flex'}} marginBottom='10px'>
                        <Box sx={{ whiteSpace: 'nowrap' }} marginRight='4px'>&lt;--&nbsp;</Box>
                        {resp}
                        </Box></Box>}
                    </Typography>
                  </Box>
                </Box>
                // </ListItem>
            )
};

export default LogMessageRow;
